import { FormButton, FormError, FormLabel, FormLink, FormRoot, FormTitle, Input } from "../../components";
import { FormContainer, ImageContainer, LoginContainer, LoginImage } from "./styles";

import { Formik } from "formik";
import loginBanner from "../../assets/images/login-banner.png";
import { loginScheme } from "../../helpers/schemas";
import { loginWithEmailAndPassword } from "../../services/user-service";
import { routes } from "../../navigation/routes";
import { useDispatch } from "react-redux";

const initialValues = {
  email: "",
  password: "",
};

export const LoginScreen = () => {
  const dispatch = useDispatch();

  const onSubmit = async (data, formik) => {
    const result = await dispatch(
      loginWithEmailAndPassword({
        email: data.email,
        password: data.password,
      })
    );

    if (result.type === "user/login/rejected") {
      formik.setErrors(getLoginServerError(result));
    }
    if (result.type === "user/login/fulfilled") {
      // role-based-redirector maneja la ruta apropiada para el login
      formik.resetForm();
    }
  };

  return (
    <LoginContainer>
      <ImageContainer>
        <LoginImage src={loginBanner} alt="Login banner" />
      </ImageContainer>
      <FormContainer>
        <FormTitle primary>Inicia sesión para comenzar</FormTitle>
        <Formik initialValues={initialValues} validationSchema={loginScheme} onSubmit={onSubmit}>
          <FormRoot columns={1}>
            <div>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input id="email" name="email" type="text" placeholder="Ingresá tu email" />
              <FormError name="email" />
            </div>
            <div>
              <FormLabel htmlFor="password">Contraseña</FormLabel>
              <Input id="password" name="password" type="password" placeholder="Ingresá tu contraseña" />
              <FormError name="password" />
            </div>
            <div style={{ textAlign: "right" }}>
              <FormLink to={routes.passwordReset()}>Olvidé mi contraseña</FormLink>
            </div>
            <div>
              <FormError showUntouchedErrors name="generic" />
            </div>
            <div style={{ marginTop: 10 }}>
              <FormButton name="loginButton" type="submit">
                Iniciar sesión
              </FormButton>
            </div>
            <div style={{ textAlign: "center" }}>
              <FormLabel fontWeight={400}>
                ¿No tienes una cuenta? <FormLink to={routes.signup()}>Registrarse</FormLink>
              </FormLabel>
            </div>
          </FormRoot>
        </Formik>
      </FormContainer>
    </LoginContainer>
  );
};

const getLoginServerError = (result) => {
  let errors = {};

  switch (result.error.code) {
    case "auth/user-not-found":
      errors.email = "El email ingresado no es válido";
      break;
    case "auth/wrong-password":
      errors.password = "La contraseña ingresada es incorrecta";
      break;
    case "auth/too-many-requests":
      errors.generic =
        "El acceso a esta cuenta ha sido temporalmente deshabilitado debido a muchos " +
        "intentos de inicio de sesión fallidos. Puedes restaurarla inmediatamente " +
        "restableciendo tu contraseña o puedes intentarlo de nuevo más tarde";
      break;
    default:
      errors.generic = result.error.message;
  }
  return errors;
};
