import { updateProfileImage, updateUser } from "../../services/user-service";
import { useDispatch, useSelector } from "react-redux";

import { AccountForm } from "../../forms/account-form/account-form";
import { routes } from "../../navigation/routes";
import { selectUser } from "../../slices/user-slice";
import { useNavigate } from "react-router-dom";

export const AccountTab = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const onUpdateAvatar = (file) => {
    dispatch(updateProfileImage(file));
  };

  const onSubmit = async (data, formik) => {
    const result = await dispatch(
      updateUser({
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        gender: data.gender,
      })
    );

    if (result.type === "user/update/rejected") {
      formik.setErrors({
        generic: result.error.message,
      });
    }
    if (result.type === "user/update/fulfilled") {
      navigate(routes.setupProfile());
    }
  };
  return <AccountForm data={user} onUpdateAvatar={onUpdateAvatar} onSubmit={onSubmit} submitText={"Guardar"} />;
};
