import { DatePickerField, TimePickerField } from "../../components/date-time";
import { FormBadge, FormButton, FormError, FormLabel, FormRoot, FormTitle, Input } from "../../components";
import { Formik, useFormikContext } from "formik";
import { useEffect, useMemo, useRef } from "react";

import { RowSpan } from "../../components/column/styles";
import { SelectArray } from "../../components/select-array";
import { SelectField } from "../../components/select";
import { appointmentScheme } from "../../helpers/schemas";
import communes from "../../assets/data/communes.json";
import { getServiceTypeName } from "../../helpers/format";
import styled from "styled-components";

export const ServicesField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const AppointmentForm = (props) => {
  const formik = useRef();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    commune: "",
    address: "",
    other: "",
    postalCode: "",
    serviceType: "",
    services: [""],
    ...(props.initialValues || {}),
  };

  // solo lista types de los servicios del profesional
  const serviceTypes = useMemo(() => {
    return props.services
      .map((service) => service.type)
      .flat()
      .reduce((acc, type) => {
        if (!acc.some((t) => t.value === type)) {
          acc.push({
            label: getServiceTypeName([type]),
            value: type,
          });
        }
        return acc;
      }, []);
  }, [props.services]);

  return (
    <Formik
      innerRef={formik}
      initialValues={initialValues}
      validationSchema={appointmentScheme}
      onSubmit={props.onSubmit}
    >
      <FormRoot columns={2}>
        <RowSpan block span={2}>
          <FormTitle style={{ margin: 0 }}>Contacto</FormTitle>
        </RowSpan>
        <div>
          <FormLabel htmlFor="firstName">Nombre</FormLabel>
          <Input id="firstName" name="firstName" type="text" placeholder="Ingrese nombre" />
          <FormError name="firstName" />
        </div>
        <div>
          <FormLabel htmlFor="lastName">Apellido</FormLabel>
          <Input id="lastName" name="lastName" type="text" placeholder="Ingrese apellido" />
          <FormError name="lastName" />
        </div>
        <div>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input id="email" name="email" type="text" placeholder="Ingrese el email" />
          <FormError name="email" />
        </div>
        <div>
          <FormLabel htmlFor="phone">Teléfono</FormLabel>
          <Input id="phone" name="phone" type="text" placeholder="Ingrese el teléfono" />
          <FormError name="phone" />
        </div>
        <RowSpan block span={2}>
          <FormTitle style={{ margin: 0 }}>Dirección</FormTitle>
        </RowSpan>
        <div>
          <FormLabel htmlFor="commune">Comúna</FormLabel>
          <SelectField id="commune" name="commune" items={communes} placeholder="Comuna" />
          <FormError name="commune" />
        </div>
        <div>
          <FormLabel htmlFor="address">Calle y numeración</FormLabel>
          <Input id="address" name="address" />
          <FormError name="address" />
        </div>
        <div>
          <FormLabel flex htmlFor="other">
            Otro
            <FormBadge>Opcional</FormBadge>
          </FormLabel>
          <Input id="other" name="other" />
          <FormError name="other" />
        </div>
        <div>
          <FormLabel htmlFor="postalCode">
            Código Postal
            <FormBadge>Opcional</FormBadge>
          </FormLabel>
          <Input id="postalCode" name="postalCode" />
          <FormError name="postalCode" />
        </div>
        <RowSpan block span={2}>
          <FormTitle style={{ margin: 0 }}>Datos de la cita</FormTitle>
        </RowSpan>
        <div>
          <FormLabel htmlFor="date">Fecha</FormLabel>
          <DatePickerField id="date" name="date" />
          <FormError /*TODO: remove */ showUntouchedErrors /*me*/ name="date" />
        </div>
        <div>
          <FormLabel htmlFor="time">Hora</FormLabel>
          <TimePickerField id="time" name="time" />
          <FormError /*TODO: remove */ showUntouchedErrors /*me*/ name="time" />
        </div>
        <RowSpan span={2} style={{ flexDirection: "column" }}>
          <FormLabel htmlFor="serviceType">Tipo de servicio</FormLabel>
          <SelectField
            id="serviceType"
            name="serviceType"
            items={serviceTypes}
            placeholder="Selecciona el tipo de servicio"
          />
          <FormError name="serviceType" />
        </RowSpan>

        <RowSpan span={2} style={{ flexDirection: "column" }}>
          <FormLabel>Servicios</FormLabel>
          <FilteredSelectArray
            name="services"
            items={props.services}
            labelField={"name"}
            dataField={"id"}
            pushMessage={"+ Agregar otro servicio"}
            placeholder={"Seleccioná el servicio"}
          />
          <FormError name="services" />
        </RowSpan>

        <RowSpan span={2} style={{ marginTop: 10 }}>
          <FormError showUntouchedErrors name="generic" />
        </RowSpan>
        <FormButton $type="secondary" type="button" onClick={props.onCancel}>
          Cancelar
        </FormButton>
        <FormButton type="submit">Guardar</FormButton>
      </FormRoot>
    </Formik>
  );
};

//HOC para filtrar los items de servicio basados en el service type
function FilteredSelectArray(props) {
  const { items, ...rest } = props;
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (!items.every((item) => item.type.includes(values.serviceType))) {
      setFieldValue("services", [""]);
    }
  }, [values.serviceType]);

  // solo lista servicios del tipo seleccionado
  const filteredItems = useMemo(() => {
    return items.filter((item) => item.type.includes(values.serviceType));
  }, [items, values.serviceType]);

  return <SelectArray {...rest} items={filteredItems} />;
}
