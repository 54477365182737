import "react-big-calendar/lib/css/react-big-calendar.css";

import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";

import { AppointmentState } from "../../helpers/consts";
import { EventWrapper } from "./schedule-event";
import { ScheduleToolbar } from "./schedule-toolbar";
import { StyledCalendar } from "./styles";
import { dateFnsLocalizer } from "react-big-calendar";
import en from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
import format from "date-fns/format";
import { getAppointments } from "../../services/appointments-service";
import getDay from "date-fns/getDay";
import { getServices } from "../../services/services-service";
import { getTimeoff } from "../../services/timeoff-service";
import { isWithinRange } from "../../helpers/date-time";
import parseISO from "date-fns/parseISO";
import { routes } from "../../navigation/routes";
import { selectAppointments } from "../../slices/appointments-slice";
import { selectServices } from "../../slices/services-slice";
import { selectTimeOff } from "../../slices/timeoff-slice";
import { selectUser } from "../../slices/user-slice";
import startOfWeek from "date-fns/startOfWeek";
import { subMonths } from "date-fns";
import { theme } from "../../styles/theme";
import { useQueryParams } from "../../hooks/use-query-params";

const MIN_DATE = subMonths(new Date(), 6);

const locales = {
  es: es,
};

const localizer = dateFnsLocalizer({
  format,
  parse: (value) => parseISO(value),
  startOfWeek,
  getDay,
  locales,
});

const messages = {
  week: "Semana",
  work_week: "Semana de trabajo",
  day: "Día",
  month: "Mes",
  previous: "Atrás",
  next: "Después",
  today: "Hoy",
  agenda: "Agenda",

  showMore: (total) => `+${total} más`,
};
export const ScheduleScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params, updateParams] = useQueryParams();
  const appointments = useSelector(selectAppointments);
  const timeoff = useSelector(selectTimeOff);
  const services = useSelector(selectServices);
  const user = useSelector(selectUser);

  // refresco dependencias para el calendario
  useEffect(() => {
    dispatch(getServices());
    dispatch(getAppointments(MIN_DATE));
    dispatch(getTimeoff());
  }, []);

  // event filter
  const events = useMemo(() => {
    let events = appointments.filter((app) => {
      return app.state === AppointmentState.PENDING || app.state === AppointmentState.APPROVED;
    });
    if (params.service) {
      events = appointments.filter((appointment) => {
        return appointment.services.includes(params.service);
      });
    }
    return events.concat(timeoff);
  }, [params.service, appointments, timeoff]);

  // availability
  const slotPropGetter = useMemo(
    () => (date) => {
      const dayOfWeek = format(date, "EEEE", { locale: en }).toLocaleLowerCase();
      const availability = dayOfWeek in (user.availability || {}) ? user.availability[dayOfWeek] : [];
      const unavailable = dayOfWeek in (user.unavailable || {}) ? user.unavailable[dayOfWeek] : [];
      const available = isWithinRange(date, availability) && !isWithinRange(date, unavailable);

      if (!available) {
        return { style: { backgroundColor: theme.colors.UNAVAILABLE }, disabled: true };
      }
      return {};
    },
    [user.availability, user.unavailabe]
  );

  const onSelectEvent = ({ id, type }) => {
    if (type === "timeoff") {
      navigate(routes.deleteTimeoff(id));
    } else {
      navigate(routes.scheduleViewAppointment(id));
    }
  };

  const toolbar = useMemo(() => {
    return (props) => (
      <ScheduleToolbar
        {...props}
        minDate={MIN_DATE}
        services={services}
        selectedService={params.service}
        onAction={(value) => {
          navigate(value);
        }}
        onChangeService={(value) => {
          navigate({
            pathname: routes.schedule(),
            search: updateParams("service", value),
          });
        }}
      />
    );
  }, [services, params.service]);

  return (
    <>
      <StyledCalendar
        localizer={localizer}
        messages={messages}
        events={events}
        culture={"es"}
        showMultiDayTimes={true}
        views={["month", "day", "week"]}
        step={15}
        components={{
          toolbar,
          eventWrapper: EventWrapper,
        }}
        selectable
        titleAccessor="title"
        onSelectEvent={onSelectEvent}
        slotPropGetter={slotPropGetter}
        startAccessor={startAccessor}
        endAccessor={endAccessor}
      />
      <Outlet />
    </>
  );
};

const endAccessor = (e) => parseISO(e.endDate);
const startAccessor = (e) => parseISO(e.startDate);
// disabledSchedule
//
