import { addDoc, collection, deleteDoc, doc, getDocs, query, where } from "firebase/firestore";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { database } from "../firebase";
import { formatISO } from "date-fns";

export const getTimeoff = createAsyncThunk("timeoff/get", async (_, { getState }) => {
  const state = getState();
  const user = state.user.data;
  const uid = user.uid;

  const timeoffRef = collection(database, "timeoff");
  const timeoffQuery = query(timeoffRef, where("createdBy", "==", uid));

  const result = await getDocs(timeoffQuery);
  return result.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
});

export const createTimeoff = createAsyncThunk("timeoff/create", async (data, { getState }) => {
  const state = getState();
  const user = state.user.data;
  const uid = user.uid;

  const collectionRef = collection(database, "timeoff");

  const startDate = new Date(
    data.date.getFullYear(),
    data.date.getMonth(),
    data.date.getDate(),
    data.startDate.getHours(),
    data.startDate.getMinutes()
  );
  const endDate = new Date(
    data.date.getFullYear(),
    data.date.getMonth(),
    data.date.getDate(),
    data.endDate.getHours(),
    data.endDate.getMinutes()
  );

  const timeOffData = {
    title: "Horario bloqueado",
    type: "timeoff",
    startDate: formatISO(startDate),
    endDate: formatISO(endDate),
    createdBy: uid,
  };

  const result = await addDoc(collectionRef, timeOffData);

  return { ...timeOffData, id: result.id };
});

export const deleteTimeoff = createAsyncThunk("timeoff/delete", async (id) => {
  const documentRef = doc(database, "timeoff", id);
  await deleteDoc(documentRef);

  return id;
});
