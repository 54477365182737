import * as Select from "@radix-ui/react-select";

import { Icon } from "../icon";
import styled from "styled-components";

export const SelectTrigger = styled(Select.Trigger)`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 0 15px 0 30px;
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 45px;
  gap: 5px;
  background-color: white;
  color: ${(props) => props.theme.colors.PRIMARY};
  border: 1px solid ${(props) => props.theme.colors.STROKE};

  .fi-rr-angle-small-down {
    color: ${(props) => props.theme.colors.TEXT_DARK};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.STROKE};
  }
`;

export const SelectContent = styled(Select.Content)`
  width: ${(props) => props.$width || 0}px;
  overflow: hidden;
  background-color: white;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 1px solid ${(props) => props.theme.colors.STROKE};
`;

export const SelectItem = styled(Select.Item)`
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.colors.TEXT_DARK};
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 45px;
  padding: 0 35px 0 25px;
  position: relative;
  user-select: none;

  &[data-disabled] {
    color: ${(props) => props.theme.colors.STROKE};
    pointer-events: none;
  }
  &[data-highlighted] {
    outline: none;
    background-color: ${(props) => props.theme.colors.STROKE};
    color: ${(props) => props.theme.colors.TEXT_DARK};
  }
  &[data-active] {
    color: ${(props) => props.theme.colors.PRIMARY};
  }
`;

export const SelectViewport = styled(Select.Viewport)`
  padding: 5px;
`;

export const SelectScrollDownButton = styled(Select.ScrollDownButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  color: ${(props) => props.theme.colors.TEXT_DARK};
  cursor: default;
`;

export const SelectScrollUpButton = styled(Select.ScrollUpButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  color: ${(props) => props.theme.colors.TEXT_DARK};
  cursor: default;
`;

export const SelectIcon = styled(Icon)`
  margin-right: 11px;
`;
