import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from "redux-persist";

import storage from "redux-persist/lib/storage";

export const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["loader", "scheduling"],
};

export const ignoredActions = [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER];
