import { getLabelStyles } from "../../helpers/colors";
import styled from "styled-components";

const EventContainer = styled.div`
  padding: 5px;
  & > .rbc-event {
    color: ${(props) => props.$color};
    background-color: ${(props) => props.$backgroundColor};
    font-family: ${(props) => props.theme.fonts.NOTO_SANS};
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    ${(props) =>
      props.$timeoff
        ? `
          background-color:#cecece;
           color: #0a0a0a;`
        : ``};
  }
`;

export const EventWrapper = (props) => {
  return (
    <EventContainer {...getLabelStyles(props.event.title)} $timeoff={props.event.type === "timeoff"}>
      {props.children}
    </EventContainer>
  );
};
