import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { AlertModal } from "../../components";
import { getServiceStatusName } from "../../helpers/format";
import { selectService } from "../../slices/services-slice";
import { updateService } from "../../services/services-service";

export const ChangeServiceStatusModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const service = useSelector(selectService(params?.id));

  // next service status
  const nextStatus = service.status === "active" ? "inactive" : "active";
  const nextStatusText = getServiceStatusName(nextStatus).toLocaleLowerCase();

  const onAccept = async () => {
    const result = await dispatch(
      updateService({
        id: params.id,
        status: nextStatus,
      })
    );

    if (result.type === "service/update/rejected") {
      console.error("error updating service", result);
    }
    if (result.type === "service/update/fulfilled") {
      navigate(-1);
    }
  };
  const onModalClose = () => {
    navigate(-1);
  };

  return (
    <AlertModal
      title="¿Estas seguro?"
      message={`El estado de este servicio se cambiará a ${nextStatusText}`}
      onModalClose={onModalClose}
      onCancel={onModalClose}
      onAccept={onAccept}
    />
  );
};
