import {
  Content,
  Icon,
  Item,
  ItemIndicator,
  Label,
  ScrollDownButton,
  ScrollUpButton,
  Separator,
  Trigger,
  Viewport,
} from "@radix-ui/react-select";

import styled from "styled-components";

export const SelectTrigger = styled(Trigger)`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  padding: 0 15px;
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  height: 35px;
  gap: 5px;
  background-color: white;
  color: ${(props) => props.theme.colors.TEXT_DARK};
  border: 1px solid ${(props) => props.theme.colors.STROKE};

  &[data-placeholder] {
    color: #747574;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.STROKE};
  }
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.PRIMARY};
  }
`;

export const SelectContent = styled(Content)`
  overflow: hidden;
  background-color: white;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.STROKE};
  z-index: 101;
`;

export const SelectItem = styled(Item)`
  font-size: 13px;
  line-height: 1;
  color: ${(props) => props.theme.colors.TEXT_DARK};
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 35px 0 25px;
  position: relative;
  user-select: none;

  &[data-disabled] {
    color: ${(props) => props.theme.colors.STROKE};
    pointer-events: none;
  }
  &[data-highlighted] {
    outline: none;
    background-color: ${(props) => props.theme.colors.STROKE};
    color: ${(props) => props.theme.colors.TEXT_DARK};
  }
`;

export const SelectViewport = styled(Viewport)`
  padding: 5px;
`;

export const SelectItemIndicator = styled(ItemIndicator)`
  position: absolute;
  left: 4px;
  width: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const SelectScrollDownButton = styled(ScrollDownButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  color: ${(props) => props.theme.colors.TEXT_DARK};
  cursor: default;
`;

export const SelectScrollUpButton = styled(ScrollUpButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  color: ${(props) => props.theme.colors.TEXT_DARK};
  cursor: default;
`;
