import { Outlet, useLocation } from "react-router-dom";
import { getAllNotifications, listenForNewNotifications } from "../services/notifications-service";
import { selectChecked, selectNotifications, setChecked, setNewNotifications } from "../slices/notifications-slice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";

import { MobileNav } from "../components/mobile-nav";
import { Notifications } from "../components/notifications";
import { Page } from "../components/page";
import { Sidebar } from "../components/sidebar";
import defaultItems from "../components/sidebar/items";
import { logout } from "../services/user-service";
import { routes } from "../navigation/routes";
import { selectUser } from "../slices/user-slice";
import { useMediaQuery } from "../hooks/use-media-query";

export const AccountLayout = () => {
  const user = useSelector(selectUser) || {};
  const [showNotifs, setNotifs] = useState(false);
  const notifications = useSelector(selectNotifications);
  const checked = useSelector(selectChecked);
  const mobile = useMediaQuery("(max-width: 918px)");
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const prevPathname = useRef(pathname);

  useEffect(() => {
    if (prevPathname.current !== pathname) {
      prevPathname.current = pathname;
      if (mobile) {
        setSidebarOpen(false);
      }
    }
  }, [pathname, mobile]);

  useEffect(() => {
    const unsub = listenForNewNotifications(user.uid, (notifs) => {
      dispatch(setNewNotifications(notifs));
    });
    return () => {
      unsub();
    };
  }, [user.uid]);

  const showNotificationsClick = () => {
    setNotifs(!showNotifs);
    dispatch(setChecked());
  };

  const onCloseSidebar = () => {
    setSidebarOpen(false);
  };

  const onOpenSidebar = () => {
    setSidebarOpen(true);
  };

  const items = useMemo(() => {
    // deshabilito items que no forman parte del onboarding flow
    return {
      ...defaultItems,
      user: { ...defaultItems.user, text: user.firstName },
      notifications: { ...defaultItems.notifications, onClick: showNotificationsClick },
      logout: { ...defaultItems.logout, onClick: () => dispatch(logout()) },
      share: { ...defaultItems.share, to: routes.shareProfile(user.uid) },
    };
  }, [user.uid, user.firstName, dispatch, showNotifs]);

  return (
    <div style={{ display: "flex", height: "100%", overflowY: "scroll" }}>
      <Sidebar
        open={sidebarOpen || !mobile}
        onCloseSidebar={onCloseSidebar}
        items={items}
        notificationsBadge={!checked}
      />
      <Notifications notifications={notifications} open={showNotifs} onClose={() => setNotifs(false)} />
      <Page>
        {mobile && <MobileNav onOpenSidebar={onOpenSidebar} />}
        <Outlet />
      </Page>
    </div>
  );
};
