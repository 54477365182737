import styled from "styled-components";

export const AppointmentFormContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 20px;
  margin-right: 20px;
`;

export const AppointmentTitle = styled.div`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  margin-bottom: 10px;
`;

export const AppointmentInfo = styled.div`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-style: normal;
  font-size: 12px;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 18px;
  margin-bottom: 18px;
  flex-wrap: wrap;
`;
export const AppointmentInfoField = styled.div`
  display: flex;
  align-items: center;
  & > .icon {
    margin-right: 7px;
  }
`;

export const AppointmentDescription = styled.div`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  margin-bottom: 18px;
`;

export const ResponseContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const Response = styled.div`
  max-width: 240px;
`;
