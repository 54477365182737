import { FirstSectionContainer, Shapes, Title } from "./styles";

import React from "react";
import shape1 from "../../assets/images/shape-1.png";
import shape2 from "../../assets/images/shape-2.png";
import shape3 from "../../assets/images/shape-3.png";
import { useMovingShapes } from "../../hooks/use-moving-shapes";

export const LandingScreen = () => {
  const [ref, shapes] = useMovingShapes([shape1, shape2, shape3]);
  return (
    <FirstSectionContainer>
      <Shapes ref={ref}>{shapes}</Shapes>
      <Title>{process.env.REACT_APP_ENV === "production" ? "production" : "development"}</Title>
    </FirstSectionContainer>
  );
};
