import { Navbar } from "../components";
import { Outlet } from "react-router-dom";

export const PublicLayout = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%", overflowY: "scroll" }}>
      <Navbar />
      <Outlet />
    </div>
  );
};
