import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

import { useDimensions } from "./use-diemnsions";
import { useInterval } from "./use-interval";

/**
 * Hook para visualizar shapes que rebotan en los límites del contenedor padre.
 * @param {*} shapeSources listado de imagenes
 * @returns
 */
export const useMovingShapes = (shapeSources) => {
  const [ref, dimensions] = useDimensions();
  const [shapes, setShapes] = useState([]);

  const width = dimensions.width;
  const height = dimensions.height;

  useEffect(() => {
    if (dimensions.width) {
      setShapes(initializeShapes(shapeSources, dimensions));
    }
  }, [dimensions.width, ...shapeSources]);

  useInterval(() => {
    let updatedShapes = shapes.map((shape) => {
      const { x, y } = shape.position;
      let newX = x + shape.speedX;
      let newY = y + shape.speedY;

      return {
        ...shape,
        angle: shape.angle + 2,
        speedX: newX > width || newX < 0 ? -shape.speedX : shape.speedX,
        speedY: newY > height || newY < 0 ? -shape.speedY : shape.speedY,
        position: {
          x: newX,
          y: newY,
        },
      };
    });
    setShapes(updatedShapes);
  }, 66);

  const component = (
    <>
      {shapes.map((shape) => (
        <Image
          key={shape.source}
          src={shape.source}
          style={{ transform: `translate(${shape.position.x}px, ${shape.position.y}px) rotate(${shape.angle}deg)` }}
        />
      ))}
    </>
  );

  return [ref, component];
};

const bounce = keyframes`
  0%   { transform: translate(0, 0) rotate(0deg); }
  50%  { transform: translate(0, 200px) rotate(180deg); }
  100% { transform: translate(0, 0) rotate(360deg); }
`;

const Image = styled.img`
  position: absolute;
  animation: ${bounce} linear infinite;
`;

// inicializa las figuras
const initializeShapes = (shapes, dimensions) => {
  const getRandomParam = (max) => Math.floor(Math.random() * max) + 1;

  return shapes.map((shape) => ({
    source: shape,
    angle: getRandomParam(2),
    speedX: getRandomParam(2),
    speedY: getRandomParam(2),
    position: {
      x: getRandomParam(dimensions.width),
      y: getRandomParam(dimensions.height),
    },
  }));
};
