import { AlertContainer, AlertMessage } from "./styles";
import { CardBody, CardClose, CardFooter, CardTitle, FormButton, Modal } from "../../components";

import { StyledIcon } from "../../components/icon/styles";
import { theme } from "../../styles/theme";

export const AlertModal = (props) => {
  return (
    <Modal expand onClose={props.onModalClose}>
      <CardTitle>
        {props.title}
        <CardClose onClick={props.onModalClose} />
      </CardTitle>
      <CardBody>
        <AlertContainer>
          <StyledIcon $size={24} className="fi-rr-exclamation" color={theme.colors.SECONDARY} />
          <AlertMessage>{props.message}</AlertMessage>
        </AlertContainer>
      </CardBody>
      <CardFooter $alignRight>
        <FormButton style={{ width: "auto" }} $type="secondary" type="button" onClick={props.onCancel}>
          Cancelar
        </FormButton>
        <FormButton style={{ width: "auto" }} type="button" onClick={props.onAccept}>
          Confirmar
        </FormButton>
      </CardFooter>
    </Modal>
  );
};
