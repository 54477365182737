import { FormButton } from "../../components";
import styled from "styled-components";

export const AddServiceButton = styled(FormButton)`
  width: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
  margin-bottom: 20px;
`;
