import styled, { css } from "styled-components";

export const GridOneColumnsStyle = css`
  display: grid;
  gap: 20px 15px;
  grid-template-columns: 1fr;
`;

export const GridTwoColumnsStyle = css`
  display: grid;
  gap: 20px 15px;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export const GridThreeColumnsStyle = css`
  display: grid;
  gap: 20px 15px;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 918px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export const GridSpanTwoStyle = css`
  grid-column: 1 / span 2;
  @media (max-width: 480px) {
    grid-column: 1;
  }
`;

export const GridSpanThreeStyle = css`
  grid-column: 1 / span 3;

  @media (max-width: 918px) {
    grid-column: 1 / span 2;
  }

  @media (max-width: 480px) {
    grid-column: 1;
  }
`;

export const RowSpan = styled.div`
  ${(props) => props.span === 2 && GridSpanTwoStyle}
  ${(props) => props.span === 3 && GridSpanThreeStyle}
  display: ${(props) => (props.block ? "block" : "flex")};
  &:empty {
    display: none;
  }
`;

export const GridTwoColumns = styled.div`
  ${GridTwoColumnsStyle}
`;

export const GridThreeColumns = styled.div`
  ${GridThreeColumnsStyle}
`;

export const GridSpanTwo = styled.div`
  ${GridSpanTwoStyle}
`;
