import {
  CardBody,
  CardDivider,
  CardTitle,
  StepContainer,
  StepDescription,
  StepNumber,
  StepTitle,
} from "../../components";
import { updateProfileImage, updateUser } from "../../services/user-service";
import { useDispatch, useSelector } from "react-redux";

import { AccountForm } from "../../forms/account-form/account-form";
import { Icon } from "../../components/icon";
import { routes } from "../../navigation/routes";
import { selectUser } from "../../slices/user-slice";
import { useNavigate } from "react-router-dom";

export const SetupAccountScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const onUpdateAvatar = (file) => {
    dispatch(updateProfileImage(file));
  };

  const onSubmit = async (data, formik) => {
    const result = await dispatch(
      updateUser({
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        gender: data.gender,
      })
    );

    if (result.type === "user/update/rejected") {
      formik.setErrors({
        generic: result.error.message,
      });
    }
    if (result.type === "user/update/fulfilled") {
      navigate(routes.setupProfile());
    }
  };

  return (
    <div>
      <CardTitle>
        <Icon name="fi-rr-user" className="card-icon" size={20} />
        Crea tu perfil
      </CardTitle>
      <CardBody $borderBottom $roundedBottom>
        <StepContainer>
          <StepTitle>
            <StepNumber>1</StepNumber>Ingresa los datos de tu perfil
          </StepTitle>
          <StepDescription>
            Queremos que tu perfil este bastante completo, por lo que te pedimos que agregues los siguientes datos.
          </StepDescription>
        </StepContainer>
        <CardDivider />
        <AccountForm data={user} onUpdateAvatar={onUpdateAvatar} onSubmit={onSubmit} submitText={"Siguiente"} />
      </CardBody>
    </div>
  );
};
