import { useEffect, useRef } from "react";

/**
Hook personalizado para ejecutar una función de callback en intervalos de tiempo regulares.
@param {function} callback La función de callback a ejecutar en cada intervalo.
@param {number} delay El tiempo (en milisegundos) entre cada intervalo de ejecución de la función de callback.
*/
export function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
