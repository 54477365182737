import { CardBody, CardClose, CardTitle, Modal } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { AppointmentForm } from "./appointment-form";
import { AppointmentFormContainer } from "./styles";
import { createAppointment } from "../../services/appointments-service";
import { getServices } from "../../services/services-service";
import { selectAppointment } from "../../slices/appointments-slice";
import { selectServices } from "../../slices/services-slice";
import { useEffect } from "react";
import { useMemo } from "react";

export const RepeatAppointment = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const services = useSelector(selectServices);
  const appointment = useSelector(selectAppointment(params.id));

  const prefillAppointment = useMemo(() => {
    return {
      services: appointment.services,
      serviceType: appointment.serviceType,
      firstName: appointment.firstName,
      lastName: appointment.lastName,
      email: appointment.email,
      phone: appointment.phone,
      commune: appointment.commune,
      address: appointment.address,
      other: appointment.other,
      postalCode: appointment.postalCode,
    };
  }, [appointment]);

  useEffect(() => {
    if (services.length === 0) {
      dispatch(getServices());
    }
  }, [services]);

  const onSubmit = async (data, formik) => {
    const result = await dispatch(createAppointment(data));

    if (result.type === "appointments/create/rejected") {
      formik.setErrors({
        generic: result.error.message,
      });
    }
    if (result.type === "appointments/create/fulfilled") {
      navigate(-1);
    }
  };
  const onModalClose = () => {
    navigate(-1);
  };

  return (
    <Modal expand onClose={onModalClose}>
      <CardTitle>
        Repetir cita
        <CardClose onClick={onModalClose} />
      </CardTitle>
      <CardBody $borderBottom $roundedBottom>
        <AppointmentFormContainer>
          <AppointmentForm
            onSubmit={onSubmit}
            services={services}
            onCancel={onModalClose}
            initialValues={prefillAppointment}
          />
        </AppointmentFormContainer>
      </CardBody>
    </Modal>
  );
};
