import {
  BottomItems,
  CloseIcon,
  Items,
  Logo,
  LogoContainer,
  SidebarButton,
  SidebarContainer,
  SidebarIcon,
  SidebarLink,
} from "./styles";
import { matchPath, useLocation } from "react-router-dom";

import { Icon } from "../icon";
import defaultItems from "./items";
import logo from "../../assets/images/logo-black.png";

export const Sidebar = (props) => {
  const items = props.items || defaultItems;

  return (
    <SidebarContainer open={props.open}>
      <div>
        <LogoContainer>
          <Logo src={logo} alt="Groopa" />
          <CloseIcon onClick={props.onCloseSidebar} />
        </LogoContainer>
        <Items>
          <Link {...items.schedule} />
          <Link {...items.history} />
          {/*    <Link {...items.messages} /> */}
          <Link {...items.clients} />
          <Link {...items.services} />
        </Items>
      </div>
      <BottomItems>
        <Button badge={props.notificationsBadge} {...items.notifications} />
        <Link {...items.user} />
        <Link {...items.share} />
        <Button {...items.logout} />
      </BottomItems>
    </SidebarContainer>
  );
};

const Link = ({ to, icon, text, enabled, activeFor }) => {
  const location = useLocation();

  const handleClick = (e) => {
    if (!enabled) e.preventDefault();
  };

  let className = "";
  if (typeof activeFor !== "undefined" && matchPath(activeFor, location.pathname)) {
    className = "active";
  }

  return (
    <SidebarLink to={to} aria-label={text} className={className} $enabled={enabled} onClick={handleClick}>
      <Icon name={icon} />
      <span className="label">{text}</span>
    </SidebarLink>
  );
};
const Button = ({ icon, text, enabled, onClick, badge }) => {
  return (
    <SidebarButton aria-label={text} $enabled={enabled} onClick={onClick}>
      <Icon badge={badge} name={icon} />
      <span className="label">{text}</span>
    </SidebarButton>
  );
};
