import { AddButton, RemoveButton } from "./styles";
import { Icon, Input } from "..";

import { FieldArray } from "formik";

export const InputArray = (props) => {
  return (
    <FieldArray
      name={props.name}
      render={(arrayHelpers) => {
        const values = arrayHelpers.form.values[props.name];
        return (
          <>
            {values.map((_, index) => (
              <div key={index} style={{ display: "inline-flex", marginBottom: 10 }}>
                <Input id={`${props.name}.${index}`} name={`${props.name}.${index}`} type="text" />
                {index > 0 && (
                  <RemoveButton type="button" onClick={() => arrayHelpers.remove(index)}>
                    <Icon name="fi-rr-delete" />
                  </RemoveButton>
                )}
              </div>
            ))}
            <AddButton type="button" onClick={() => arrayHelpers.push("")}>
              + Añadir otra categoría
            </AddButton>
          </>
        );
      }}
    />
  );
};
