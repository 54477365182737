import { addDoc, and, collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { database, functions } from "../firebase";
import { formatISO, getUnixTime, parseISO, startOfToday } from "date-fns";

import { AppointmentState } from "../helpers/consts";
import addMinutes from "date-fns/addMinutes";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { httpsCallable } from "firebase/functions";
import set from "date-fns/set";

export const getAppointments = createAsyncThunk("appointments/get", async (since, { getState }) => {
  const state = getState();
  const user = state.user.data;
  const uid = user.uid;

  const minDate = typeof since === "undefined" ? startOfToday() : since;
  const appointmentsRef = collection(database, "appointments");

  const queryConstraints = [];
  queryConstraints.push(where("createdBy", "==", uid));
  queryConstraints.push(where("startTimestamp", ">", getUnixTime(minDate)));

  const appointmentsQuery = query(appointmentsRef, ...queryConstraints);

  const result = await getDocs(appointmentsQuery);
  return result.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
});

export const createAppointment = createAsyncThunk("appointments/create", async (data, { getState }) => {
  const state = getState();
  const user = state.user.data;
  const uid = user.uid;

  // calculate appointment duration
  const servicesFiltered = data.services.map((id) => state.services.data.find((s) => s.id === id));
  const price = servicesFiltered.reduce((total, service) => total + service.price, 0);
  const durationMinutes = servicesFiltered.reduce((total, service) => total + service.durationMinutes, 0);
  const title = servicesFiltered.map((service) => service.name).join(" + ");

  // calculate start and end dates
  const startDate = set(data.date, {
    hours: data.time.getHours(),
    minutes: data.time.getMinutes(),
  });
  const endDate = addMinutes(startDate, durationMinutes);

  const createAppointmentFunction = httpsCallable(functions, "createAppointment");
  const result = await createAppointmentFunction({
    title: title,
    price: price,
    durationMinutes: durationMinutes,
    startDate: formatISO(startDate),
    endDate: formatISO(endDate),
    services: data.services,
    serviceType: data.serviceType,
    createdBy: uid,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phone: data.phone,
    commune: data.commune,
    address: data.address,
    other: data.other,
    postalCode: data.postalCode,
  });

  return result.data;
});

export const approveAppointment = createAsyncThunk("appointments/approve", async (id) => {
  await updateDoc(doc(database, "appointments", id), {
    state: AppointmentState.APPROVED,
  });

  return id;
});

export const rejectAppointment = createAsyncThunk("appointments/reject", async (id) => {
  await updateDoc(doc(database, "appointments", id), {
    state: AppointmentState.REJECTED,
  });

  return id;
});

export const updateAppointment = createAsyncThunk("appointments/update", async (data) => {
  // calculate start and end dates
  const startDate = set(data.date, {
    hours: data.time.getHours(),
    minutes: data.time.getMinutes(),
  });
  const endDate = addMinutes(startDate, data.durationMinutes);

  const updatedFields = {
    startTimestamp: getUnixTime(startDate),
    startDate: formatISO(startDate),
    endDate: formatISO(endDate),
  };
  await updateDoc(doc(database, "appointments", data.id), updatedFields);

  return { id: data.id, ...updatedFields };
});
