import styled, { css } from "styled-components";

import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import sortIcon from "../../assets/images/sort-icon.svg";

/* prettier-ignore */
const menuTransition =css`
  &.slide-enter {
    transform: translateX(0);
    opacity: 0;
  }
  &.slide-enter-active {
    transform: translateX(-100%);
    opacity: 1;
    transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
  }
  &.slide-exit {
    transform: translateX(-100%);
    opacity: 1;
  }
  &.slide-exit-active {
    transform: translateX(0);
    opacity: 0;
    transition: transform 300ms ease-out, opacity 300ms ease-out;
  }
`;

/* prettier-ignore */
const rowButtonStyles =css`
  background-color: ${(props) => props.theme.colors.WHITE};
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;
  margin: 0;
  width: 48px;
  height: 48px;
  cursor: pointer;
  position: relative;
`;

export const TableRowMenuButtonContainer = styled.button`
  ${rowButtonStyles}
  color: ${(props) => props.theme.colors.TEXT_DARK};
  &:hover {
    color: ${(props) => props.theme.colors.SECONDARY};
  }
`;

export const TableRowMenuLinkContainer = styled(Link)`
  ${rowButtonStyles}
  color: ${(props) => props.theme.colors.TEXT_DARK};
  &:hover {
    color: ${(props) => props.theme.colors.SECONDARY};
  }

  &,
  &:link {
    text-decoration: none;
  }
`;

export const TableRowEditButtonContainer = styled.button`
  ${rowButtonStyles}
  color: ${(props) => props.theme.colors.SECONDARY};
`;

export const TablebMenu = styled.div`
  ${menuTransition}
  background-color: #f8f9fd;
  position: absolute;
  height: 48px;
  top: 0;
  transform: translateX(-100%);
  /* z-index: -1; */
  display: flex;
  justify-content: flex-end;
  left: -10px;
  gap: 10px;
  @media (max-width: 918px) {
    left: -5px;
    gap: 5px;
  }
`;

export const TableContainer = styled.div`
  position: relative;
`;

export const StyledDataTable = styled(DataTable)`
  .rdt_TableRow {
    border-bottom: 1px solid ${(props) => props.theme.colors.STROKE};
    :hover {
      cursor: pointer;
      background-color: #fdfdfd;
    }
  }
  .rdt_TableRow:last-child {
    ${(props) => (props.noBorderLastRow ? "border:none;" : "")}
  }
  .rdt_TableCol_Sortable {
    font-family: ${(props) => props.theme.fonts.NOTO_SANS};
    color: ${(props) => props.theme.colors.PRIMARY};
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  .rdt_TableCell {
    font-family: ${(props) => props.theme.fonts.NOTO_SANS};
    color: ${(props) => props.theme.colors.TEXT_DARK};
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  @media (max-width: 918px) {
    .rdt_TableHead {
      display: none;
    }
    .rdt_TableRow {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding-top: 10px;
      padding-bottom: 10px;
      position: relative;
    }
    .rdt_TableCell:first-child {
      font-weight: 700;
      font-size: 14px;
      padding-bottom: 5px;
    }
    [data-column-id="cell-menu"] {
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }
`;

export const SortImage = styled.img.attrs({
  src: sortIcon,
})`
  width: 12px;
  height: auto;
  margin-left: 10px;
`;

export const EmptyContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.STROKE};
  height: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmptyMessage = styled.p`
  margin: 0;
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-weight: 400px;
  font-size: 14px;
`;
