import { getFirestore, initializeFirestore } from "firebase/firestore";

import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { initializeApp } from "firebase/app";

let firebaseConfig;
// env based configuration
if (process.env.REACT_APP_ENV === "production") {
  firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_PRODUCTION_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_PRODUCTION_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PRODUCTION_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_PRODUCTION_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_PRODUCTION_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_PRODUCTION_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_PRODUCTION_MEASUREMENT_ID,
  };
} else {
  firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_DEVELOPMENT_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DEVELOPMENT_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_DEVELOPMENT_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_DEVELOPMENT_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_DEVELOPMENT_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_DEVELOPMENT_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_DEVELOPMENT_MEASUREMENT_ID,
  };
}

const app = initializeApp(firebaseConfig);

initializeFirestore(app, {
  ignoreUndefinedProperties: true,
});

const auth = getAuth(app);
const database = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

export { auth, database, storage, functions };
