import { CardBody, CardClose, CardTitle, FormLabel, FormParagraph, Icon, Modal } from "../../components";
import { ServiceDescription, ServiceInfo, ServiceInfoField, ServiceTitle } from "../../components/services/styles";
import { ServiceImage, ServiceImageContainer, ServiceImages } from "./styles";
import { getServiceDuration, getServicePrice, getServiceTypeName } from "../../helpers/format";
import { useNavigate, useParams } from "react-router-dom";

import { selectPublicService } from "../../slices/scheduling-slice";
import { selectService } from "../../slices/services-slice";
import { theme } from "../../styles/theme";
import { useSelector } from "react-redux";

export const ViewServiceModal = (props) => {
  const navigate = useNavigate();
  const params = useParams();

  // works for registered and public users
  const privateService = useSelector(selectService(params?.serviceId));
  const publicService = useSelector(selectPublicService(params?.serviceId));

  const service = props.publicSource ? publicService : privateService;

  const onModalClose = () => {
    navigate(-1);
  };

  return (
    <Modal expand onClose={onModalClose}>
      <CardTitle style={{ justifyContent: "space-between" }}>
        Información del servicio
        <CardClose onClick={onModalClose} />
      </CardTitle>
      <CardBody $borderBottom $roundedBottom style={{ padding: 20 }}>
        <ServiceTitle>{service.name}</ServiceTitle>
        <ServiceInfo>
          <ServiceInfoField>
            <Icon name="fi-rr-money" size={20} color={"#3B3E45"} />
            <FormParagraph>{getServicePrice(service.price, "CLP")}</FormParagraph>
          </ServiceInfoField>
          <ServiceInfoField>
            <Icon name="fi-rr-time-oclock" size={18} color={"#3B3E45"} />
            <FormParagraph>{getServiceDuration(service.durationMinutes)}</FormParagraph>
          </ServiceInfoField>
          <ServiceInfoField>
            <Icon name="fi-rr-building" size={18} color={"#3B3E45"} />
            <FormParagraph>{getServiceTypeName(service.type)}</FormParagraph>
          </ServiceInfoField>
        </ServiceInfo>
        <FormLabel>Descripción</FormLabel>
        <ServiceDescription>{service.description}</ServiceDescription>
        <FormLabel>Gelería </FormLabel>
        <ServiceImages>
          {service.photos.map((photo, index) => (
            <ServiceImageContainer key={index}>
              <ServiceImage src={photo} />
            </ServiceImageContainer>
          ))}
        </ServiceImages>
      </CardBody>
    </Modal>
  );
};
