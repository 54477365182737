import { collection, onSnapshot, query, where } from "firebase/firestore";
import { getUnixTime, subMonths } from "date-fns";

import { database } from "../firebase";

export const listenForNewNotifications = (uid, callback) => {
  const limit = subMonths(new Date(), 3);
  const notificationsRef = query(
    collection(database, "notifications"),
    where("createdBy", "==", uid),
    where("timestamp", ">", getUnixTime(limit))
  );

  return onSnapshot(notificationsRef, (snapshot) => {
    const newNotifications = snapshot.docs.map((doc) => doc.data());
    callback(newNotifications);
  });
};
