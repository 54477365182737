import { ErrorMessage, useField } from "formik";

import { StyledError } from "./styles";

/**
 * Version custom de ErrorMessage que acepta errores de servidor seteados con
 * formik.setErrors y que no necestian tener la propiedad touched en true para ser renderizados.
 */
export const FormError = (props) => {
  const [, meta] = useField(props.name);
  let isVisible = false;
  if (props.showUntouchedErrors) {
    // muestra todos los errores independientemente del estado de touched
    isVisible = !!meta.error;
  } else {
    isVisible = !!meta.error && !!meta.touched;
  }

  return isVisible ? <StyledError>{meta.error}</StyledError> : null;
};
