import styled, { css } from "styled-components";

import { Icon } from "../icon";

// prettier-ignore
const RoundedStyles =css`
  border-top-left-radius: ${(props) => (props.$roundedTop || props.$rounded ? "12px" : "none")};
  border-top-right-radius: ${(props) => (props.$roundedTop || props.$rounded ? "12px" : "none")};
  border-bottom-left-radius: ${(props) => (props.$roundedBottom || props.$rounded ? "12px" : "none")};
  border-bottom-right-radius: ${(props) => (props.$roundedBottom || props.$rounded ? "12px" : "none")};
`;

// prettier-ignore
const BorderStyles =css`
  border-top: 1px solid ${(props) => props.$borderVertical || props.$borderTop ?  props.theme.colors.STROKE : "none"};
  border-bottom: 1px solid ${(props) => props.$borderVertical || props.$borderBottom  ?  props.theme.colors.STROKE : "none"};
  border-left: 1px solid ${(props) => props.$borderHorizontal  || props.$borderLeft ?  props.theme.colors.STROKE : "none"};
  border-right: 1px solid ${(props) => props.$borderHorizontal  || props.$borderRight ?  props.theme.colors.STROKE : "none"};
`;

const CardTitle = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.WHITE};
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.PRIMARY};
  font-weight: 600;
  font-size: 14px;
  padding: 13px 20px;
  ${BorderStyles}
  ${RoundedStyles}
  
  .card-icon {
    margin-right: 10px;
  }
`;

CardTitle.defaultProps = {
  $roundedTop: true,
  $borderVertical: true,
  $borderHorizontal: true,
};

const CardBody = styled.div`
  background-color: ${(props) => props.theme.colors.WHITE};
  overflow: auto;
  ${BorderStyles}
  ${RoundedStyles}
`;

CardBody.defaultProps = {
  $borderHorizontal: true,
};

const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 13px 20px;
  background-color: ${(props) => props.theme.colors.WHITE};
  ${(props) => props.$alignRight && "gap:7px; justify-content: flex-end;"}
  ${BorderStyles}
  ${RoundedStyles}
`;

CardFooter.defaultProps = {
  $roundedBottom: true,
  $borderLeft: true,
  $borderRight: true,
  $borderBottom: true,
};

const CardDivider = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.STROKE};
`;

const CardClose = styled(Icon).attrs((props) => ({
  name: "fi-rr-cross-small",
  color: props.theme.colors.TEXT_DARK,
}))`
  margin-left: auto;
  align-self: flex-end;
  cursor: pointer;
`;

export { CardTitle, CardBody, CardDivider, CardFooter, CardClose };
