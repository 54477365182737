import { FormButton, FormError, FormLabel, FormLink, FormRoot, FormTitle, Input } from "../../components";
import { FormContainer, PasswordUpdateContainer } from "./styles";

import { Formik } from "formik";
import { GridSpanTwo } from "../../components/column/styles";
import { passwordUpdateScheme } from "../../helpers/schemas";
import { routes } from "../../navigation/routes";
import { updatePassword } from "../../services/user-service";
import { useDispatch } from "react-redux";
import { useQueryParams } from "../../hooks/use-query-params";

const initialValues = {
  password: "",
  confirmPassword: "",
};

export const PasswordUpdateScreen = () => {
  const [params] = useQueryParams();
  const dispatch = useDispatch();

  const onSubmit = async (data, formik) => {
    const result = await dispatch(
      updatePassword({
        oobCode: params.oobCode,
        newPassword: data.password,
      })
    );

    if (result.type === "user/password-update/rejected") {
      formik.setErrors(getUpdatePasswordServerError(result));
    }
    if (result.type === "user/password-update/fulfilled") {
      formik.setStatus({
        done: true,
      });
    }
  };

  return (
    <PasswordUpdateContainer>
      <FormContainer>
        <FormTitle primary>Cambio de contraseña</FormTitle>
        <Formik initialValues={initialValues} validationSchema={passwordUpdateScheme} onSubmit={onSubmit}>
          {({ status }) => (
            <FormRoot columns={2}>
              {status?.done ? (
                <GridSpanTwo>
                  <p style={{ margin: 0 }}>
                    Su contraseña se ha cambiado correctamente. Por razones de seguridad, ahora debe iniciar sesión
                    nuevamente con su nueva contraseña. <FormLink to={routes.login()}>Iniciar sesión</FormLink>
                  </p>
                </GridSpanTwo>
              ) : (
                <>
                  <div>
                    <FormLabel htmlFor="password">Nueva contraseña</FormLabel>
                    <Input id="password" name="password" type="password" placeholder="Crea tu nueva contraseña" />
                    <FormError name="password" />
                  </div>
                  <div>
                    <FormLabel htmlFor="confirmPassword">Repetir contraseña</FormLabel>
                    <Input
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      placeholder="Repetí tu nueva contraseña"
                    />
                    <FormError name="confirmPassword" />
                  </div>
                  <GridSpanTwo>
                    <div>
                      <FormError showUntouchedErrors name="generic" />
                    </div>
                  </GridSpanTwo>
                  <GridSpanTwo>
                    <FormButton type="submit">Cambiar contraseña</FormButton>
                  </GridSpanTwo>
                </>
              )}
            </FormRoot>
          )}
        </Formik>
      </FormContainer>
    </PasswordUpdateContainer>
  );
};

const getUpdatePasswordServerError = (result) => {
  let errors = {};

  switch (result.error.code) {
    case "auth/invalid-action-code":
      errors.generic =
        "El enlace de restablecimiento de contraseña es inválido o ha caducado. Por favor, solicita uno nuevo.";
      break;
    default:
      errors.generic = result.error.message;
  }
  return errors;
};
