import { useEffect, useState } from "react";

/**
 * Hook personalizado para detectar media queries.
 * @param {string} query - La cadena de consulta de media query.
 * @returns {boolean} - Verdadero si la consulta de media coincide, falso de lo contrario.
 */
export function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);
    setMatches(mediaQuery.matches);

    const updateMatches = () => setMatches(mediaQuery.matches);

    // soporte para browsers que no tengan addEventListener
    const useLegacyAPI = typeof mediaQuery.addEventListener === "undefined";

    if (useLegacyAPI) {
      mediaQuery.addListener(updateMatches);
    } else {
      mediaQuery.addEventListener("change", updateMatches);
    }
    return () => {
      if (useLegacyAPI) {
        mediaQuery.removeListener(updateMatches);
      } else {
        mediaQuery.removeEventListener("change", updateMatches);
      }
    };
  }, [query]);

  return matches;
}
