import { useEffect, useRef, useState } from "react";

/**
 * Hook para obtener las dimensiones de un elemento y actualizarlas cuando cambian.
 * @param {React.RefObject<HTMLElement>} ref - Referencia del elemento cuyas dimensiones se desean obtener.
 * @returns {Object} Objeto con las dimensiones `width` y `height` del elemento referenciado.
 */
export function useDimensions() {
  const resizeObserverRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const element = resizeObserverRef.current;

    if (!element) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      setDimensions({ width, height });
    });

    observer.observe(element);

    return () => observer.unobserve(element);
  }, []);

  return [resizeObserverRef, dimensions];
}
