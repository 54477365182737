import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const NavbarContainer = styled.nav`
  width: 100%;
  box-shadow: 0 15px 30px 0 #0000000f;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 1;
`;

export const NavbarContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1110px;
  height: 80px;
  margin: 0 auto;

  @media (max-width: 768px) {
    height: 60px;
  }
`;

export const Logo = styled.img`
  width: 160px;
`;

export const LinksContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Link = styled.a`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: #333;
  text-decoration: none;
  margin: 0 15px;
  transition: color 0.2s ease;

  &:hover {
    color: #666;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const SolidLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: 1px solid #e92eff;
  background-color: #e92eff;
  color: #fff;
  cursor: pointer;
  padding: 0 30px;
  font-size: 15px;
  height: 45px;
  border-radius: 6px;
  transition: all 0.4s ease-out 0s;

  & > div {
    margin-right: 7px;
  }

  &:hover {
    background-color: #fff;
    color: #e92eff;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    height: 38px;
  }
  @media (max-width: 480px) {
    font-size: 14px;
    height: 38px;
    padding: 0 16px;
    & > div {
      display: none;
    }
  }
`;
