import { Calendar } from "react-big-calendar";
import styled from "styled-components";

export const StyledCalendar = styled(Calendar)`
  height: 100%;

  .rbc-month-view,
  .rbc-time-view {
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.colors.STROKE};
    background-color: ${(props) => props.theme.colors.WHITE};
    overflow: hidden;
  }
  .rbc-time-header-content {
  }
  .rbc-time-content {
    border-top: 1px solid ${(props) => props.theme.colors.STROKE};
  }
  .rbc-month-header {
    text-transform: capitalize;
  }
  .rbc-time-view > .rbc-time-content {
    border-top: none;
  }

  .rbc-header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${(props) => props.theme.fonts.NOTO_SANS};
    color: ${(props) => props.theme.colors.TEXT_DARK};
    font-weight: 600;
    font-size: 12px;
    height: 44px;
  }
  .rbc-off-range-bg {
    background-color: ${(props) => props.theme.colors.LIGHT_BACKGROUND};
  }
  .rbc-allday-cell {
    display: none;
  }
  .rbc-time-view .rbc-header {
    border-bottom: none;
  }

  .rbc-timeslot-group:last-child,
  .rbc-day-slot.rbc-time-column > .rbc-timeslot-group:nth-last-child(2),
  .rbc-day-slot.rbc-time-column.rbc-now > .rbc-timeslot-group:nth-last-child(3) {
    border-bottom: none;
  }

  .rbc-button-link,
  .rbc-label {
    font-family: ${(props) => props.theme.fonts.NOTO_SANS};
    color: ${(props) => props.theme.colors.TEXT_DARK};
    font-weight: 400;
    font-size: 12px;
  }
  .rbc-show-more {
    font-family: ${(props) => props.theme.fonts.NOTO_SANS};
    color: ${(props) => props.theme.colors.TEXT_DARK};
    font-weight: 700;
    font-size: 12px;
  }
  .rbc-events-container {
    margin-right: 0;
  }
  .rbc-today {
    background-color: #eaf8f7;
  }
`;
