import { format, parseISO } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { AlertModal } from "../../components";
import { approveAppointment } from "../../services/appointments-service";
import es from "date-fns/locale/es";
import { selectAppointment } from "../../slices/appointments-slice";

export const ApproveAppointmentModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const appointment = useSelector(selectAppointment(params.id));
  const date = parseISO(appointment.startDate);
  const dateString = format(date, "EEEE dd MMMM", { locale: es });
  const timeString = format(date, "HH:mm", { locale: es });

  const onAccept = async () => {
    const result = await dispatch(approveAppointment(params.id));

    if (result.type === "appointments/approve/rejected") {
    }
    if (result.type === "appointments/approve/fulfilled") {
      navigate(-1);
    }
  };
  const onModalClose = () => {
    navigate(-1);
  };

  return (
    <AlertModal
      title="¿Estas seguro?"
      message={`Se aceptará la cita para ${appointment.firstName} ${appointment.lastName}, el dia ${dateString} a las ${timeString} con el servicio ${appointment.title}`}
      onModalClose={onModalClose}
      onCancel={onModalClose}
      onAccept={onAccept}
    />
  );
};
