import styled from "styled-components";

export const AccountFormContainer = styled.div`
  flex: 1;
  padding-left: 22px;
  padding-right: 22px;
`;

export const UpdateAvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  grid-column: 3;
  grid-row: 1 / 6;

  @media (max-width: 918px) {
    grid-column: 1 / span 2;
    grid-row: unset;
  }

  @media (max-width: 480px) {
    grid-column: unset;
    grid-row: unset;
  }
`;
