import "react-datepicker/dist/react-datepicker.css";

import { DatePickerContainer, DatePickerInput } from "./styles";
import { useEffect, useMemo, useRef } from "react";

import { Field } from "formik";
import ReactDatePicker from "react-datepicker";
import es from "date-fns/locale/es";

export const TimePicker = (props) => {
  const popperPlacement = props.popperPlacement || "auto";
  // Idealmente deberíamos mostrar el placeholder en la hora, pero hay un nuevo bug
  // introducido recientemente en react-datepicker
  // TODO:
  // https://github.com/Hacker0x01/react-datepicker/issues/3933
  // https://github.com/Hacker0x01/react-datepicker/issues/3921
  const defaultDate = useMemo(() => {
    const defaultDate = new Date();
    defaultDate.setHours(0);
    defaultDate.setMinutes(0);
    return defaultDate;
  }, []);

  return (
    <DatePickerContainer style={props.style}>
      <ReactDatePicker
        showPopperArrow={true}
        locale={es}
        popperPlacement={popperPlacement}
        customInput={<DatePickerInput />}
        selected={props.value || defaultDate}
        placeholderText="Seleccioná la hora"
        onChange={props.onValueChange}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        dateFormat="HH:mm"
      />
    </DatePickerContainer>
  );
};

export const DatePicker = (props) => {
  const popperPlacement = props.popperPlacement || "auto";

  let value = props.value;
  if (props.inline) {
    value = value ?? 0;
  }

  return (
    <DatePickerContainer $borderless={props.borderless}>
      <ReactDatePicker
        key={props.value}
        inline={props.inline}
        calendarStartDay={1}
        showPopperArrow={true}
        locale={es}
        popperPlacement={popperPlacement}
        customInput={<DatePickerInput />}
        selected={value}
        placeholderText="Seleccioná la fecha"
        dateFormat={"dd/MM/yyyy"}
        onChange={props.onValueChange}
        excludeDates={props.excludeDates}
        minDate={props.minDate}
        maxDate={props.maxDate}
      />
    </DatePickerContainer>
  );
};

// Fields

export const DatePickerField = (props) => {
  const onValueChange = (formik) => (value) => {
    formik.form.setFieldValue(formik.field.name, value);
  };
  return (
    <Field
      name={props.name}
      component={(formik) => (
        <DatePicker
          id={props.id}
          value={formik.field.value}
          onValueChange={onValueChange(formik)}
          popperPlacement={props.popperPlacement}
        />
      )}
    />
  );
};

export const TimePickerField = (props) => {
  const onValueChange = (formik) => (value) => {
    formik.form.setFieldValue(formik.field.name, value);
  };
  return (
    <Field
      name={props.name}
      component={(formik) => (
        <TimePicker
          id={props.id}
          value={formik.field.value}
          onValueChange={onValueChange(formik)}
          popperPlacement={props.popperPlacement}
        />
      )}
    />
  );
};
