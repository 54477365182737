import { CardBody, CardClose, CardTitle, Modal } from "../../components";
import { useDispatch, useSelector } from "react-redux";

import { AppointmentFormContainer } from "./styles";
import { TimeOffForm } from "./timeoff-form";
import { createTimeoff } from "../../services/timeoff-service";
import { selectServices } from "../../slices/services-slice";
import { useNavigate } from "react-router-dom";

export const TimeOffModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const services = useSelector(selectServices);

  const onSubmit = async (data, formik) => {
    const result = await dispatch(createTimeoff(data));

    if (result.type === "timeoff/create/rejected") {
      formik.setErrors({
        generic: result.error.message,
      });
    }
    if (result.type === "timeoff/create/fulfilled") {
      navigate(-1);
    }
  };
  const onModalClose = () => {
    navigate(-1);
  };
  return (
    <Modal expand fullheight onClose={onModalClose}>
      <CardTitle>
        Bloquear horario
        <CardClose onClick={onModalClose} />
      </CardTitle>
      <CardBody $borderBottom $roundedBottom>
        <AppointmentFormContainer>
          <TimeOffForm onSubmit={onSubmit} services={services} onCancel={onModalClose} />
        </AppointmentFormContainer>
      </CardBody>
    </Modal>
  );
};
