import {
  approveAppointment,
  createAppointment,
  getAppointments,
  rejectAppointment,
  updateAppointment,
} from "../services/appointments-service";

import { AppointmentState } from "../helpers/consts";
import { createSlice } from "@reduxjs/toolkit";
import { logout } from "../services/user-service";

const INITIAL_STATE = {
  data: [],
  error: false,
  errorMessage: "",
};

const appointmentsSlice = createSlice({
  name: "appointments",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createAppointment.fulfilled, (state, action) => {
      state.error = false;
      state.errorMessage = "";
      state.data.push(action.payload);
    });
    builder.addCase(createAppointment.rejected, (state, action) => {
      state.error = true;
      state.errorMessage = action.error;
    });
    builder.addCase(getAppointments.fulfilled, (state, action) => {
      state.error = false;
      state.errorMessage = "";
      state.data = action.payload;
    });
    builder.addCase(getAppointments.rejected, (state, action) => {
      state.error = true;
      state.errorMessage = action.error;
      state.data = [];
    });
    builder.addCase(approveAppointment.fulfilled, (state, action) => {
      state.error = false;
      state.errorMessage = "";
      const index = state.data.findIndex((appointment) => appointment.id === action.payload);
      if (index !== -1) {
        state.data[index].state = AppointmentState.APPROVED;
      }
    });
    builder.addCase(updateAppointment.fulfilled, (state, action) => {
      state.error = false;
      state.errorMessage = "";
      const index = state.data.findIndex((appointment) => appointment.id === action.payload.id);
      if (index !== -1) {
        state.data[index].startTimestamp = action.payload.startTimestamp;
        state.data[index].startDate = action.payload.startDate;
        state.data[index].endDate = action.payload.endDate;
      }
    });
    builder.addCase(rejectAppointment.fulfilled, (state, action) => {
      state.error = false;
      state.errorMessage = "";
      const index = state.data.findIndex((appointment) => appointment.id === action.payload);
      if (index !== -1) {
        state.data[index].state = AppointmentState.REJECTED;
      }
    });
    builder.addCase(logout.fulfilled, () => {
      return INITIAL_STATE;
    });
  },
});

// selectors
export const selectAppointments = (state) => state.appointments.data;

export const selectAppointment = (id) => (state) => {
  const [appointment] = state.appointments.data.filter((appointment) => appointment.id === id);
  return appointment;
};

export const selectAppointmentsError = (state) => ({
  error: state.appointments.error,
  errorMessage: state.appointments.errorMessage,
});

export default appointmentsSlice.reducer;
