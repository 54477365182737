import { createService, deleteService, getServices, updateService } from "../services/services-service";

import { createSlice } from "@reduxjs/toolkit";
import { logout } from "../services/user-service";

const INITIAL_STATE = {
  data: [],
  error: false,
  errorMessage: "",
};

const servicesSlice = createSlice({
  name: "services",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createService.fulfilled, (state, action) => {
      state.error = false;
      state.errorMessage = "";
      state.data.push(action.payload);
    });
    builder.addCase(createService.rejected, (state, action) => {
      state.error = true;
      state.errorMessage = action.error;
    });
    builder.addCase(getServices.fulfilled, (state, action) => {
      state.error = false;
      state.errorMessage = "";
      state.data = action.payload;
    });
    builder.addCase(getServices.rejected, (state, action) => {
      state.error = true;
      state.errorMessage = action.error;
      state.data = [];
    });
    builder.addCase(deleteService.fulfilled, (state, action) => {
      state.error = false;
      state.errorMessage = "";
      state.data = state.data.filter((service) => service.id !== action.payload);
    });
    builder.addCase(deleteService.rejected, (state, action) => {
      state.error = true;
      state.errorMessage = action.error;
    });
    builder.addCase(updateService.fulfilled, (state, action) => {
      const { id, ...data } = action.payload;
      const index = state.data.findIndex((service) => service.id === id);
      if (index !== -1) {
        state.data[index] = { ...state.data[index], ...data };
      }
    });
    builder.addCase(updateService.rejected, (state, action) => {
      state.error = true;
      state.errorMessage = action.error;
    });
    builder.addCase(logout.fulfilled, () => {
      return INITIAL_STATE;
    });
  },
});

// selectors
export const selectServices = (state) => state.services.data;

export const selectService = (id) => (state) => {
  const [service] = state.services.data.filter((service) => service.id === id);
  return service;
};

export const selectServicesError = (state) => ({
  error: state.services.error,
  errorMessage: state.services.errorMessage,
});

export default servicesSlice.reducer;
