export const genders = [
  {
    label: "Masculino",
    value: "male",
  },
  {
    label: "Femenino",
    value: "female",
  },
  {
    label: "No binario",
    value: "non-binary",
  },
  {
    label: "Otro",
    value: "other",
  },
];

export const serviceTypes = [
  {
    label: "A domicilio",
    value: "in-home",
  },
  {
    label: "Presencial",
    value: "in-person",
  },
  {
    label: "Remoto",
    value: "remote",
  },
];
export const daysOfWeek = [
  {
    label: "Lunes",
    value: "monday",
  },
  {
    label: "Martes",
    value: "tuesday",
  },
  {
    label: "Miércoles",
    value: "wednesday",
  },
  {
    label: "Jueves",
    value: "thursday",
  },
  {
    label: "Viernes",
    value: "friday",
  },
  {
    label: "Sábado",
    value: "saturday",
  },
  {
    label: "Domingo",
    value: "sunday",
  },
];

export const repeatDays = [
  {
    label: "De lunes a viernes",
    value: "mondayToFriday",
  },
  {
    label: "De lunes a sábados",
    value: "mondayToSaturday",
  },
  {
    label: "De lunes a domingos",
    value: "mondayToSunday",
  },
];

export const AppointmentState = {
  PENDING: "pending",
  APPROVED: "approved",
  REJECTED: "rejected",
  PAST: "past",
  CANCELED: "canceled",
};
