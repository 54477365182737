import { useCallback, useMemo, useState } from "react";

/**
 * Un hook para manejar la selección de rows en una tabla
 *
 * @returns {Array} Un array conteniendo los estilos condicionales y la función handleSelection.
 */
export const useRowSelection = () => {
  const [selectedRows, setSelectedRows] = useState({});
  const conditionalRowStyles = useMemo(() => {
    return [
      {
        when: (row) => selectedRows[row.id],
        style: {
          backgroundColor: "#f8f9fd",
        },
      },
    ];
  }, [selectedRows]);

  const handleSelection = useCallback(
    (id, selected) => {
      setSelectedRows((prevSelectedRows) => ({ ...prevSelectedRows, [id]: selected }));
    },
    [setSelectedRows]
  );

  return [conditionalRowStyles, handleSelection];
};
