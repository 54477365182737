import { array, boolean, date, number, ref, string } from "yup";

export const nameValidations = {
  firstName: string().required("Este campo es obligatorio"),
  lastName: string().required("Este campo es obligatorio"),
};

export const fullnameValidations = {
  fullname: string().required("Este campo es obligatorio"),
};

export const emailValidations = {
  email: string().email("Ingrese una dirección de correo electrónico válida").required("Este campo es obligatorio"),
};

export const phoneValidations = {
  phone: string()
    .matches(/^[0-9+-]+$/, "El formato ingresado no es válido")
    .required("Este campo es obligatorio"),
};

export const genderValidations = {
  gender: string().oneOf(["male", "female", "non-binary", "other"]),
};

export const createPasswordValidations = {
  password: string()
    .matches(/^(?=.*[A-Z])(?=.*\d).+$/, "La contraseña debe contener al menos una letra mayúscula y un número")
    .min(6, "La contraseña debe tener al menos 6 caracteres")
    .required("Este campo es obligatorio"),
  confirmPassword: string()
    .oneOf([ref("password"), null], "Las contraseñas no coinciden")
    .required("Este campo es obligatorio"),
};

export const passwordValidations = {
  password: string().required("Este campo es obligatorio"),
};

export const acceptTermsValidations = {
  acceptTerms: boolean().oneOf([true], "Debes aceptar los términos y condiciones"),
};

export const categoriesValidations = {
  categories: array()
    .min(1, "Debe ingresar al menos una categoría")
    .test(
      "not-empty",
      "Debe ingresar un nombre para la categoría.",
      (value) => !value.some((item) => item === undefined || item === "")
    )
    .transform((values) => values.filter((v) => v !== "")),
};

export const descriptionValidation = {
  description: string().max(63206, "La descripción excede el máximo de caracteres."),
};

export const addressValidation = {
  commune: string().required("Este campo es obligatorio"),
  address: string().required("Este campo es obligatorio"),
  postalCode: string().matches(/^[0-9]+$/, "Solo números"),
  other: string(),
};

export const serviceValidation = {
  name: string().required("Este campo es obligatorio"),
  price: number().typeError("Inrese un valor en números").required("Este campo es obligatorio"),
  description: string(),
  durationMinutes: number()
    .typeError("Inrese un valor en números")
    .test("not-empty", "Debe ingresar la duración.", (value) => !!value),
  photos: array(),
  type: array()
    .of(string().oneOf(["in-home", "in-person", "remote"]))
    .required("Debe seleccionar el tipo de servicio"),
  status: string().oneOf(["active", "inactive"]),
};

export const dateValidation = {
  date: date().typeError("La fecha es obligatoria").required("La fecha es obligatoria"),
};

export const timeValidation = {
  time: date().typeError("La hora es obligatoria").required("La hora es obligatoria"),
};

export const timeoffValidation = {
  date: date().typeError("La fecha es obligatoria").required("La fecha es obligatoria"),
  startDate: date().typeError("La hora de inicio es obligatoria").required("Este campo es obligatorio"),
  endDate: date()
    .typeError("La hora de inicio es obligatoria")
    .required("Este campo es obligatorio")
    .min(ref("startDate"), "La hora de fin debe ser mayor que la hora de inicio"),
};

export const serviceListValidation = {
  services: array()
    .min(1, "Debe seleccionar al menos un servicio")
    .test(
      "not-empty",
      "Debe seleccionar un servicio.",
      (value) => !value.some((item) => item === undefined || item === "")
    )
    .transform((values) => values.filter((v) => v !== "")),
};

export const availabilityValidation = {
  availabilityDay: string().required("Debe seleccionar una opción"),
  availabilityStart: date().typeError("La hora de inicio es obligatoria").required("Este campo es obligatorio"),
  availabilityEnd: date()
    .typeError("La hora de inicio es obligatoria")
    .required("Este campo es obligatorio")
    .min(ref("availabilityStart"), "La hora de fin debe ser mayor que la hora de inicio"),
};

export const unavailableValidation = {
  unavailableDay: string().required("Debe seleccionar una opción"),
  unavailableStart: date().typeError("La hora de inicio es obligatoria").required("Este campo es obligatorio"),
  unavailableEnd: date()
    .typeError("La hora de inicio es obligatoria")
    .required("Este campo es obligatorio")
    .min(ref("unavailableStart"), "La hora de fin debe ser mayor que la hora de inicio"),
};

export const serviceTypeValidation = {
  serviceType: string().oneOf(["in-home", "in-person", "remote"]).required("Debe seleccionar el tipo de servicio"),
};
