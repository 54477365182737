import {
  loginWithEmailAndPassword,
  logout,
  signupWithEmailAndPassword,
  updateProfileImage,
  updateUser,
} from "../services/user-service";

import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  data: null,
  error: false,
  errorMessage: "",
};

const userSlice = createSlice({
  name: "user",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signupWithEmailAndPassword.fulfilled, (state, action) => {
      state.error = false;
      state.errorMessage = "";
      state.data = action.payload;
    });
    builder.addCase(signupWithEmailAndPassword.rejected, (state, action) => {
      state.error = true;
      state.errorMessage = action.error;
    });
    builder.addCase(loginWithEmailAndPassword.fulfilled, (state, action) => {
      state.error = false;
      state.errorMessage = "";
      state.data = action.payload;
    });
    builder.addCase(loginWithEmailAndPassword.rejected, (state, action) => {
      state.error = true;
      state.errorMessage = action.error;
    });
    builder.addCase(updateProfileImage.fulfilled, (state, action) => {
      state.data = { ...state.data, profileImage: action.payload };
    });
    builder.addCase(updateProfileImage.rejected, (state, action) => {
      state.error = true;
      state.errorMessage = action.error;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.data = { ...state.data, ...action.payload };
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.error = true;
      state.errorMessage = action.error;
    });
    builder.addCase(logout.fulfilled, () => {
      return INITIAL_STATE;
    });
    builder.addCase(logout.rejected, (state, action) => {
      state.error = true;
      state.errorMessage = action.error;
    });
  },
});

// selectors
export const selectUser = (state) => state.user.data;
export const selectUserError = (state) => ({
  error: state.user.error,
  errorMessage: state.user.errorMessage,
});

export default userSlice.reducer;
