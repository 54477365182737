import { CardBody, CardTitle, Icon } from "../../components";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ClientsGrid } from "../../grids/clients-grid/clients-grid";
import { getClients } from "../../services/clients-service";
import { routes } from "../../navigation/routes";
import { selectClients } from "../../slices/clients-slice";
import { useEffect } from "react";

export const ClientsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clients = useSelector(selectClients);

  // refresco clientes en esta página
  useEffect(() => {
    dispatch(getClients());
  }, []);

  // on row click
  const onRowClicked = ({ id }) => {
    navigate(routes.editClient(id));
  };

  return (
    <div>
      <CardTitle>
        <Icon name="fi-rr-address-book" className="card-icon" size={20} />
        Mis Clientes
      </CardTitle>
      <CardBody $borderBottom $roundedBottom>
        <ClientsGrid data={clients} onRowClicked={onRowClicked} noBorderLastRow />
      </CardBody>
      <Outlet />
    </div>
  );
};
