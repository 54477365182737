import { useEffect } from "react";

/**
 * Outside click listener.
 * It checks for a flag or an active class in case you are using a transition.
 */
const useOutsideClick = (ref, withClassOrFlag, callback) => {
  const handleClick = (event) => {
    let isVisible = false;
    if (typeof withClassOrFlag === "string") {
      isVisible = ref.current?.className.includes(withClassOrFlag);
    } else {
      isVisible = !!withClassOrFlag;
    }
    if (isVisible && ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [ref, callback]);
};

export default useOutsideClick;
