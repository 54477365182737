import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  .__react_modal_image__header {
    background: transparent !important;
  }
  .__react_modal_image__caption {
    display:none !important;
  }
  .__react_modal_image__modal_content svg {
    display: none;
  }
`;
