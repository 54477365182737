export function getLabelStyles(name) {
  const pastelColors = [
    ["#F44336", "#FFCDD2"],
    ["#E91E63", "#F8BBD0"],
    ["#9C27B0", "#E1BEE7"],
    ["#673AB7", "#D1C4E9"],
    ["#3F51B5", "#C5CAE9"],
    ["#2196F3", "#BBDEFB"],
    ["#00BCD4", "#B2EBF2"],
    ["#4CAF50", "#C8E6C9"],
  ];

  const index = (name || "").split("").reduce((sum, char) => sum + char.charCodeAt(), 0) % pastelColors.length;

  const [$color, $backgroundColor] = pastelColors[index];
  return {
    $color,
    $backgroundColor,
  };
}
