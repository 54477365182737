import { FormButton, Icon } from "../../components";
import {
  PaymentMethodBox,
  PlanBody,
  PlanContainer,
  PlanTitle,
  SkipButton,
  SubscriptionBox,
  SubscriptionContainer,
  SubscriptionField,
  SubscriptionHistory,
  SubscriptionInfo,
  SubscriptionRow,
  SubscriptionText,
  SubscriptionTitle,
} from "../../components/subscription/styles";
import { format, parseISO } from "date-fns";
import {
  getPlanDetail,
  getSubscriptionTimeleft,
  getTransactions,
  startFreeTrial,
} from "../../services/subscription-service";
import { selectSubscription, selectTransactions } from "../../slices/subscription-slice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";

import { Plan } from "../../components/subscription";
import { TransactionsGrid } from "../../grids/transactions-grid/transactions-grid";
import { routes } from "../../navigation/routes";
import { selectUser } from "../../slices/user-slice";
import { updateUser } from "../../services/user-service";
import { useNavigate } from "react-router-dom";

export const SubscriptionTab = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const subscription = useSelector(selectSubscription);
  const transactions = useSelector(selectTransactions);

  const subscriptionFields = useMemo(() => {
    if (!subscription) {
      return {};
    }
    const planDetail = getPlanDetail(subscription.plan);

    return {
      card: `${subscription.cardType} terminada en ${subscription.cardLastDigits?.replaceAll("X", "")}`,
      planName: planDetail.planName,
      planAmount: planDetail.planAmount,
      planFrecuency: planDetail.planFrecuency,
      expirationDate: format(parseISO(subscription.expirationDate), "dd/MM/yyyy"),
    };
  }, [subscription]);

  useEffect(() => {
    dispatch(getTransactions());
  }, []);

  const onStartFreeTrialClick = (e) => {
    e.preventDefault();
    dispatch(startFreeTrial());
  };

  const onAdminPlanClick = () => {
    const adminPlanLink =
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_PAYKU_ADMIN_LINK
        : process.env.REACT_APP_PAYKU_ADMIN_DEV_LINK;

    var link = document.createElement("a");
    link.href = adminPlanLink;
    link.target = "_blank";
    link.click();
  };

  const onMonthlySubscriptionClick = () => {
    const monthlySubscriptionLink =
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_MONTHLY_SUBSCRIPTION_PROD_LINK
        : process.env.REACT_APP_MONTHLY_SUBSCRIPTION_DEV_LINK;

    const params = new URLSearchParams();
    params.append("nombre", user.firstName);
    params.append("apellido", user.lastName);
    params.append("email", user.email);
    params.append("telefono", user.phone);
    params.append("direct_full", "true");

    var link = document.createElement("a");
    link.href = `${monthlySubscriptionLink}&${params.toString()}`;
    link.target = "_blank";
    link.click();
  };

  const onAnnualSubscriptionClick = () => {
    const annualSubscriptionLink =
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_ANNUAL_SUBSCRIPTION_PROD_LINK
        : process.env.REACT_APP_ANNUAL_SUBSCRIPTION_DEV_LINK;

    const params = new URLSearchParams();
    params.append("nombre", user.firstName);
    params.append("apellido", user.lastName);
    params.append("email", user.email);
    params.append("telefono", user.phone);
    params.append("direct_full", "true");

    var link = document.createElement("a");
    link.href = `${annualSubscriptionLink}&${params.toString()}`;
    link.target = "_blank";
    link.click();
  };

  const onSubmit = async (data, formik) => {
    const result = await dispatch(
      updateUser({
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        gender: data.gender,
      })
    );

    if (result.type === "user/update/rejected") {
      formik.setErrors({
        generic: result.error.message,
      });
    }
    if (result.type === "user/update/fulfilled") {
      navigate(routes.setupProfile());
    }
  };

  // no subscription
  if (!subscription) {
    return (
      <SubscriptionContainer>
        <PlanContainer>
          <Plan
            continueText={"Empezar prueba"}
            title="Pago mensual"
            unit="mes"
            price={0}
            onClick={onStartFreeTrialClick}
          >
            Primer mes de prueba gratis
            <br />
            Cancela cuando quieras
            <br />
            Acceso a toda la plataforma
          </Plan>
          <Plan continueText={"Empezar prueba"} title="Pago anual" unit="año" price={0} onClick={onStartFreeTrialClick}>
            Primer mes de prueba gratis
            <br />
            Obtén 2 meses gratis
            <br />
            Acceso a toda la plataforma
          </Plan>
        </PlanContainer>
        <SkipButton onClick={onStartFreeTrialClick}>Omitir por ahora, y empezar prueba gratuita</SkipButton>
      </SubscriptionContainer>
    );
  }

  // trial
  if (subscription.status === "trial" && subscription.expirationDate) {
    return (
      <SubscriptionContainer>
        <div style={{ margin: "0 20px" }}>
          <PlanTitle>Prueba gratuita</PlanTitle>
          <PlanBody>Estás utilizando tu mes de prueba en Groopa. {getSubscriptionTimeleft(subscription)}</PlanBody>
        </div>
      </SubscriptionContainer>
    );
  }

  // expired
  if (subscription.status === "trial-expired" || subscription.status === "expired") {
    return (
      <SubscriptionContainer>
        <SubscriptionTitle>
          {subscription.status === "trial-expired" &&
            "Tu suscripción de prueba expiró. Debes elegír un plan para seguir utilizando Groopa."}
          {subscription.status === "expired" &&
            "Tu suscripción expiró. Debes elegír un plan para seguir utilizando Groopa."}
        </SubscriptionTitle>
        <PlanContainer>
          <Plan
            continueText={"Continuar"}
            title="Pago mensual"
            unit="mes"
            price={getPlanDetail("monthly").planAmount}
            onClick={onMonthlySubscriptionClick}
          >
            Primer mes de prueba gratis
            <br />
            Cancela cuando quieras
            <br />
            Acceso a toda la plataforma
          </Plan>
          <Plan
            continueText={"Continuar"}
            unit="año"
            title="Pago anual"
            price={getPlanDetail("annual").planAmount}
            onClick={onAnnualSubscriptionClick}
          >
            Primer mes de prueba gratis
            <br />
            Obtén 2 meses gratis
            <br />
            Acceso a toda la plataforma
          </Plan>
        </PlanContainer>
      </SubscriptionContainer>
    );
  }
  // active
  if (subscription.status === "active") {
    return (
      <SubscriptionInfo>
        <SubscriptionBox>
          <SubscriptionTitle>Suscripción</SubscriptionTitle>
          <SubscriptionRow>
            <SubscriptionTitle>{subscriptionFields.planName}</SubscriptionTitle>
            <SubscriptionText>
              <span>${subscriptionFields.planAmount}</span>
              {" / "}
              {subscriptionFields.planFrecuency}
            </SubscriptionText>
          </SubscriptionRow>
          <SubscriptionRow>
            <SubscriptionText>Próxima fecha de facturación: {subscriptionFields.expirationDate}</SubscriptionText>
            <FormButton onClick={onAdminPlanClick} style={{ maxWidth: 200 }}>
              Administrar plan
            </FormButton>
          </SubscriptionRow>
        </SubscriptionBox>
        <PaymentMethodBox>
          <SubscriptionTitle>Metodo de pago</SubscriptionTitle>
          <SubscriptionText>
            <b>{subscriptionFields.card}</b>
          </SubscriptionText>
          <SubscriptionField>
            <Icon size={11} name="fi-rr-envelope" />
            <SubscriptionText>{user.email}</SubscriptionText>
          </SubscriptionField>
        </PaymentMethodBox>
        <SubscriptionHistory>
          <SubscriptionTitle>Historial de facturación</SubscriptionTitle>
        </SubscriptionHistory>
        <TransactionsGrid data={transactions} />
      </SubscriptionInfo>
    );
  }
};

/*
register
active
finish
delete
cancel
suspended
 */
