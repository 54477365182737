import { CheckboxItem, Content, ItemIndicator } from "@radix-ui/react-dropdown-menu";

import styled from "styled-components";

export const DropdownMenuContent = styled(Content)`
  background-color: white;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.STROKE};
  padding: 5px;
  z-index: 101;
`;

export const DropdownMenuItemIndicator = styled(ItemIndicator)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const DropdownMenuItemContainer = styled.div`
  position: absolute;
  left: 4px;
  width: 18px;
  height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.colors.STROKE};
`;

export const DropdownMenuCheckboxItem = styled(CheckboxItem)`
  font-size: 13px;
  line-height: 1;
  color: ${(props) => props.theme.colors.TEXT_DARK};
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 35px 0 30px;
  position: relative;
  user-select: none;

  &[data-disabled] {
    color: ${(props) => props.theme.colors.STROKE};
    pointer-events: none;
  }
  &[data-highlighted] {
    outline: none;
    background-color: #fafafa;
    color: ${(props) => props.theme.colors.TEXT_DARK};
  }
`;

export const MultiSelectInput = styled.input`
  box-shadow: none;
  box-sizing: border-box;
  background-color: transparent;
  border: none;
  padding: 0;
  width: 100%;
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  &::placeholder {
    color: #747574;
  }
  &:focus-within {
    outline: none;
  }
`;

export const MultiSelectTrigger = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  padding: 0 15px;
  height: 35px;
  gap: 5px;
  background-color: white;
  color: ${(props) => props.theme.colors.TEXT_DARK};
  border: 1px solid ${(props) => props.theme.colors.STROKE};

  &:hover {
    background-color: ${(props) => props.theme.colors.STROKE};
  }
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.PRIMARY};
  }
  &:focus-within {
    outline: auto;
    outline: -webkit-focus-ring-color auto 1px;
  }
`;
