import { Indicator, Root } from "@radix-ui/react-checkbox";

import styled from "styled-components";

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxRoot = styled(Root)`
  box-shadow: none;
  background-color: white;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.colors.STROKE};

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.SECONDARY};
  }

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.SECONDARY};
  }
  [data-state="checked"] {
    background-color: ${(props) => props.theme.colors.SECONDARY};
    border: 1px solid ${(props) => props.theme.colors.SECONDARY};
    border-radius: 3px;
  }
`;

export const CheckboxLabel = styled.label`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-weight: 400;
  font-size: 12px;
  margin-left: 12px;
`;

export const CheckboxIndicator = Indicator;
