import queryString from "query-string";
import { v4 as uuidv4 } from "uuid";

/**
 * Genera un nombre de archivo único con la extensión original utilizando un UUID v4.
 *
 * @param {Object} file - Un objeto que contiene información sobre el archivo, incluyendo su nombre y tipo.
 * @returns {string} - El nombre de archivo único generado con el UUID y la extensión original.
 */
export function getUniqueFilename(file) {
  const extension = file.name.split(".").pop();
  const uid = uuidv4();
  return `${uid}.${extension}`;
}

/**
 * Reemplaza el nombre de archivo manteniendo la la extensión original.
 *
 * @param {Object} file - Un objeto que contiene información sobre el archivo, incluyendo su nombre y tipo.
 * @returns {string} - El nombre de archivo reemplazado
 */
export function replaceFilename(file, name) {
  const extension = file.name.split(".").pop();
  return `${name}.${extension}`;
}

/**
 * Formatea un tamaño dado en bytes, kilobytes o megabytes según su peso.
 * @param {number} sizeBytes - El tamaño en bytes a formatear.
 * @returns {string} - El tamaño formateado en bytes, kilobytes o megabytes.
 */
export function formatSize(sizeBytes) {
  if (typeof sizeBytes !== "number") {
    return "";
  }
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;
  if (sizeBytes < kilobyte) {
    return sizeBytes + " bytes";
  } else if (sizeBytes < megabyte) {
    return (sizeBytes / kilobyte).toFixed(2) + " KB";
  } else {
    return (sizeBytes / megabyte).toFixed(2) + " MB";
  }
}

/**
 * Obtiene el nombre de archivo de una URL producida por getDownloadLink()
 * @param {*} storeURL URL del recurso en firebase storage
 * @returns
 */
export function getStorageFilename(storeURL) {
  const decoded = decodeURIComponent(storeURL);
  const { url } = queryString.parseUrl(decoded);
  return url.split("/").pop();
}

/**
 * Obtiene el nombre de archivo de un Objeto File o una URL.
 * @param {*} file file object o url
 * @returns
 */
export function getFilename(file) {
  if (typeof file === "string") {
    return getStorageFilename(file);
  }
  if (file instanceof window.File) {
    return file.name;
  }
  return "";
}
