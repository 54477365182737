import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { database, functions } from "../firebase";
import { differenceInDays, parseISO } from "date-fns";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { httpsCallable } from "firebase/functions";

export const getUserSubscription = createAsyncThunk("subscription/get", async (uid, { getState }) => {
  const docRef = doc(database, "subscriptions", uid);
  const subscriptionSnapshot = await getDoc(docRef);

  return subscriptionSnapshot.exists ? subscriptionSnapshot.data() : null;
});

export const startFreeTrial = createAsyncThunk("subscription/start-free-trial", async () => {
  const startFreeTrialFunction = httpsCallable(functions, "startFreeTrial");
  const result = await startFreeTrialFunction();

  return result.data;
});

export const getTransactions = createAsyncThunk("transactions/get", async (_, { getState }) => {
  const state = getState();
  const userEmail = state.user.data.email;

  const transactionsRef = collection(database, "transactions");
  const transactionsQuery = query(transactionsRef, where("email", "==", userEmail));

  const result = await getDocs(transactionsQuery);
  return result.docs.map((doc) => doc.data());
});

// Helpers
export function getSubscriptionTimeleft(subscription) {
  if (!subscription) {
    return null;
  }
  const timeleft = differenceInDays(parseISO(subscription.expirationDate), parseISO(subscription.createdAt));
  if (subscription.status === "trial") {
    return `Te quedan ${timeleft} días de prueba. `;
  }
  return `Quedan ${timeleft} días para la próxima fecha de cobro. `;
}

export function isActiveOrTrial(subscription) {
  if (!subscription) {
    return false;
  }

  return subscription.status === "active" || subscription.status === "trial";
}

export function getSubscriptionPlan(subscription) {
  if (!subscription) {
    return null;
  }
  const timeleft = differenceInDays(parseISO(subscription.expirationDate), parseISO(subscription.createdAt));
  if (subscription.status === "trial") {
    return `Te quedan ${timeleft} días de prueba. `;
  }
  return `Quedan ${timeleft} días para la próxima fecha de cobro. `;
}

export function getPlanDetail(planType) {
  if (!planType) {
    return {
      planName: "Periodo de prueba",
      planAmount: 0,
      planFrecuency: "",
    };
  }
  if (planType === "monthly") {
    return {
      planName: "Plan Mensual",
      planAmount: Number(process.env.REACT_APP_MONTHLY_SUBSCRIPTION_AMOUNT).toLocaleString("es-CL"),
      planFrecuency: "mes",
    };
  } else if (planType === "annual") {
    return {
      planName: "Plan Anual",
      planAmount: Number(process.env.REACT_APP_ANNUAL_SUBSCRIPTION_AMOUNT).toLocaleString("es-CL"),
      planFrecuency: "año",
    };
  }
}
