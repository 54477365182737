import { ignoredActions, persistConfig } from "./persist-config";
import { persistReducer, persistStore } from "redux-persist";

import appointmentsReducer from "../slices/appointments-slice";
import clientsReducer from "../slices/clients-slice";
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import createLoader from "../middlewares/loader";
import loaderReducer from "../slices/loader-slice";
import notificationsReducer from "../slices/notifications-slice";
import schedulingReducer from "../slices/scheduling-slice";
import servicesReducer from "../slices/services-slice";
import subscriptionReducer from "../slices/subscription-slice";
import timeoffReducer from "../slices/timeoff-slice";
import userReducer from "../slices/user-slice";

// middlewares
const middlewares = [createLoader()];

// reducers
const rootReducer = combineReducers({
  subscription: subscriptionReducer,
  notifications: notificationsReducer,
  appointments: appointmentsReducer,
  scheduling: schedulingReducer,
  services: servicesReducer,
  timeoff: timeoffReducer,
  clients: clientsReducer,
  loader: loaderReducer,
  user: userReducer,
});

export const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions,
      },
    }).concat(middlewares),
});

export const persistor = persistStore(store);
