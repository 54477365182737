import React, { useEffect } from "react";
import {
  approveAppointment,
  createAppointment,
  getAppointments,
  rejectAppointment,
  updateAppointment,
} from "../../services/appointments-service";
import {
  createService,
  deleteService,
  getServices,
  updateService,
  uploadServicePhotos,
} from "../../services/services-service";
import { createTimeoff, deleteTimeoff } from "../../services/timeoff-service";
import { deleteClient, getClients, updateClient } from "../../services/clients-service";
import {
  getPublicAvailability,
  getPublicProfile,
  getPublicServices,
  publicCreateAppointment,
} from "../../services/scheduling-service";
import {
  loginWithEmailAndPassword,
  logout,
  sendPasswordRecoverEmail,
  signupWithEmailAndPassword,
  updatePassword,
  updatePortfolio,
  updateProfileImage,
  updateUser,
} from "../../services/user-service";
import { register, selectLoader } from "../../slices/loader-slice";
import { useDispatch, useSelector } from "react-redux";

import { LoaderDialog } from "../../components/loader";
import { startFreeTrial } from "../../services/subscription-service";

const LoaderScreen = () => {
  const dispatch = useDispatch();
  const loaderState = useSelector(selectLoader);
  const isLoading = loaderState.loaders["app-loader"];
  const message = process.env.NODE_ENV !== "production" ? loaderState.callers["app-loader"] : "Cargando";

  useEffect(() => {
    dispatch(
      register({
        id: "app-loader",
        startActions: [
          logout.pending.type,
          updateUser.pending.type,
          updateProfileImage.pending.type,
          updatePassword.pending.type,
          loginWithEmailAndPassword.pending.type,
          signupWithEmailAndPassword.pending.type,
          sendPasswordRecoverEmail.pending.type,
          updatePortfolio.pending.type,
          uploadServicePhotos.pending.type,
          createService.pending.type,
          getServices.pending.type,
          deleteService.pending.type,
          updateService.pending.type,
          createAppointment.pending.type,
          createTimeoff.pending.type,
          getPublicAvailability.pending.type,
          publicCreateAppointment.pending.type,
          deleteClient.pending.type,
          updateClient.pending.type,
          getAppointments.pending.type,
          approveAppointment.pending.type,
          rejectAppointment.pending.type,
          updateAppointment.pending.type,
          getClients.pending.type,
          startFreeTrial.pending.type,
          getPublicProfile.pending.type,
          getPublicServices.pending.type,
          deleteTimeoff.pending.type,
        ],
        stopActions: [
          logout.fulfilled.type,
          logout.rejected.type,
          updateUser.fulfilled.type,
          updateUser.rejected.type,
          updateProfileImage.fulfilled.type,
          updateProfileImage.rejected.type,
          updatePassword.fulfilled.type,
          updatePassword.rejected.type,
          loginWithEmailAndPassword.fulfilled.type,
          loginWithEmailAndPassword.rejected.type,
          signupWithEmailAndPassword.fulfilled.type,
          signupWithEmailAndPassword.rejected.type,
          sendPasswordRecoverEmail.fulfilled.type,
          sendPasswordRecoverEmail.rejected.type,
          updatePortfolio.fulfilled.type,
          updatePortfolio.rejected.type,
          uploadServicePhotos.fulfilled.type,
          uploadServicePhotos.rejected.type,
          createService.fulfilled.type,
          createService.rejected.type,
          getServices.fulfilled.type,
          getServices.rejected.type,
          deleteService.fulfilled.type,
          deleteService.rejected.type,
          updateService.fulfilled.type,
          updateService.rejected.type,
          createAppointment.fulfilled.type,
          createAppointment.rejected.type,
          createTimeoff.fulfilled.type,
          createTimeoff.rejected.type,
          getPublicAvailability.fulfilled.type,
          getPublicAvailability.rejected.type,
          publicCreateAppointment.fulfilled.type,
          publicCreateAppointment.rejected.type,
          deleteClient.fulfilled.type,
          deleteClient.rejected.type,
          updateClient.fulfilled.type,
          updateClient.rejected.type,
          getAppointments.fulfilled.type,
          getAppointments.rejected.type,
          approveAppointment.fulfilled.type,
          approveAppointment.rejected.type,
          rejectAppointment.fulfilled.type,
          rejectAppointment.rejected.type,
          updateAppointment.fulfilled.type,
          updateAppointment.rejected.type,
          getClients.fulfilled.type,
          getClients.rejected.type,
          startFreeTrial.fulfilled.type,
          startFreeTrial.rejected.type,
          getPublicProfile.fulfilled.type,
          getPublicProfile.rejected.type,
          getPublicServices.fulfilled.type,
          getPublicServices.rejected.type,
          deleteTimeoff.fulfilled.type,
          deleteTimeoff.rejected.type,
        ],
      })
    );
  }, []);

  return isLoading ? <LoaderDialog message={message} /> : null;
};

export { LoaderScreen };
