import { InputStyles } from "../input/styles";
import styled from "styled-components";

export const DatePickerInput = styled.input`
  ${InputStyles}
`;

export const DatePickerContainer = styled.div`
  .react-datepicker__current-month,
  .react-datepicker__day-name,
  .react-datepicker__day {
    font-family: ${(props) => props.theme.fonts.NOTO_SANS};
    color: ${(props) => props.theme.colors.TEXT_DARK} !important;
    padding: 3px;
    padding: 0.5vw;
  }
  .react-datepicker__current-month {
    padding: 0px;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--highlighted {
    background-color: ${(props) => props.theme.colors.SECONDARY} !important;
    font-family: ${(props) => props.theme.fonts.NOTO_SANS};
    color: ${(props) => props.theme.colors.WHITE} !important;
  }
  .react-datepicker__day--disabled {
    color: ${(props) => props.theme.colors.DISABLED} !important;
  }
  .react-datepicker__header--time {
    display: none;
  }
  .react-datepicker__header {
    background-color: ${(props) => props.theme.colors.WHITE};
    border-bottom: 0px;
  }
  .react-datepicker__time {
    border-radius: 4px;
  }
  .react-datepicker {
    ${(props) => (props.$borderless ? `border: none;` : ` border: 1px solid ${(props) => props.theme.colors.STROKE};`)}
    ${(props) =>
      props.$borderless ? `box-shadow: none;` : `box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important`}
  }
  .react-datepicker__time-list-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${(props) => props.theme.fonts.NOTO_SANS};
    color: ${(props) => props.theme.colors.TEXT_DARK};
  }
  .react-datepicker__time-list-item--selected {
    background-color: ${(props) => props.theme.colors.SECONDARY} !important;
  }
  .react-datepicker__triangle::before {
    border-top-color: ${(props) => props.theme.colors.STROKE}!important;
  }
`;
