import { CardBody, Icon } from "../../components";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { TabsList, TabsRoot, TabsTrigger } from "../../components/tabs/styles";
import { isAfter, isBefore, parseISO, subMonths } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";

import { AppointmentState } from "../../helpers/consts";
import { HistoryGrid } from "../../grids/history-grid/history-grid";
import { SelectLink } from "../../components/select-link";
import { getAppointments } from "../../services/appointments-service";
import { routes } from "../../navigation/routes";
import { selectAppointments } from "../../slices/appointments-slice";
import { useMediaQuery } from "../../hooks/use-media-query";

const MIN_DATE = subMonths(new Date(), 6);

const items = [
  {
    icon: "fi-rr-clock",
    label: "Pendientes",
    to: routes.history(AppointmentState.PENDING),
  },
  {
    icon: "fi-rr-check",
    label: "Aprobadas",
    to: routes.history(AppointmentState.APPROVED),
  },
  {
    icon: "fi-rr-ban",
    label: "Rechazadas",
    to: routes.history(AppointmentState.REJECTED),
  },
  {
    icon: "fi-rr-arrow-small-left",
    label: "Pasadas",
    to: routes.history(AppointmentState.PAST),
  },
  {
    icon: "fi-rr-cross-small",
    label: "Canceladas",
    to: routes.history(AppointmentState.CANCELED),
  },
];

export const HistoryScreen = () => {
  const appointments = useSelector(selectAppointments);
  const mobile = useMediaQuery("(max-width: 918px)");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const { type } = useParams();

  // refresco servicios en esta página
  useEffect(() => {
    dispatch(getAppointments(MIN_DATE));
  }, [type]);

  const filtered = useMemo(() => {
    if (type === "past") {
      return appointments.filter(
        (appointment) =>
          (appointment.state === "approved" || appointment.state === "pending") &&
          isBefore(parseISO(appointment.endDate), new Date())
      );
    }
    return appointments.filter(
      (appointment) => appointment.state === type && isAfter(parseISO(appointment.startDate), new Date())
    );
  }, [appointments, type]);

  // view appointment
  const onRowClicked = ({ id }) => {
    navigate(routes.historyView(type, id));
  };

  return (
    <>
      {mobile ? (
        <SelectLink value={getCurrentPath(pathname)} items={items} onValueChange={navigate} />
      ) : (
        <TabsRoot value={pathname} onValueChange={navigate}>
          <TabsList aria-label="Historial">
            {items.map((item) => (
              <TabsTrigger key={item.to} value={item.to}>
                <Icon name={item.icon} size={18} />
                {item.label}
              </TabsTrigger>
            ))}
          </TabsList>
        </TabsRoot>
      )}
      <CardBody $borderBottom $roundedBottom>
        <HistoryGrid type={type} data={filtered} onRowClicked={onRowClicked} noBorderLastRow />
      </CardBody>
      <Outlet />
    </>
  );
};

function getCurrentPath(path) {
  const trimmed = path.trim().replace(/^\/+|\/+$/g, "");
  const [history, type] = trimmed.split("/");
  return `/${history}/${type}`;
}
