import {
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItemContainer,
  DropdownMenuItemIndicator,
  MultiSelectInput,
  MultiSelectTrigger,
} from "./styles";
import { Portal, Root, Trigger } from "@radix-ui/react-dropdown-menu";

import { Field } from "formik";
import { Icon } from "../icon";
import React from "react";
import { getServiceTypeName } from "../../helpers/format";

// Radix-UI no tiene una primitiva para multi select, implementamos una usando dropdown
export const MultiSelect = (props) => {
  const values = props.value || [];
  const text = getServiceTypeName(values);

  const onChange = (item) => {
    if (!values.includes(item.value)) {
      props.onValueChange([...values, item.value]);
    } else {
      const nextValues = values.filter((value) => value !== item.value);
      props.onValueChange(nextValues);
    }
  };

  return (
    <Root>
      <Trigger asChild>
        <MultiSelectTrigger>
          <MultiSelectInput value={text} onChange={() => {}} type={"text"} placeholder={props.placeholder} />
          <Icon name="fi-rr-angle-small-down" />
        </MultiSelectTrigger>
      </Trigger>

      <Portal>
        <DropdownMenuContent sideOffset={5}>
          {props.items.map((item) => (
            <DropdownMenuCheckboxItem
              key={item.value}
              checked={values.includes(item.value)}
              onCheckedChange={() => onChange(item)}
            >
              <DropdownMenuItemContainer>
                <DropdownMenuItemIndicator>
                  <Icon size={18} name="fi-rr-check" />
                </DropdownMenuItemIndicator>
              </DropdownMenuItemContainer>
              {item.label}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuContent>
      </Portal>
    </Root>
  );
};

export const MultiSelectField = (props) => {
  const onValueChange = (formik) => (value) => {
    formik.form.setFieldValue(formik.field.name, value);
  };
  return (
    <Field
      name={props.name}
      component={(formik) => (
        <MultiSelect
          id={props.id}
          value={formik.field.value}
          onValueChange={onValueChange(formik)}
          items={props.items}
          placeholder={props.placeholder}
        />
      )}
    />
  );
};
