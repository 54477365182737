import {
  AppointmentDescription,
  AppointmentFormContainer,
  AppointmentInfo,
  AppointmentInfoField,
  AppointmentTitle,
  Response,
  ResponseContainer,
} from "./styles";
import { CardBody, CardClose, CardTitle, Icon, Modal } from "../../components";
import { addMinutes, format, isAfter, isBefore, parseISO } from "date-fns";
import { approveAppointment, rejectAppointment } from "../../services/appointments-service";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AppointmentState } from "../../helpers/consts";
import { Select } from "../../components/select/index";
import es from "date-fns/locale/es";
import { getServiceTypeName } from "../../helpers/format";
import { getServices } from "../../services/services-service";
import { selectAppointment } from "../../slices/appointments-slice";
import { selectServices } from "../../slices/services-slice";

const responseOptions = [
  {
    label: "Aprobar",
    value: AppointmentState.APPROVED,
  },
  {
    label: "Rechazar",
    value: AppointmentState.REJECTED,
  },
  {
    label: "Pendiente",
    value: AppointmentState.PENDING,
    disabled: true,
  },
];

export const ViewAppointmentModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const services = useSelector(selectServices);
  const appointment = useSelector(selectAppointment(params.id));
  const date = parseISO(appointment.startDate);
  const endDate = addMinutes(date, appointment.durationMinutes);
  const timeRange = `${format(date, "HH:mm", { locale: es })} - ${format(endDate, "HH:mm", { locale: es })}`;

  const servicesData = useMemo(() => {
    return services.filter((service) => appointment.services.some((id) => id === service.id));
  }, [services, appointment.services]);

  // get services if needed
  useEffect(() => {
    if (services.length === 0) {
      dispatch(getServices());
    }
  }, [services]);

  const onResponseClick = async (value) => {
    const option = responseOptions.find((a) => a.value === value);
    if (await window.confirm(`Se cambiará el estado de la cita a ${option.label}?`)) {
      if (value === AppointmentState.APPROVED) {
        await dispatch(approveAppointment(params.id));
      }
      if (value === AppointmentState.REJECTED) {
        await dispatch(rejectAppointment(params.id));
      }
    }
  };
  const onModalClose = () => {
    navigate(-1);
  };

  return (
    <Modal expand onClose={onModalClose}>
      <CardTitle>
        Información de la cita
        <CardClose onClick={onModalClose} />
      </CardTitle>
      <CardBody $borderBottom $roundedBottom>
        <AppointmentFormContainer>
          <AppointmentTitle>{`${appointment.firstName} ${appointment.lastName}`}</AppointmentTitle>
          <AppointmentInfo>
            <AppointmentInfoField>
              <Icon name="fi-rr-clock" size={14} />
              {timeRange}
            </AppointmentInfoField>
            <AppointmentInfoField>
              <Icon name="fi-rr-calendar" size={14} />
              {format(date, "EEEE dd MMMM", { locale: es })}
            </AppointmentInfoField>
            <AppointmentInfoField>
              <Icon name="fi-rr-building" size={14} />
              {getServiceTypeName([appointment.serviceType])}
            </AppointmentInfoField>
            <AppointmentInfoField>
              <Icon name="fi-rr-money" size={14} />
              {`$${appointment.price.toLocaleString("es-CL")}`}
            </AppointmentInfoField>
          </AppointmentInfo>
          {servicesData.map((service) => (
            <div>
              <AppointmentTitle>{service.name}</AppointmentTitle>
              <AppointmentDescription>{service.description}</AppointmentDescription>
            </div>
          ))}
          {/* Only allow to answer in future appointments */}
          {(appointment.state === AppointmentState.PENDING || appointment.state === AppointmentState.APPROVED) &&
            isAfter(parseISO(appointment.endDate), new Date()) && (
              <ResponseContainer>
                <AppointmentTitle>Respuesta</AppointmentTitle>
                <Response>
                  <Select value={appointment.state} items={responseOptions} onValueChange={onResponseClick} />
                </Response>
              </ResponseContainer>
            )}
        </AppointmentFormContainer>
      </CardBody>
    </Modal>
  );
};
