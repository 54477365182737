import { Link, LinksContainer, Logo, NavbarContainer, NavbarContent, SolidLink } from "./styles";
import { getPublicURL, routes } from "../../navigation/routes";

import { Icon } from "../icon";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/logo-black.png";

export const Navbar = () => {
  return (
    <NavbarContainer>
      <NavbarContent>
        <NavLink to={routes.root()}>
          <Logo src={logo} alt="Groopa" />
        </NavLink>
        <LinksContainer>
          <Link href={process.env.REACT_APP_LANDING_PAGE}>Inicio</Link>
          <Link href={process.env.REACT_APP_LANDING_PAGE}>Precio</Link>
          <Link href={process.env.REACT_APP_LANDING_PAGE}>FAQ</Link>
          <Link href={process.env.REACT_APP_LANDING_PAGE}>Contacto</Link>
        </LinksContainer>
        <LoginButton />
      </NavbarContent>
    </NavbarContainer>
  );
};

const LoginButton = () => {
  return (
    <SolidLink to={getPublicURL(routes.login())}>
      <Icon name="fi-rr-user" size={16} />
      Login / Registro
    </SolidLink>
  );
};
