import styled, { css } from "styled-components";

import { Field } from "formik";

// prettier-ignore
export const InputStyles =css`
  box-shadow: none;
  box-sizing: border-box;

  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  padding: 0 15px;
  height: 36px;
  line-height: 2em;
  border: 1px solid ${(props) => props.theme.colors.STROKE};

  &::selection {
    background-color: ${(props) => props.theme.colors.PRIMARY};
    color: ${(props) => props.theme.colors.TEXT_LIGHT};
  }
`;

export const Input = styled(Field).attrs((props) => ({
  component: props.component || "input",
}))`
  ${InputStyles}
  ${(props) =>
    props.component === "textarea" &&
    ` padding:15px;
      min-height: 86px;
      line-height: unset;
      resize: vertical;
  `}
`;
