import { EmptyContainer, EmptyMessage } from "./styles";

import { FormLink } from "../form/styles";
import { routes } from "../../navigation/routes";

export const EmptyComponent = (props) => {
  const entityName = props.entityName || "[entity]";

  if (props.readonly) {
    return (
      <EmptyContainer>
        <EmptyMessage fontWeight={400} style={{ textAlign: "center" }}>
          {`No tienes ningún ${entityName}. `}
        </EmptyMessage>
      </EmptyContainer>
    );
  }

  return (
    <EmptyContainer>
      <EmptyMessage fontWeight={400} style={{ textAlign: "center" }}>
        {`No tienes ningún ${entityName}. `}
        <FormLink $type="secondary" to={props.to}>
          Haz click aquí
        </FormLink>{" "}
        {` para agregar el primer ${entityName}. `}
      </EmptyMessage>
    </EmptyContainer>
  );
};
