import {
  TableContainer,
  TableRowEditButtonContainer,
  TableRowMenuButtonContainer,
  TableRowMenuLinkContainer,
  TablebMenu,
} from "./styles";
import { useRef, useState } from "react";

import { CSSTransition } from "react-transition-group";
import { Icon } from "../icon";

// menú in-row
export const TableRowMenuRoot = (props) => {
  const nodeRef = useRef(null);
  const [open, setOpen] = useState(false);
  const onMenuClick = () => {
    props.onSelectionChange(props.row.id, !open);
    setOpen(!open);
  };

  return (
    <TableContainer>
      <CSSTransition nodeRef={nodeRef} in={open} timeout={500} classNames="slide" unmountOnExit>
        <TablebMenu onClick={onMenuClick} ref={nodeRef}>
          {props.children}
        </TablebMenu>
      </CSSTransition>
      <TableRowEditButtonContainer $open={open} onClick={onMenuClick}>
        <Icon name={"fi-rr-menu-dots-vertical"} size={20} />
      </TableRowEditButtonContainer>
    </TableContainer>
  );
};

// botones de edición
export const TableRowMenuButton = (props) => (
  <TableRowMenuButtonContainer onClick={props.onClick} className={props.className}>
    {props.component ? <props.component /> : <Icon name={props.name} size={20} />}
  </TableRowMenuButtonContainer>
);

// links de edición
export const TableRowMenuLink = (props) => (
  <TableRowMenuLinkContainer to={props.to} onClick={props.onClick} className={props.className}>
    {props.component ? <props.component /> : <Icon name={props.name} size={20} />}
  </TableRowMenuLinkContainer>
);
