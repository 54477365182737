import { updatePortfolio, updateUser } from "../../services/user-service";
import { useDispatch, useSelector } from "react-redux";

import { ProfileForm } from "../../forms/profile-form/profile-form";
import { routes } from "../../navigation/routes";
import { selectUser } from "../../slices/user-slice";
import { useNavigate } from "react-router-dom";

export const ProfileTab = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const onSubmit = async (data, formik) => {
    // sube las imagenes para poder incluirlas en el perfil del usuario
    const portfolioResult = await dispatch(updatePortfolio(data.portfolio));

    if (portfolioResult.type === "user/portfolio-update/rejected") {
      formik.setErrors({
        generic: "Error actualizando la galería de imagenes",
      });
      return;
    }
    // actualiza perfil del usuario
    const result = await dispatch(
      updateUser({
        portfolio: portfolioResult.payload,
        categories: data.categories,
        commune: data.commune,
        address: data.address,
        postalCode: data.postalCode,
        description: data.description,
        other: data.other,
      })
    );

    if (result.type === "user/update/rejected") {
      formik.setErrors({
        generic: result.error.message,
      });
    }
    if (result.type === "user/update/fulfilled") {
      navigate(routes.userProfile());
    }
  };

  return <ProfileForm data={user} onSubmit={onSubmit} submitText={"Guardar"} />;
};
