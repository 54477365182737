import { getTransactions, getUserSubscription, startFreeTrial } from "../services/subscription-service";

import { createSlice } from "@reduxjs/toolkit";
import { logout } from "../services/user-service";

const INITIAL_STATE = {
  data: null,
  transactions: [],
  error: false,
  errorMessage: "",
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(startFreeTrial.fulfilled, (state, action) => {
      state.error = false;
      state.errorMessage = "";
      state.data = action.payload;
    });
    builder.addCase(getUserSubscription.fulfilled, (state, action) => {
      state.error = false;
      state.errorMessage = "";
      state.data = action.payload;
    });
    builder.addCase(getUserSubscription.rejected, (state, action) => {
      state.error = true;
      state.errorMessage = action.error;
    });
    builder.addCase(getTransactions.fulfilled, (state, action) => {
      state.error = false;
      state.errorMessage = "";
      state.transactions = action.payload;
    });
    builder.addCase(getTransactions.rejected, (state, action) => {
      state.error = true;
      state.errorMessage = action.error;
    });
    builder.addCase(logout.fulfilled, () => {
      return INITIAL_STATE;
    });
  },
});

// selectors
export const selectSubscription = (state) => state.subscription.data;
export const selectTransactions = (state) => state.subscription.transactions;

export default subscriptionSlice.reducer;
