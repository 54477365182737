import { SortImage, StyledDataTable, TableRowMenuLink, TableRowMenuRoot } from "../../components";

import { EmptyComponent } from "../../components/table/empty-table";
import { routes } from "../../navigation/routes";
import { useRowSelection } from "../../hooks/use-table";

export const ClientsGrid = (props) => {
  const [conditionalRowStyles, handleSelection] = useRowSelection();
  const columns = getClientsColumns(handleSelection);

  return (
    <StyledDataTable
      sortIcon={<SortImage />}
      columns={columns}
      data={props.data}
      defaultSortFieldId={"name"}
      defaultSortAsc={true}
      persistTableHead={true}
      onRowClicked={props.onRowClicked}
      noBorderLastRow={props.noBorderLastRow}
      noDataComponent={<EmptyComponent to={"#"} readonly entityName="cliente" />}
      conditionalRowStyles={conditionalRowStyles}
    />
  );
};

const TableRowMenu = (props) => (
  <TableRowMenuRoot row={props.row} onSelectionChange={props.onSelectionChange}>
    <TableRowMenuLink to={routes.appendToPath(`edit/${props.row.id}`)} name="fi-rr-edit" />
    <TableRowMenuLink to={routes.appendToPath(`appointment/${props.row.id}`)} name="fi-rr-calendar" />
    <TableRowMenuLink to={routes.appendToPath(`delete/${props.row.id}`)} name="fi-rr-trash" />
  </TableRowMenuRoot>
);

const getClientsColumns = (onSelectionChange) => {
  return [
    {
      id: "name",
      name: "Nombre",
      selector: (row) => `${row.firstName} ${row.lastName}`,
      sortable: true,
    },
    {
      id: "email",
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      id: "phone",
      name: "Teléfono",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      id: "address",
      name: "Dirección",
      selector: (row) => `${row.address}, ${row.commune}`,
      sortable: true,
    },
    {
      id: "cell-menu",
      cell: (row) => <TableRowMenu row={row} onSelectionChange={onSelectionChange} />,
      allowOverflow: true,
      button: true,
      width: "48px",
    },
  ];
};
