import {
  AddButton,
  AddButtonContainer,
  DayContainer,
  DaySlot,
  DayTitle,
  SectionDays,
  SectionSelects,
  SectionTitle,
} from "./styles";
import { Field, Formik } from "formik";
import { FormError, FormLabel, FormRoot, FormTitle, Icon } from "../../components";
import { availabilityScheme, unavailableScheme } from "../../helpers/schemas";
import { daysOfWeek, repeatDays } from "../../helpers/consts";

import { SelectField } from "../../components/select";
import { TimePickerField } from "../../components/date-time";
import { Tooltip } from "../../components/tooltip/tooltip";
import { format } from "date-fns";
import { useRef } from "react";

const slotOptions = [...daysOfWeek, ...repeatDays];

export const ScheduleAvailabilityForm = (props) => {
  const formik = useRef(null);

  const initialValues = {
    availabilityDay: "",
    availabilityStart: "",
    availabilityEnd: "",
    availability: {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    },
    ...props.data,
  };

  const onAddAvailabilitySlot = async () => {
    const values = formik.current.values;
    await formik.current.validateForm();

    if (formik.current.isValid) {
      let availability = { ...values.availability };
      const start = format(values.availabilityStart, "HH:mm");
      const end = format(values.availabilityEnd, "HH:mm");

      const days = getDayArray(values.availabilityDay);

      availability = days.reduce((acc, day) => {
        return { ...acc, [day]: [...acc[day], { start, end }] };
      }, availability);

      await formik.current.setFieldValue("availability", availability);
      await props.onSubmit(formik.current.values, formik.current);
    }
  };

  const onRemoveAvailabilitySlot = async (day, slot) => {
    const values = formik.current.values;
    const dayAvailability = [...values.availability[day]];
    const index = dayAvailability.indexOf(slot);
    dayAvailability.splice(index, 1);

    await formik.current.setFieldValue("availability", {
      ...values.availability,
      [day]: dayAvailability,
    });

    await props.onSubmit(formik.current.values, formik.current);
  };

  return (
    <Formik
      innerRef={formik}
      initialValues={initialValues}
      validationSchema={availabilityScheme}
      onSubmit={() => {
        //just to avoid a warning
      }}
    >
      <FormRoot columns={1}>
        <SectionTitle>
          <FormTitle>Horarios</FormTitle>
          <p>Indique el día y hora de inicio y fin de su horario.</p>
        </SectionTitle>
        <SectionSelects>
          <div>
            <FormLabel htmlFor="availabilityDay">Día</FormLabel>
            <SelectField
              items={slotOptions}
              id="availabilityDay"
              name="availabilityDay"
              placeholder="Seleccione el día"
            />
            <FormError name="availabilityDay" />
          </div>
          <div>
            <FormLabel htmlFor="availabilityStart">Desde</FormLabel>
            <TimePickerField id="availabilityStart" name="availabilityStart" />
            <FormError name="availabilityStart" />
          </div>
          <div>
            <FormLabel htmlFor="availabilityEnd">Hasta</FormLabel>
            <TimePickerField id="availabilityEnd" name="availabilityEnd" />
            <FormError name="availabilityEnd" />
          </div>
          <AddButton type="submit" onClick={onAddAvailabilitySlot}>
            Agregar Otro
          </AddButton>
        </SectionSelects>
        <SectionTitle>
          <FormTitle style={{ marginTop: 0 }}>Mi Horario</FormTitle>
        </SectionTitle>
        <SectionDays>
          <Availability name="availability" onRemoveSlot={onRemoveAvailabilitySlot} />
        </SectionDays>
        <FormError showUntouchedErrors name="generic" />
      </FormRoot>
    </Formik>
  );
};

export const ScheduleUnavailableForm = (props) => {
  const formik = useRef(null);

  const initialValues = {
    unavailableDay: "",
    unavailableStart: "",
    unavailableEnd: "",
    unavailable: {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    },
    ...props.data,
  };

  const onAddUnavailableSlot = async () => {
    const values = formik.current.values;
    await formik.current.validateForm();
    if (formik.current.isValid) {
      let unavailable = { ...values.unavailable };
      const start = format(values.unavailableStart, "HH:mm");
      const end = format(values.unavailableEnd, "HH:mm");

      const days = getDayArray(values.unavailableDay);

      unavailable = days.reduce((acc, day) => {
        return { ...acc, [day]: [...acc[day], { start, end }] };
      }, unavailable);

      await formik.current.setFieldValue("unavailable", unavailable);
      await props.onSubmit(formik.current.values, formik.current);
    }
  };

  const onRemoveUnavailableSlot = async (day, slot) => {
    const values = formik.current.values;
    const unavailableDay = [...values.unavailable[day]];
    const index = unavailableDay.indexOf(slot);
    unavailableDay.splice(index, 1);

    await formik.current.setFieldValue("unavailable", {
      ...values.unavailable,
      [day]: unavailableDay,
    });
    await props.onSubmit(formik.current.values, formik.current);
  };

  return (
    <Formik
      innerRef={formik}
      initialValues={initialValues}
      validationSchema={unavailableScheme}
      onSubmit={() => {
        //just to avoid a warning
      }}
    >
      <FormRoot columns={1}>
        <SectionTitle>
          <FormTitle flex>
            Bloquear horario
            <Tooltip>
              Para bloquear un horario específico en un día determinado, lo debes programar en tu agenda. Este bloqueo,
              será de forma permanente.
            </Tooltip>
          </FormTitle>
          <p>Indique el día, la hora inicial y final que desea bloquear en el calendario.</p>
        </SectionTitle>
        <SectionSelects>
          <div>
            <FormLabel htmlFor="unavailableDay">Día</FormLabel>
            <SelectField
              items={slotOptions}
              id="unavailableDay"
              name="unavailableDay"
              placeholder="Seleccione el día"
            />
            <FormError name="unavailableDay" />
          </div>
          <div>
            <FormLabel htmlFor="unavailableStart">Desde</FormLabel>
            <TimePickerField id="unavailableStart" name="unavailableStart" />
            <FormError name="unavailableStart" />
          </div>
          <div>
            <FormLabel htmlFor="unavailableEnd">Hasta</FormLabel>
            <TimePickerField id="unavailableEnd" name="unavailableEnd" />
            <FormError name="unavailableEnd" />
          </div>
          <AddButton type="submit" onClick={onAddUnavailableSlot}>
            Agregar Otro
          </AddButton>
        </SectionSelects>
        <SectionTitle>
          <FormTitle style={{ marginTop: 0 }}>Mis días bloqueados</FormTitle>
        </SectionTitle>
        <SectionDays>
          <Availability name="unavailable" onRemoveSlot={onRemoveUnavailableSlot} />
        </SectionDays>
        <FormError showUntouchedErrors name="generic" />
      </FormRoot>
    </Formik>
  );
};

const Availability = (props) => {
  return (
    <Field
      name={props.name}
      component={(formik) =>
        daysOfWeek.map((day) => (
          <DayContainer key={day.value}>
            <DayTitle>{day.label}</DayTitle>
            {formik.field.value[day.value].map((slot, index) => (
              <DaySlot key={index}>
                {`${slot.start} - ${slot.end}`}
                <Icon onClick={() => props.onRemoveSlot(day.value, slot)} size={15} name="fi-rr-trash" />
              </DaySlot>
            ))}
          </DayContainer>
        ))
      }
    />
  );
};

const getDayArray = (dayValue) => {
  switch (dayValue) {
    case "mondayToFriday":
      return ["monday", "tuesday", "wednesday", "thursday", "friday"];
    case "mondayToSaturday":
      return ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    case "mondayToSunday":
      return ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
    default:
      return [dayValue];
  }
};
