import styled, { css } from "styled-components";

export const IconContainer = styled.div`
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin: 0 auto;
  background-color: ${(props) => props.theme.colors.SECONDARY};
`;

export const UpdateContainer = styled.section`
  text-align: center;
  cursor: pointer;
  border: 1px dashed ${(props) => props.theme.colors.STROKE};
  padding: 10px;
`;

export const UpdateHint = styled.div`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`;

export const UpdateTitle = styled.div`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  margin-top: 10px;
  margin-bottom: 6px;
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-weight: 400;
  & > b {
    color: ${(props) => props.theme.colors.SECONDARY};
    font-weight: 700;
  }
`;

export const FilesContainer = styled.div`
  display: grid;
  grid-gap: 10px;
`;

export const File = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: ${(props) => props.theme.colors.WHITE};
  border: 1px solid ${(props) => props.theme.colors.STROKE};
  border-radius: 4px;
`;

// prettier-ignore
const FileInfoStyles =css`
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 16px;
  margin-right: 16px;
`;

export const FileInfo = styled.div`
  ${FileInfoStyles}
`;

export const FileLink = styled.a`
  ${FileInfoStyles}
`;
