import { Group, ItemText, Portal, Root, Value } from "@radix-ui/react-select";
import {
  SelectContent,
  SelectItem,
  SelectScrollDownButton,
  SelectScrollUpButton,
  SelectTrigger,
  SelectViewport,
} from "./styles";

import { Icon } from "../icon";
import React from "react";

/**
 *
 */
export const OptionButton = (props) => {
  return (
    <Root value={null} onValueChange={props.onValueChange}>
      <SelectTrigger style={props.triggerStyle} aria-label={props.name}>
        <Icon size={18} name={props.icon} />
        <Value>{props.text}</Value>
      </SelectTrigger>
      <Portal>
        <SelectContent position="popper" side="bottom" sideOffset={-1}>
          <SelectScrollUpButton>
            <Icon name="fi-rr-angle-small-up" />
          </SelectScrollUpButton>
          <SelectViewport>
            <Group>
              {props.items.map((item) => (
                <SelectItem key={item.to} value={item.to}>
                  <ItemText>{item.label}</ItemText>
                </SelectItem>
              ))}
            </Group>
          </SelectViewport>
          <SelectScrollDownButton>
            <Icon name="fi-rr-angle-small-down" />
          </SelectScrollDownButton>
        </SelectContent>
      </Portal>
    </Root>
  );
};
