import { InputStyles } from "../input/styles";
import styled from "styled-components";

// TODO: crear componente input, porque el Input actual es un field de formik
// Por ahora importé él estilo para evitar renombrar todos los Inputs del proyecto a InputField
export const DurationInput = styled.input`
  ${InputStyles}
`;

export const DurationContainer = styled.div`
  display: flex;
  gap: 15px;
`;
