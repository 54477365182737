import {
  Groopa,
  OrderEntry,
  OrderSuccessCard,
  OrderSuccessScreenContainer,
  OrderSummary,
  PriceRow,
  ServiceRow,
  SuccessTitle,
  Total,
} from "./styles";
import { getAppointmentTime, getServiceDuration, getServiceTypeName } from "../../helpers/format";
import { selectScheduledServices, selectScheduling } from "../../slices/scheduling-slice";

import { Icon } from "../../components";
import es from "date-fns/locale/es";
import { format } from "date-fns";
import { useMemo } from "react";
import { useSelector } from "react-redux";

export const PublicOrderSuccess = () => {
  const scheduling = useSelector(selectScheduling);
  const scheduledServices = useSelector(selectScheduledServices());
  const price = useMemo(() => {
    return scheduledServices.reduce((acc, curr) => {
      return acc + curr.price;
    }, 0);
  }, [scheduledServices]);

  if (!scheduling.id) {
    console.log("No scheduling data, redirecting.");
    return "No se encontraron datos.";
  }

  return (
    <OrderSuccessScreenContainer>
      <OrderSuccessCard>
        <SuccessTitle>¡Solicitud de reserva exitosa!</SuccessTitle>
        <p>{`Hola ${scheduling.firstName} ${scheduling.lastName}`}</p>
        <p>
          La reserva fue exitosa y quedará pendiente por <b>confirmación del profesional</b>.
          <br /> A continuación encontraras los detalles de la reserva.
        </p>
        <p>
          Gracias por elegir <Groopa>Groopa</Groopa>
        </p>
        {scheduledServices.map((service) => (
          <ServiceRow key={service.id}>
            <PriceRow>
              <b>{service.name}</b>
              <div>${service.price.toLocaleString("es-CL")}</div>
            </PriceRow>
            <OrderEntry>
              <Icon name="fi-rr-clock" size={14} />
              {getServiceDuration(service.durationMinutes, false)}
            </OrderEntry>
          </ServiceRow>
        ))}
        <OrderSummary>
          <OrderEntry>
            <Icon name="fi-rr-clock" size={14} />
            {getAppointmentTime(scheduling)}
          </OrderEntry>
          <OrderEntry>
            <Icon name="fi-rr-calendar" size={14} />
            {format(scheduling.date, "EEEE dd MMMM yyyy", { locale: es })}
          </OrderEntry>
          <OrderEntry>
            <Icon name="fi-rr-building" size={14} />
            {getServiceTypeName([scheduling.selectedType])}
          </OrderEntry>
        </OrderSummary>
        <PriceRow>
          <Total>Total</Total>
          <span>${price.toLocaleString("es-CL")}</span>
        </PriceRow>
      </OrderSuccessCard>
    </OrderSuccessScreenContainer>
  );
};
