import { AddButton, RemoveButton } from "./styles";

import { FieldArray } from "formik";
import { Icon } from "..";
import { Select } from "../select";

export const SelectArray = (props) => {
  return (
    <FieldArray
      name={props.name}
      render={(arrayHelpers) => {
        const values = arrayHelpers.form.values[props.name];
        return (
          <>
            {values.map((value, index) => (
              <div key={index} style={{ display: "inline-flex", marginBottom: 10 }}>
                <Select
                  id={`${props.name}.${index}`}
                  name={`${props.name}.${index}`}
                  items={props.items}
                  value={value}
                  labelField={props.labelField}
                  dataField={props.dataField}
                  placeholder={props.placeholder}
                  onValueChange={(value) => {
                    props.onValueChange && props.onValueChange(value);
                    arrayHelpers.replace(index, value);
                  }}
                />
                {index > 0 && (
                  <RemoveButton type="button" onClick={() => arrayHelpers.remove(index)}>
                    <Icon name="fi-rr-delete" />
                  </RemoveButton>
                )}
              </div>
            ))}
            <AddButton type="button" onClick={() => arrayHelpers.push("")}>
              {props.pushMessage}
            </AddButton>
          </>
        );
      }}
    />
  );
};
