import { IconButton, TooltipContent } from "./styles";
import { Portal, Provider, Root, Trigger } from "@radix-ui/react-tooltip";

import { Icon } from "../icon";
import { theme } from "../../styles/theme";
import { useState } from "react";

export const Tooltip = (props) => {
  const [open, setOpen] = useState(undefined);
  const onTooltipClick = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(undefined);
    }, 3000);
  };

  return (
    <Provider>
      <Root open={open}>
        <Trigger asChild>
          {props.trigger ? (
            props.trigger
          ) : (
            <IconButton onClick={onTooltipClick}>
              <Icon name="fi-rr-info" size={12} color={theme.colors.SECONDARY} />
            </IconButton>
          )}
        </Trigger>
        <Portal>
          <TooltipContent sideOffset={5}>{props.children}</TooltipContent>
        </Portal>
      </Root>
    </Provider>
  );
};
