import styled from "styled-components";

export const ClientDataScreenContainer = styled.div`
  background-color: ${(props) => props.theme.colors.LIGHT_BACKGROUND};
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 48px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const ClientDataLayout = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 1108px;
  width: 100%;
`;

export const ServiceSection = styled.div`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
`;

export const ServiceItem = styled.div`
  border-radius: 4px;
  padding: 0 20px;
  margin-bottom: 15px;
  margin-top: 15px;
`;

export const ServiceDetail = styled.div`
  display: flex;
  font-weight: 400;
  margin-top: 12px;
  .icon {
    margin-right: 4px;
  }
`;

export const ClientDataError = styled.div`
  display: flex;
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.ERROR};
  font-size: 13px;
  margin-bottom: 16px;
  align-items: center;
  .icon {
    margin-right: 4px;
  }
`;

export const BookContainer = styled.div`
  padding: 16px;
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 16px;
`;

export const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-size: 14px;
  font-weight: 700;
  & > span {
    font-weight: 400;
  }
`;
