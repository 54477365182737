import styled, { css } from "styled-components";

export const ScheduleFormContainer = styled.div`
  flex: 1;
`;

// prettier-ignore
const paddingStyles =css`
  padding-left: 22px;
  padding-right: 22px;
`;

export const Section = styled.div`
  ${paddingStyles}
`;

export const SectionSelects = styled.div`
  ${paddingStyles}
  display: flex;
  align-items: flex-start;
  gap: 25px;
  flex-wrap: wrap;
  & > div {
    width: 200px;
  }
`;

export const AddButton = styled.button`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  font-size: 14px;
  padding: 10px 15px;
  width: 200px;
  height: 39px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  cursor: pointer;
  line-height: 1;
  align-self: flex-end;

  background: ${(props) => props.theme.colors.PRIMARY};
  color: ${(props) => props.theme.colors.WHITE};
  border: 1px solid ${(props) => props.theme.colors.PRIMARY};

  &:hover {
    background-color: ${(props) => props.theme.colors.PRESSED};
  }
`;

export const SectionTitle = styled.div`
  ${paddingStyles}
  & > h2 {
    margin-bottom: 6px;
  }
  & > p {
    margin: 0;
  }
`;

export const SectionDays = styled.div`
  ${paddingStyles}
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  flex-wrap: wrap;
`;

export const DayTitle = styled.div`
  font-weight: 700;
`;
export const DaySlot = styled.div`
  margin-top: 10px;
  font-weight: 500;
  display: flex;
  gap: 15px;
  align-items: center;
`;

export const DayContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  line-height: 19px;
  font-size: 14px;
  margin-top: 5px;
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.TEXT_DARK};
`;

export const Slots = styled.div`
  display: flex;
  flex-direction: column;
`;
