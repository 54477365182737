import { AppointmentState } from "../../helpers/consts";
import { routes } from "../../navigation/routes";

export default {
  schedule: {
    icon: "fi-rr-calendar",
    to: routes.schedule(),
    text: "Agenda",
    enabled: true,
  },
  history: {
    icon: "fi-rr-time-past",
    to: routes.history(AppointmentState.PENDING),
    activeFor: routes.history(),
    text: "Historial",
    enabled: true,
  },
  messages: {
    icon: "fi-rr-comments",
    to: routes.messages(),
    text: "Mensajes",
    enabled: false,
  },
  clients: {
    icon: "fi-rr-address-book",
    to: routes.clients(),
    text: "Clientes",
    enabled: true,
  },
  services: {
    icon: "fi-rr-hand-holding-heart",
    to: routes.services(),
    text: "Servicios",
    enabled: true,
  },
  notifications: {
    icon: "fi-rr-bell",
    text: "Notificaciones",
    enabled: true,
  },
  user: {
    icon: "fi-rr-user",
    to: routes.userAccount(),
    text: "Usuario",
    enabled: true,
  },
  share: {
    icon: "fi-rr-share",
    text: "Compartir Perfil",
    to: routes.shareProfile(),
    enabled: true,
  },
  logout: {
    icon: "fi-rr-sign-out-alt",
    text: "Cerrar sesión",
    enabled: true,
  },
};
