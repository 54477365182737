import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.OVERLAY};
  z-index: 100;
  animation: ${fadeIn} 0.2s ease-in-out;
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
  overflow-y: ${(props) => props.overflowY || "auto"};
  width: ${(props) => (props.$expand ? "100%" : "unset")};

  ${(props) =>
    props.$fullheight
      ? `
    display:flex;
    flex-direction: column;
    justify-content: center;
    height:100%;
  `
      : ``}

  @media (min-width: 540px) {
    max-width: 540px;
  }
`;
