import { LoaderContainer, LoaderMessage, SpinnerWheel, SpinnerWrapper } from "./styles";

import { Dialog } from "../dialog";
import { DialogContent } from "../dialog/styles";

export const Loader = () => {
  return (
    <SpinnerWrapper>
      <SpinnerWheel />
    </SpinnerWrapper>
  );
};

export const LoaderDialog = (props) => {
  const message = props.message || "Cargando";
  return (
    <Dialog open={true}>
      <DialogContent style={{ width: "auto" }}>
        <LoaderContainer>
          <Loader />
          <LoaderMessage>{message}</LoaderMessage>
        </LoaderContainer>
      </DialogContent>
    </Dialog>
  );
};
