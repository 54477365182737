import styled from "styled-components";

export const ServiceFormContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 20px;
  margin-right: 20px;
`;

export const ServiceImageContainer = styled.div`
  width: 160px;
  height: 90px;
  overflow: hidden;
  display: inline-block;
`;

export const ServiceImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ServiceImages = styled.div`
  overflow-x: scroll;
  white-space: nowrap;
  & > div:not(:last-child) {
    margin-right: 10px;
  }
`;
