import styled from "styled-components";

export const StepContainer = styled.div`
  padding: 20px;
`;

export const StepDescription = styled.p`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.TEXT_DARK};
  margin-top: 15px;
  margin-bottom: 0;
`;

export const StepNumber = styled.div`
  width: 18px;
  height: 18px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.SECONDARY};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.WHITE};
  font-weight: 700;
  font-size: 10px;
`;

export const StepTitle = styled.div`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.SECONDARY};
  display: flex;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
`;
