import { Logo, MobileMenuIcon, MobileNavContainer, SidebarButton, SidebarIcon, SidebarLink } from "./styles";

import logo from "../../assets/images/logo-black.png";

export const MobileNav = (props) => {
  return (
    <MobileNavContainer>
      <MobileMenuIcon onClick={props.onOpenSidebar} />
      <Logo src={logo} alt="Groopa" />
    </MobileNavContainer>
  );
};
