import {
  acceptTermsValidations,
  addressValidation,
  availabilityValidation,
  categoriesValidations,
  createPasswordValidations,
  dateValidation,
  descriptionValidation,
  emailValidations,
  fullnameValidations,
  genderValidations,
  nameValidations,
  passwordValidations,
  phoneValidations,
  serviceListValidation,
  serviceTypeValidation,
  serviceValidation,
  timeValidation,
  timeoffValidation,
  unavailableValidation,
} from "./validations";

import { object } from "yup";

export const signupScheme = object({
  ...nameValidations,
  ...emailValidations,
  ...phoneValidations,
  ...createPasswordValidations,
  ...acceptTermsValidations,
});

export const loginScheme = object({
  ...emailValidations,
  ...passwordValidations,
});

export const passwordResetScheme = object({
  ...emailValidations,
});

export const passwordUpdateScheme = object({
  ...createPasswordValidations,
});

export const completeAccountScheme = object({
  ...nameValidations,
  ...phoneValidations,
  ...genderValidations,
});

export const completeProfileScheme = object({
  ...categoriesValidations,
  ...descriptionValidation,
  ...addressValidation,
});

export const appointmentScheme = object({
  ...nameValidations,
  ...emailValidations,
  ...phoneValidations,
  ...addressValidation,
  ...dateValidation,
  ...timeValidation,
  ...serviceTypeValidation,
  ...serviceListValidation,
});

export const updateAppointmentScheme = object({
  ...dateValidation,
  ...timeValidation,
});

export const timeOffScheme = object({
  ...timeoffValidation,
});

export const serviceScheme = object(serviceValidation);

export const availabilityScheme = object({
  ...availabilityValidation,
});

export const unavailableScheme = object({
  ...unavailableValidation,
});

export const clientDataScheme = object({
  ...nameValidations,
  ...emailValidations,
  ...phoneValidations,
  ...addressValidation,
});
