import { Icon } from "../../components/icon";
import { OptionButton } from "../../components/option-button";
import { Select } from "../../components/select";
import { routes } from "../../navigation/routes";
import styled from "styled-components";

const ScheduleActions = [
  {
    label: "Agendar cita",
    to: routes.scheduleAppointment(),
  },
  {
    label: "Bloquear horario",
    to: routes.scheduleTimeoff(),
  },
];

const ScheduleViewItems = [
  {
    label: "Mes",
    value: "month",
  },
  {
    label: "Semana",
    value: "week",
  },
  {
    label: "Día",
    value: "day",
  },
];

const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap-reverse;
  margin-top: -10px;
  & > * {
    margin-top: 10px;
  }
`;

const ToolbarSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ToolbarButton = styled.button`
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  height: 35px;
  background-color: white;
  color: ${(props) => props.theme.colors.TEXT_DARK};
  border: 1px solid ${(props) => props.theme.colors.STROKE};
  &[data-placeholder] {
    color: #747574;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.STROKE};
  }
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.PRIMARY};
  }
  ${(props) => props.disabled && `background-color:${props.theme.colors.STROKE}`}
`;

const ToolbarLabel = styled.div`
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  &::first-letter {
    text-transform: capitalize;
  }
`;

export const ScheduleToolbar = (props) => {
  return (
    <ToolbarContainer>
      <ToolbarSection>
        <Select value={props.view} items={ScheduleViewItems} onValueChange={props.onView} />
        <ToolbarButton disabled={props.date < props.minDate} onClick={() => props.onNavigate("PREV")}>
          <Icon size={20} name="fi-rr-angle-small-left" />
        </ToolbarButton>
        <ToolbarLabel>{props.label}</ToolbarLabel>
        <ToolbarButton onClick={() => props.onNavigate("NEXT")}>
          <Icon size={20} name="fi-rr-angle-small-right" />
        </ToolbarButton>
      </ToolbarSection>
      <ToolbarSection>
        <Select
          clearable
          clearText="Todos los servicios"
          value={props.selectedService}
          items={props.services}
          placeholder={"Selecciona un servicio"}
          onValueChange={props.onChangeService}
          labelField={"name"}
          dataField={"id"}
        />
        <OptionButton text="Nuevo" icon="fi-rr-plus-small" items={ScheduleActions} onValueChange={props.onAction} />
      </ToolbarSection>
    </ToolbarContainer>
  );
};
