import {
  AppointmentDescription,
  AppointmentFormContainer,
  AppointmentInfo,
  AppointmentInfoField,
  AppointmentTitle,
} from "./styles";
import {
  CardBody,
  CardClose,
  CardTitle,
  FormButton,
  FormError,
  FormLabel,
  FormRoot,
  Icon,
  Modal,
} from "../../components";
import { DatePickerField, TimePickerField } from "../../components/date-time";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Formik } from "formik";
import { RowSpan } from "../../components/column/styles";
import { getServiceTypeName } from "../../helpers/format";
import { getServices } from "../../services/services-service";
import { parseISO } from "date-fns";
import { selectAppointment } from "../../slices/appointments-slice";
import { selectServices } from "../../slices/services-slice";
import { updateAppointment } from "../../services/appointments-service";
import { updateAppointmentScheme } from "../../helpers/schemas";

export const UpdateAppointmentModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const services = useSelector(selectServices);
  const appointment = useSelector(selectAppointment(params.id));
  const initialValues = {
    date: parseISO(appointment.startDate),
    time: parseISO(appointment.startDate),
  };

  const servicesData = useMemo(() => {
    return services.filter((service) => appointment.services.some((id) => id === service.id));
  }, [services, appointment.services]);

  // get services if needed
  useEffect(() => {
    if (services.length === 0) {
      dispatch(getServices());
    }
  }, [services]);

  const onSubmit = async (data, formik) => {
    const result = await dispatch(
      updateAppointment({
        id: appointment.id,
        durationMinutes: appointment.durationMinutes,
        date: data.date,
        time: data.time,
      })
    );
    if (result.type === "appointments/update/rejected") {
      formik.setErrors({
        generic: result.error.message,
      });
    }
    if (result.type === "appointments/update/fulfilled") {
      navigate(-1);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Modal expand overflowY={"unset"} onClose={onCancel}>
      <CardTitle>
        Cambiar cita
        <CardClose onClick={onCancel} />
      </CardTitle>
      <CardBody $borderBottom $roundedBottom>
        <AppointmentFormContainer>
          <AppointmentTitle>{`${appointment.firstName} ${appointment.lastName}`}</AppointmentTitle>
          <AppointmentInfo>
            <AppointmentInfoField>
              <Icon name="fi-rr-building" size={14} />
              {getServiceTypeName([appointment.serviceType])}
            </AppointmentInfoField>
            <AppointmentInfoField>
              <Icon name="fi-rr-money" size={14} />
              {`$${appointment.price.toLocaleString("es-CL")}`}
            </AppointmentInfoField>
          </AppointmentInfo>
          <Formik initialValues={initialValues} validationSchema={updateAppointmentScheme} onSubmit={onSubmit}>
            <FormRoot columns={2}>
              <RowSpan span={2} style={{ flexDirection: "column" }}>
                {servicesData.map((service) => (
                  <div>
                    <AppointmentTitle>{service.name}</AppointmentTitle>
                    <AppointmentDescription>{service.description}</AppointmentDescription>
                  </div>
                ))}
              </RowSpan>
              <div>
                <FormLabel htmlFor="date">Fecha</FormLabel>
                <DatePickerField id="date" name="date" popperPlacement={"top"} />
                <FormError /*TODO: remove */ showUntouchedErrors /*me*/ name="date" />
              </div>
              <div>
                <FormLabel htmlFor="time">Hora</FormLabel>
                <TimePickerField id="time" name="time" popperPlacement={"top"} />
                <FormError /*TODO: remove */ showUntouchedErrors /*me*/ name="time" />
              </div>

              <FormButton $type="secondary" type="button" onClick={onCancel}>
                Cancelar
              </FormButton>
              <FormButton type="submit">Guardar</FormButton>
            </FormRoot>
          </Formik>
        </AppointmentFormContainer>
      </CardBody>
    </Modal>
  );
};
