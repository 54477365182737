import {
  CardBody,
  CardDivider,
  CardFooter,
  CardTitle,
  FormButton,
  FormLink,
  StepContainer,
  StepDescription,
  StepNumber,
  StepTitle,
} from "../../components";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Icon } from "../../components/icon";
import { ServicesGrid } from "../../grids/services-grid/services-grid";
import { getServices } from "../../services/services-service";
import { routes } from "../../navigation/routes";
import { selectServices } from "../../slices/services-slice";
import { updateUser } from "../../services/user-service";
import { useEffect } from "react";

export const SetupServicesScreen = () => {
  const dispatch = useDispatch();
  const services = useSelector(selectServices);
  const navigate = useNavigate();

  // refresco servicios en esta página
  useEffect(() => {
    dispatch(getServices());
  }, []);

  const onContinueClick = async () => {
    await dispatch(
      updateUser({
        setupComplete: true,
      })
    );
    // redirect se encarga de la redirección
  };
  const onBackClick = () => {
    navigate(routes.setupProfile());
  };

  return (
    <div>
      <CardTitle>
        <Icon name="fi-rr-user" className="card-icon" size={20} />
        Crea tu perfil
      </CardTitle>
      <CardBody>
        <StepContainer>
          <StepTitle>
            <StepNumber>3</StepNumber>Configura tus servicios
          </StepTitle>
          <StepDescription>
            Ingresa los servicios que realizas. Se bastante especifico para que el cliente entienda bien lo que haces.
          </StepDescription>
        </StepContainer>
        <CardDivider />
        <ServicesGrid data={services} emptyRoute={routes.setupAddService()} />
        {services.length ? (
          <>
            <div style={{ display: "flex", justifyContent: "flex-end", padding: 20 }}>
              <FormLink $type={"secondary"} to={routes.setupAddService()}>
                + Agregar otro servicio
              </FormLink>
            </div>
            <CardDivider />
          </>
        ) : null}
      </CardBody>
      <CardFooter>
        <FormButton $type="secondary" onClick={onBackClick} style={{ width: 100 }}>
          Anterior
        </FormButton>
        <FormButton disabled={services.length === 0} onClick={onContinueClick} style={{ width: 100 }}>
          Guardar
        </FormButton>
      </CardFooter>
      <Outlet />
    </div>
  );
};
