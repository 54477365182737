import { CheckboxContainer, CheckboxIndicator, CheckboxLabel, CheckboxRoot } from "./styles";

import { Field } from "formik";
import { Icon } from "../icon";
import React from "react";

export const Checkbox = (props) => {
  return (
    <CheckboxContainer style={props.style}>
      <CheckboxRoot
        name={props.name}
        checked={props.checked}
        onCheckedChange={props.onCheckedChange}
        onBlur={props.onBlur}
      >
        <CheckboxIndicator>
          <Icon style={{ padding: 2 }} name="fi-rr-check" size={14} color={"white"} />
        </CheckboxIndicator>
      </CheckboxRoot>
      {props.children && <CheckboxLabel htmlFor={props.name}>{props.children}</CheckboxLabel>}
    </CheckboxContainer>
  );
};

export const CheckboxField = (props) => {
  return (
    <Field
      name={props.name}
      component={(formik) => {
        return (
          <Checkbox
            name={formik.field.name}
            checked={formik.field.value}
            onBlur={formik.field.onBlur}
            onCheckedChange={(checked) => {
              formik.form.setFieldValue(formik.field.name, checked);
            }}
          >
            {props.children}
          </Checkbox>
        );
      }}
    ></Field>
  );
};
