import styled from "styled-components";

export const ServiceTitle = styled.div`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  margin-bottom: 10px;
`;

export const ServiceInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 18px;
`;
export const ServiceInfoField = styled.div`
  display: flex;
  align-items: center;
  & > p {
    margin-left: 7px;
  }
`;

export const ServiceDescription = styled.div`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  margin-bottom: 18px;
`;
