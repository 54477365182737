import { auth, database, storage } from "../firebase";
import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { getUniqueFilename, replaceFilename } from "../helpers/file";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { formatISO } from "date-fns";
import { getUserSubscription } from "./subscription-service";

export const signupWithEmailAndPassword = createAsyncThunk("user/signup", async (data) => {
  const { email, password, firstName, lastName, phone } = data;

  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  const uid = userCredential.user.uid;

  // agregar roles futuros
  const role = "professional";
  const setupComplete = false;

  //save last login timestamp
  await localStorage.setItem("lastLoginTime", formatISO(new Date()));

  const docRef = doc(database, "users", uid);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  await setDoc(docRef, {
    role,
    setupComplete,
    email,
    firstName,
    lastName,
    phone,
    timeZone,
  });

  return {
    role,
    setupComplete,
    uid,
    email,
    firstName,
    lastName,
    phone,
    timeZone,
  };
});

export const loginWithEmailAndPassword = createAsyncThunk("user/login", async (data, { dispatch }) => {
  const { email, password } = data;

  const userCredential = await signInWithEmailAndPassword(auth, email, password);
  const uid = userCredential.user.uid;

  const docRef = doc(database, "users", uid);

  const user = await getDoc(docRef);
  const userData = await user.data();

  //save last login timestamp
  await localStorage.setItem("lastLoginTime", formatISO(new Date()));

  //get user subscription before continue
  await dispatch(getUserSubscription(uid));

  return {
    uid,
    ...userData,
  };
});

export const sendPasswordRecoverEmail = createAsyncThunk("user/password-reset", async (email) => {
  await sendPasswordResetEmail(auth, email);
});

export const updatePassword = createAsyncThunk("user/password-update", async (data) => {
  return await confirmPasswordReset(auth, data.oobCode, data.newPassword);
});

export const logout = createAsyncThunk("user/logout", async () => {
  await localStorage.setItem("lastLoginTime", null);
  await signOut(auth);
});

export const updateProfileImage = createAsyncThunk("user/profile-image-update", async (file, { getState }) => {
  const state = getState();
  const user = state.user.data;
  const uid = user.uid;

  const filename = replaceFilename(file, "profile-image");
  const avatarRef = ref(storage, `users/${uid}/profile/${filename}`);

  await uploadBytes(avatarRef, file);
  const profileImageURL = await getDownloadURL(avatarRef);

  const docRef = doc(database, "users", uid);
  await updateDoc(docRef, {
    profileImage: profileImageURL,
  });

  return profileImageURL;
});

export const updatePortfolio = createAsyncThunk("user/portfolio-update", async (filesOrURLs, { getState }) => {
  const state = getState();
  const user = state.user.data;
  const uid = user.uid;

  const uploadPicture = async (file) => {
    return new Promise(async (resolve, reject) => {
      try {
        const fileName = getUniqueFilename(file);
        const fileRef = ref(storage, `users/${uid}/portfolio/${fileName}`);
        const result = await uploadBytes(fileRef, file);
        const downloadLink = await getDownloadURL(result.ref);
        resolve(downloadLink);
      } catch (error) {
        reject(error);
      }
    });
  };

  const removePicture = async (file) => {
    return new Promise(async (resolve, reject) => {
      try {
        const fileRef = ref(storage, file);
        await deleteObject(fileRef);

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const fileURLs = [];
  const filesToUpload = [];

  // remove files if needed
  const missingElements = !user.portfolio ? [] : user.portfolio.filter((element) => !filesOrURLs.includes(element));
  if (missingElements.length > 0) {
    const filesToRemove = missingElements.map(removePicture);
    await Promise.all(filesToRemove);
  }

  // chequea si hay archivos para subir filtrando por tipo File
  filesOrURLs.forEach((fileOrURL) => {
    if (fileOrURL instanceof window.File) {
      filesToUpload.push(fileOrURL);
    }
    if (typeof fileOrURL === "string") {
      fileURLs.push(fileOrURL);
    }
  });
  // sube los nuevos archivos, reemplazando los anteriores
  if (filesToUpload.length > 0) {
    const uploadTasks = filesToUpload.map(uploadPicture);
    const newFileURLs = await Promise.all(uploadTasks);
    return [...fileURLs, ...newFileURLs];
  }

  return [...fileURLs];
});

export const updateUser = createAsyncThunk("user/update", async (data, { getState }) => {
  const state = getState();
  const uid = state.user.data.uid;
  const docRef = doc(database, "users", uid);

  await updateDoc(docRef, data);

  return data;
});
