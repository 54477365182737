import styled from "styled-components";

export const StyledIcon = styled.i`
  font-size: ${(props) => props.$size}px;
  color: ${(props) => props.color};
  display: flex;
`;

export const IconContainer = styled.div`
  position: relative;
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
  ${(props) => props.onClick && `cursor: pointer;`}
`;

export const IconBadge = styled.span`
  position: absolute;
  top: 1px;
  right: 0px;
  color: white;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.badgeColor || props.theme.colors.SECONDARY};
`;
