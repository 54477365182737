import styled, { keyframes } from "styled-components";

const SpinnerAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LoaderMessage = styled.div`
  margin-left: 16px;
  margin-right: 16px;
`;

export const SpinnerWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
`;

export const SpinnerWheel = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 32px;
  height: 32px;
  margin: -16px 0 0 -16px;
  border: 4px solid ${(props) => props.theme.colors.PRIMARY};
  border-radius: 50%;
  border-top-color: ${(props) => props.theme.colors.STROKE};
  animation: ${SpinnerAnimation} 1s cubic-bezier(0.65, 0.05, 0.36, 1) infinite;
`;
