import { DatePickerField, TimePickerField } from "../../components/date-time";
import { FormButton, FormError, FormLabel, FormRoot } from "../../components";

import { Formik } from "formik";
import { RowSpan } from "../../components/column/styles";
import { timeOffScheme } from "../../helpers/schemas";

export const TimeOffForm = (props) => {
  const initialValues = {};

  return (
    <Formik initialValues={initialValues} validationSchema={timeOffScheme} onSubmit={props.onSubmit}>
      <FormRoot columns={3}>
        <div>
          <FormLabel htmlFor="date">Fecha</FormLabel>
          <DatePickerField id="date" name="date" />
          <FormError /*TODO: remove */ showUntouchedErrors /*me*/ name="date" />
        </div>
        <div>
          <FormLabel htmlFor="startDate">Inicio</FormLabel>
          <TimePickerField id="startDate" name="startDate" />
          <FormError /*TODO: remove */ showUntouchedErrors /*me*/ name="startDate" />
        </div>
        <div>
          <FormLabel htmlFor="endDate">Fin</FormLabel>
          <TimePickerField id="endDate" name="endDate" />
          <FormError /*TODO: remove */ showUntouchedErrors /*me*/ name="endDate" />
        </div>
        <RowSpan span={3} style={{ marginTop: 10 }}>
          <FormError showUntouchedErrors name="generic" />
        </RowSpan>
        <RowSpan span={3} style={{ marginTop: 10, gap: 20 }}>
          <FormButton $type="secondary" type="button" onClick={props.onCancel}>
            Cancelar
          </FormButton>
          <FormButton type="submit">Guardar</FormButton>
        </RowSpan>
      </FormRoot>
    </Formik>
  );
};
