import { PlanBody, PlanButton, PlanCard, PlanPrice, PlanTitle } from "./styles";

export const Plan = (props) => {
  return (
    <PlanCard>
      <div style={{ flex: 1 }}>
        <PlanTitle>{props.title}</PlanTitle>
        <PlanBody>{props.children}</PlanBody>
      </div>
      {!props.trial && (
        <div style={{ flex: 1, textAlign: "right" }}>
          <PlanPrice>
            {props.price}
            <span>/{props.unit}</span>
          </PlanPrice>
          <PlanButton onClick={props.onClick}>{props.continueText}</PlanButton>
        </div>
      )}
    </PlanCard>
  );
};
