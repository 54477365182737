import { FormBadge, FormButton, FormError, FormLabel, FormRoot, FormTitle, Input } from "../../components";

import { Formik } from "formik";
import { RowSpan } from "../../components/column/styles";
import { SelectField } from "../../components/select";
import { clientDataScheme } from "../../helpers/schemas";
import communes from "../../assets/data/communes.json";

export const ClientForm = (props) => {
  return (
    <Formik initialValues={props.initialValues} validationSchema={clientDataScheme} onSubmit={props.onSubmit}>
      <FormRoot columns={2}>
        <RowSpan block span={2}>
          <FormTitle style={{ marginBottom: 0, marginTop: 0 }}>Contacto</FormTitle>
        </RowSpan>
        <div>
          <FormLabel htmlFor="firstName">Nombre</FormLabel>
          <Input id="firstName" name="firstName" type="text" placeholder="Ingrese tu nombre" />
          <FormError name="firstName" />
        </div>
        <div>
          <FormLabel htmlFor="lastName">Apellido</FormLabel>
          <Input id="lastName" name="lastName" type="text" placeholder="Ingrese tu apellido" />
          <FormError name="lastName" />
        </div>
        <div>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input id="email" name="email" type="text" placeholder="Ingrese tu email" />
          <FormError name="email" />
        </div>
        <div>
          <FormLabel htmlFor="phone">Teléfono</FormLabel>
          <Input id="phone" name="phone" type="text" placeholder="Ingrese tu teléfono" />
          <FormError name="phone" />
        </div>
        <RowSpan block span={2}>
          <FormTitle style={{ marginBottom: 0 }}>Dirección</FormTitle>
        </RowSpan>
        <div>
          <FormLabel htmlFor="commune">Comúna</FormLabel>
          <SelectField id="commune" name="commune" items={communes} placeholder="Comuna" />
          <FormError name="commune" />
        </div>
        <div>
          <FormLabel htmlFor="address">Calle y numeración</FormLabel>
          <Input id="address" name="address" />
          <FormError name="address" />
        </div>
        <div>
          <FormLabel flex htmlFor="other">
            Otro
            <FormBadge>Opcional</FormBadge>
          </FormLabel>
          <Input id="other" name="other" />
          <FormError name="other" />
        </div>
        <div>
          <FormLabel htmlFor="postalCode">
            Código Postal
            <FormBadge>Opcional</FormBadge>
          </FormLabel>
          <Input id="postalCode" name="postalCode" />
          <FormError name="postalCode" />
        </div>
        <RowSpan span={2} style={{ marginTop: 10 }}>
          <FormError showUntouchedErrors name="generic" />
        </RowSpan>
        <FormButton $type="secondary" type="button" onClick={props.onCancel}>
          Cancelar
        </FormButton>
        <FormButton type="submit">Guardar</FormButton>
      </FormRoot>
    </Formik>
  );
};
