import { ScheduleAvailabilityForm, ScheduleUnavailableForm } from "../../forms/schedule-form/schedule-form";
import { useDispatch, useSelector } from "react-redux";

import { CardDivider } from "../../components";
import { ScheduleFormContainer } from "../../forms/schedule-form/styles";
import { selectUser } from "../../slices/user-slice";
import { updateUser } from "../../services/user-service";

export const ScheduleTab = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const onAvailabilitySubmit = async (data, formik) => {
    // actualiza perfil del usuario
    const result = await dispatch(
      updateUser({
        availability: data.availability,
      })
    );

    if (result.type === "user/update/rejected") {
      formik.setErrors({
        generic: result.error.message,
      });
    }
    if (result.type === "user/update/fulfilled") {
      await formik.setFieldValue("availabilityStart", undefined);
      await formik.setFieldValue("availabilityEnd", undefined);
      formik.setErrors({});
    }
  };

  const onUnavailableSubmit = async (data, formik) => {
    const result = await dispatch(
      updateUser({
        unavailable: data.unavailable,
      })
    );

    if (result.type === "user/update/rejected") {
      formik.setErrors({
        generic: result.error.message,
      });
    }
    if (result.type === "user/update/fulfilled") {
      await formik.setFieldValue("unavailableStart", undefined);
      await formik.setFieldValue("unavailableEnd", undefined);
      formik.setErrors({});
    }
  };

  return (
    <ScheduleFormContainer>
      <ScheduleAvailabilityForm data={user} onSubmit={onAvailabilitySubmit} />
      <CardDivider />
      <ScheduleUnavailableForm data={user} onSubmit={onUnavailableSubmit} />
    </ScheduleFormContainer>
  );
};
