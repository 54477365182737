import { GridOneColumnsStyle, GridThreeColumnsStyle, GridTwoColumnsStyle } from "../column/styles";
import styled, { css } from "styled-components";

import { Form } from "formik";
import { Link } from "react-router-dom";

export const FormRoot = styled(Form)`
  width: 100%;
  ${(props) => props.columns === 1 && GridOneColumnsStyle}
  ${(props) => props.columns === 2 && GridTwoColumnsStyle}
  ${(props) => props.columns === 3 && GridThreeColumnsStyle}
`;

export const FormLabel = styled.label`
  display: ${(props) => (props.flex ? "flex" : "block")};
  align-items: center;
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-weight: ${(props) => props.fontWeight || 700};
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 6px;
`;

export const FormParagraph = styled.p`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
`;

export const FormBadge = styled.span`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.TEXT_DARK};
  background-color: #f0f0f0;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  border-radius: 100px;
  padding: 2px 7px;
  margin-left: 10px;
`;

export const StyledError = styled.div`
  margin-top: 6px;
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.ERROR};
  font-size: 13px;
`;

export const FormTitle = styled.h2`
  display: ${(props) => (props.flex ? "flex" : "block")};
  align-items: center;
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => (props.primary ? props.theme.colors.PRIMARY : props.theme.colors.TEXT_DARK)};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

// prettier-ignore
const PrimaryButtonStyles =css`
  background: ${(props) => props.theme.colors.PRIMARY};
  color: ${(props) => props.theme.colors.WHITE};
  border: 1px solid ${(props) => props.theme.colors.PRIMARY};

  &:hover {
    background-color: ${(props) => props.theme.colors.PRESSED};
  }
`;

// prettier-ignore
const SecondaryButtonStyles =css`
  background: ${(props) => props.theme.colors.WHITE};
  color: ${(props) => props.theme.colors.TEXT_DARK};
  border: 1px solid ${(props) => props.theme.colors.STROKE};

  &:hover {
    background-color: ${(props) => props.theme.colors.STROKE};
  }
`;
// prettier-ignore
const OutlineButtonStyles =css`
  background: ${(props) => props.theme.colors.WHITE};
  color: ${(props) => props.theme.colors.PRIMARY};
  border: 1px solid ${(props) => props.theme.colors.PRIMARY};

  &:hover {
    background-color: ${(props) => props.theme.colors.STROKE};
  }
`;

export const FormButton = styled.button`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  font-size: 14px;
  padding: 10px 15px;
  width: 100%;
  height: 39px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  cursor: pointer;
  line-height: 1;

  ${(props) => props.$type === "primary" && PrimaryButtonStyles}
  ${(props) => props.$type === "secondary" && SecondaryButtonStyles}
  ${(props) => props.$type === "outline" && OutlineButtonStyles}


  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

FormButton.defaultProps = {
  $type: "primary",
};

export const FormLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  &,
  &:hover {
    color: ${(props) => (props.$type === "secondary" ? props.theme.colors.SECONDARY : props.theme.colors.PRIMARY)};
  }

  &:focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.FOCUS_OUTLINE};
    outline-offset: -4px;
  }
`;

export const FormActions = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: space-between;

  & > button {
    max-width: 100px;
  }
  & > *:only-child {
    margin-left: auto;
  }
`;
