import { createSlice } from "@reduxjs/toolkit";
import { logout } from "../services/user-service";

const INITIAL_STATE = {
  data: [],
  checked: true,
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: INITIAL_STATE,
  reducers: {
    setNewNotifications: (state, action) => {
      state.checked = state.data.length === action.payload.length;
      state.data = action.payload;
    },
    setChecked: (state) => {
      state.checked = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => {
      return INITIAL_STATE;
    });
  },
});

export const { setNewNotifications, setChecked } = notificationsSlice.actions;
// selectors
export const selectNotifications = (state) => state.notifications.data;
export const selectChecked = (state) => state.notifications.checked;

export default notificationsSlice.reducer;
