import { Content, Description, Overlay, Title } from "@radix-ui/react-dialog";
import styled, { keyframes } from "styled-components";

const overlayShow = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const contentShow = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`;

export const DialogOverlay = styled(Overlay)`
  z-index: 9999;
  background-color: ${(props) => props.theme.colors.OVERLAY};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  inset: 0;
  animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
`;

export const DialogContent = styled(Content)`
  border: 1px solid #dcdfe3;
  padding: 20px;
  border-radius: 12px;
  width: 90vw;
  max-width: 540px;
  max-height: 85vh;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);

  &:focus {
    outline: none;
  }
`;

export const DialogTitle = styled(Title)`
  margin: 0;
  font-weight: 500;
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-size: 17px;
`;

export const DialogDescription = styled(Description)`
  margin: 10px 0 20px;
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-size: 15px;
  line-height: 1.5;
`;

/* 
.IconButton {
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--violet11);
  position: absolute;
  top: 10px;
  right: 10px;
}
.IconButton:hover {
  background-color: var(--violet4);
}
.IconButton:focus {
  box-shadow: 0 0 0 2px var(--violet7);
} */
