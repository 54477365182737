import { FormButton, FormLink } from "../form/styles";

import styled from "styled-components";

export const SubscriptionContainer = styled.div`
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const PlanCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 20px;
  padding-left: 30px;
  display: flex;
`;

export const PlanTitle = styled.div`
  color: ${(props) => props.theme.colors.SECONDARY};
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
`;

export const PlanBody = styled.p`
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;
export const PlanPrice = styled.div`
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  text-align: right;
  span {
    font-size: 16px;
  }
`;

export const PlanButton = styled(FormButton)`
  margin-top: 10px;
  font-weight: 700;
  max-width: 300px;
`;

export const PlanContainer = styled.div`
  display: grid;
  gap: 20px 15px;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1370px) {
    grid-template-columns: 1fr;
  }
`;

export const SkipButton = styled(FormLink).attrs({
  $type: "secondary",
})`
  margin-left: auto;
  font-weight: 600;
`;

export const SubscriptionTitle = styled.div`
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
`;

export const SubscriptionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SubscriptionHistory = styled.div`
  width: 100%;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${(props) => props.theme.colors.STROKE};
`;

export const SubscriptionText = styled.div`
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  font-weight: 400;
  font-size: 12px;
  line-height: 1;

  & > span {
    font-weight: 700;
    font-size: 16px;
  }
`;

export const SubscriptionInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const SubscriptionBox = styled.div`
  flex: 1;
  border-right: 1px solid ${(props) => props.theme.colors.STROKE};
  border-bottom: 1px solid ${(props) => props.theme.colors.STROKE};
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  min-width: 200px;
`;

export const PaymentMethodBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.STROKE};
  min-width: 200px;
`;

export const SubscriptionField = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
