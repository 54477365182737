import { createSearchParams, useLocation } from "react-router-dom";

import queryString from "query-string";
import { useCallback } from "react";

/**
 * Hook que utiliza `useLocation` y `query-string` para obtener los parámetros de consulta de una URL.
 * @returns {Object} Un objeto con los pares de clave-valor de los parámetros de consulta de la URL.
 */
export function useQueryParams() {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const updateParams = useCallback(
    (param, value) => {
      let newParams = { ...queryParams };
      if (value === null) {
        delete newParams[param];
      } else {
        newParams[param] = value;
      }

      return createSearchParams(newParams).toString();
    },
    [queryParams]
  );

  return [queryParams, updateParams];
}
