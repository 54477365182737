import { SortImage, StyledDataTable } from "../../components";

import { EmptyComponent } from "../../components/table/empty-table";

export const TransactionsGrid = (props) => {
  const columns = getTransactionsColumns();

  return (
    <StyledDataTable
      sortIcon={<SortImage />}
      columns={columns}
      data={props.data}
      defaultSortFieldId={"name"}
      defaultSortAsc={true}
      persistTableHead={true}
      noBorderLastRow={props.noBorderLastRow}
      noDataComponent={<EmptyComponent to={"#"} readonly entityName="registro" />}
    />
  );
};

const getTransactionsColumns = () => {
  return [
    {
      id: "id",
      name: "Transacción",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      id: "order",
      name: "Orden",
      selector: (row) => row.order,
      sortable: true,
    },
    {
      id: "date",
      name: "Fecha",
      selector: (row) => row.date,
      sortable: true,
    },

    {
      id: "subject",
      name: "Descripción",
      selector: (row) => row.subject,
      sortable: true,
    },
    {
      id: "amount",
      name: "Monto",
      selector: (row) => `$${Number(row.amount).toLocaleString("es-CL")}`,
      sortable: true,
    },
    {
      id: "status",
      name: "Estado",
      selector: (row) => row.status,
      sortable: true,
    },
  ];
};
