import {
  getPublicAvailability,
  getPublicProfile,
  getPublicServices,
  publicCreateAppointment,
} from "../services/scheduling-service";

import { createSlice } from "@reduxjs/toolkit";
import { logout } from "../services/user-service";

const INITIAL_STATE = {
  data: {
    id: null,
    selectedType: null,
    selectedServices: [],
    profile: null,
    services: [],
    availability: {},
    date: null,
    time: null,
    durationMinutes: 0,
  },
  error: false,
  errorMessage: "",
};

const schedulingSlice = createSlice({
  name: "scheduling",
  initialState: INITIAL_STATE,
  reducers: {
    setProfileId: (state, action) => {
      state = { ...INITIAL_STATE };
      state.data = { ...INITIAL_STATE.data, id: action.payload };
      return state;
    },
    setSelectedService: (state, action) => {
      const index = state.data.services.findIndex((s) => s.id === action.payload.service);
      state.data.services[index].selected = action.payload.selected;
      state.data.durationMinutes = state.data.services
        .filter((service) => service.selected)
        .reduce((total, service) => {
          return total + service.durationMinutes;
        }, 0);
    },
    setDate: (state, action) => {
      state.data.dateString = action.payload.dateString;
      state.data.date = action.payload.date;
      state.data.time = undefined;
    },
    setTime: (state, action) => {
      state.data.time = action.payload.time;
    },
    setSelectedType: (state, action) => {
      state.data.selectedType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPublicProfile.fulfilled, (state, action) => {
      state.error = false;
      state.errorMessage = "";
      state.data = {
        ...state.data,
        profile: action.payload,
      };
    });
    builder.addCase(getPublicProfile.rejected, (state, action) => {
      state.error = true;
      state.errorMessage = action.error;
    });
    builder.addCase(getPublicServices.fulfilled, (state, action) => {
      state.error = false;
      state.errorMessage = "";
      state.data = {
        ...state.data,
        services: action.payload,
      };
    });
    builder.addCase(getPublicServices.rejected, (state, action) => {
      state.error = true;
      state.errorMessage = action.error;
    });
    builder.addCase(getPublicAvailability.fulfilled, (state, action) => {
      state.error = false;
      state.errorMessage = "";
      state.data = {
        ...state.data,
        availability: action.payload,
      };
    });
    builder.addCase(getPublicAvailability.rejected, (state, action) => {
      state.error = true;
      state.errorMessage = action.error;
    });
    builder.addCase(publicCreateAppointment.fulfilled, (state, action) => {
      state.data = {
        ...state.data,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
      };
    });
    builder.addCase(logout.fulfilled, () => {
      return INITIAL_STATE;
    });
  },
});

// actions

export const { setSelectedService, setProfileId, setDate, setTime, setSelectedType } = schedulingSlice.actions;

// selectors
export const selectScheduling = (state) => state.scheduling.data;

export const selectPublicService = (id) => (state) => {
  const [service] = state.scheduling.data.services.filter((service) => service.id === id);
  return service;
};

export const selectScheduledServices = () => (state) => {
  return state.scheduling.data.services.filter((s) => s.selected);
};

export const selectAvailability = () => (state) => {
  return state.scheduling.data.availability;
};

export default schedulingSlice.reducer;
