import {
  NotificationBox,
  NotificationDate,
  NotificationMessage,
  NotificationsCloseIcon,
  NotificationsContainer,
  NotificationsContent,
  NotificationsSection,
  NotificationsTitle,
  NotificationsTitleContainer,
} from "./styles";
import { getUnixTime, startOfToday, toDate } from "date-fns";
import { useMemo, useRef } from "react";

import { CSSTransition } from "react-transition-group";
import { formatShortDistance } from "../../helpers/date-time";
import { routes } from "../../navigation/routes";
import { useNavigate } from "react-router-dom";
import useOutsideClick from "../../hooks/use-outside-click";

export const Notifications = ({ notifications, open, onClose }) => {
  const nodeRef = useRef(null);
  const navigate = useNavigate();
  useOutsideClick(nodeRef, "notifs-slide-enter-done", onClose);

  // actions
  const onNotificationClick = () => {
    onClose();
    navigate(routes.history("pending"));
  };

  // split notifications
  const [today, previous] = useMemo(() => {
    const todayNotifications = [];
    const previousNotifications = [];
    const todayTimestamp = getUnixTime(startOfToday());

    notifications.forEach((notification) => {
      if (notification.timestamp >= todayTimestamp) {
        todayNotifications.push(notification);
      } else {
        previousNotifications.push(notification);
      }
    });

    const sortedTodayNotifications = todayNotifications.sort((a, b) => b.timestamp - a.timestamp);
    const sortedPreviousNotifications = previousNotifications.sort((a, b) => b.timestamp - a.timestamp);

    return [sortedTodayNotifications, sortedPreviousNotifications];
  }, [notifications]);

  return (
    <CSSTransition nodeRef={nodeRef} in={open} timeout={500} classNames="notifs-slide" unmountOnExit>
      <NotificationsContainer>
        <NotificationsContent ref={nodeRef}>
          <NotificationsTitleContainer>
            <NotificationsTitle>Notificaciones</NotificationsTitle>
            <NotificationsCloseIcon onClick={onClose} />
          </NotificationsTitleContainer>
          {today.length > 0 && (
            <>
              <NotificationsSection>Hoy</NotificationsSection>
              {today.map((notification, index) => (
                <Notification
                  key={index}
                  message={notification.message}
                  timestamp={notification.timestamp}
                  onNotificationClick={onNotificationClick}
                />
              ))}
            </>
          )}
          {previous.length > 0 && (
            <>
              <NotificationsSection>Previas</NotificationsSection>
              {previous.map((notification, index) => (
                <Notification
                  key={index}
                  message={notification.message}
                  timestamp={notification.timestamp}
                  onNotificationClick={onNotificationClick}
                />
              ))}
            </>
          )}
        </NotificationsContent>
      </NotificationsContainer>
    </CSSTransition>
  );
};

const Notification = ({ message, timestamp, onNotificationClick }) => {
  const time = useMemo(() => {
    return formatShortDistance(toDate(timestamp));
  }, [timestamp]);
  return (
    <NotificationBox onClick={onNotificationClick}>
      <NotificationMessage>{message}</NotificationMessage>
      <NotificationDate>{time}</NotificationDate>
    </NotificationBox>
  );
};
