import styled, { css } from "styled-components";

import { Icon } from "../icon";
import { NavLink } from "react-router-dom";

export const SidebarContainer = styled.nav`
  width: 270px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.WHITE};
  border-right: 1px solid ${(props) => props.theme.colors.STROKE};
  @media (max-width: 918px) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    max-width: 400px;
    left: ${({ open }) => (open ? "0" : "-100%")};
    opacity: ${({ open }) => (open ? "1" : "0")};
    transition: left 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
`;

export const Logo = styled.img`
  height: auto;
  width: 182px;
  display: block;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
`;

export const CloseIcon = styled(Icon).attrs((props) => ({
  name: "fi-rr-cross-small",
  color: props.theme.colors.TEXT_DARK,
}))`
  display: none;
  @media (max-width: 918px) {
    display: block;
  }
  cursor: pointer;
`;

export const Items = styled.div`
  margin-top: 17px;
`;

export const BottomItems = styled.div`
  margin-bottom: 17px;
`;

const ItemStyle = css`
  background-color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 13px 30px;
  border: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.TEXT_DARK};

  &.active {
    color: ${(props) => props.theme.colors.PRIMARY};
    font-weight: 800;
  }

  & > .label {
    margin-left: 26px;
  }

  &:focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.FOCUS_OUTLINE};
    outline-offset: -4px;
  }

  ${(props) =>
    !props.$enabled &&
    `color:${props.theme.colors.DISABLED};
    cursor:default;`}
`;

export const SidebarLink = styled(NavLink)`
  ${ItemStyle}
`;

export const SidebarButton = styled.button`
  ${ItemStyle}
`;
