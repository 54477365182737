import { FormButton, FormError, FormLabel, FormLink, FormRoot, FormTitle, Input } from "../../components";
import { FormContainer, ImageContainer, SignupContainer, SignupImage } from "./styles";

import { CheckboxField } from "../../components/checkbox";
import { Formik } from "formik";
import { GridSpanTwo } from "../../components/column/styles";
import loginBanner from "../../assets/images/login-banner.png";
import { routes } from "../../navigation/routes";
import { signupScheme } from "../../helpers/schemas";
import { signupWithEmailAndPassword } from "../../services/user-service";
import { useDispatch } from "react-redux";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  password: "",
  confirmPassword: "",
  acceptTerms: false,
};

export const SignupScreen = () => {
  const dispatch = useDispatch();

  const onSubmit = async (data, formik) => {
    const result = await dispatch(
      signupWithEmailAndPassword({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        password: data.password,
      })
    );

    if (result.type === "user/signup/rejected") {
      formik.setErrors(getSignupServerError(result));
    }
    if (result.type === "user/signup/fulfilled") {
      // role-based-redirector maneja la ruta apropiada para el signup
      formik.resetForm();
    }
  };

  return (
    <SignupContainer>
      <ImageContainer>
        <SignupImage src={loginBanner} alt="Login banner" />
      </ImageContainer>
      <FormContainer>
        <FormTitle primary>Registrate como profesional</FormTitle>
        <Formik initialValues={initialValues} validationSchema={signupScheme} onSubmit={onSubmit}>
          <FormRoot columns={2}>
            <div>
              <FormLabel htmlFor="firstName">Nombre</FormLabel>
              <Input id="firstName" name="firstName" type="text" placeholder="Ingrese tu nombre" />
              <FormError name="firstName" />
            </div>
            <div>
              <FormLabel htmlFor="lastName">Apellido</FormLabel>
              <Input id="lastName" name="lastName" type="text" placeholder="Ingrese tu apellido" />
              <FormError name="lastName" />
            </div>
            <div>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input id="email" name="email" type="text" placeholder="Ingrese tu email" />
              <FormError name="email" />
            </div>
            <div>
              <FormLabel htmlFor="phone">Teléfono</FormLabel>
              <Input id="phone" name="phone" type="text" placeholder="Ingrese tu teléfono" />
              <FormError name="phone" />
            </div>
            <div>
              <FormLabel htmlFor="password">Contraseña</FormLabel>
              <Input id="password" name="password" type="password" placeholder="Crea tu contraseña" />
              <FormError name="password" />
            </div>
            <div>
              <FormLabel htmlFor="confirmPassword">Repetir contraseña</FormLabel>
              <Input id="confirmPassword" name="confirmPassword" type="password" placeholder="Repetí tu contraseña" />
              <FormError name="confirmPassword" />
            </div>
            <GridSpanTwo>
              <CheckboxField name="acceptTerms">
                He leído y acepto <b>términos y condiciones</b>
              </CheckboxField>
              <FormError name="acceptTerms" />
            </GridSpanTwo>
            <GridSpanTwo style={{ marginTop: 10 }}>
              <div>
                <FormError showUntouchedErrors name="generic" />
              </div>
              <FormButton type="submit">Registrarse como profesional</FormButton>
            </GridSpanTwo>
            <GridSpanTwo>
              <FormLabel fontWeight={400} style={{ textAlign: "center" }}>
                ¿Ya tienes una cuenta? <FormLink to={routes.login()}>Iniciar sesión</FormLink>
              </FormLabel>
            </GridSpanTwo>
          </FormRoot>
        </Formik>
      </FormContainer>
    </SignupContainer>
  );
};

const getSignupServerError = (result) => {
  let errors = {};

  switch (result.error.code) {
    case "auth/email-already-exists":
    case "auth/email-already-in-use":
      errors.email = "Esta dirección de correo electrónico ya está en uso por otra cuenta";

      break;
    default:
      errors.generic = result.error.message;
  }
  return errors;
};
