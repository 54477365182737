import { FormButton, FormError, FormLabel, FormRoot, FormTitle, Input } from "../../components";
import { FormContainer, PasswordResetContainer } from "./styles";

import { Formik } from "formik";
import { passwordResetScheme } from "../../helpers/schemas";
import { sendPasswordRecoverEmail } from "../../services/user-service";
import { useDispatch } from "react-redux";

const initialValues = {
  email: "",
};

export const PasswordResetScreen = () => {
  const dispatch = useDispatch();

  const onSubmit = async (data, formik) => {
    const result = await dispatch(sendPasswordRecoverEmail(data.email));

    if (result.type === "user/password-reset/rejected") {
      formik.setErrors(getResetPasswordServerError(result));
    }
    if (result.type === "user/password-reset/fulfilled") {
      formik.setStatus({
        sent: true,
      });
    }
  };

  return (
    <PasswordResetContainer>
      <FormContainer>
        <FormTitle primary>Recuperación de contraseña</FormTitle>

        <Formik initialValues={initialValues} validationSchema={passwordResetScheme} onSubmit={onSubmit}>
          {({ status }) => (
            <FormRoot columns={1}>
              {status?.sent ? (
                <p style={{ margin: 0 }}>
                  Se ha enviado un enlace de recuperación de contraseña a la dirección de correo electrónico que
                  proporcionaste. Por favor, revisa tu bandeja de entrada y sigue las instrucciones para restablecer tu
                  contraseña. Si no recibiste el correo electrónico, revisa tu carpeta de correo no deseado o spam. Si
                  sigues teniendo problemas para acceder a tu cuenta, contáctanos para obtener ayuda.
                </p>
              ) : (
                <>
                  <p style={{ margin: 0 }}>
                    Ingresá la dirección de correo electrónico asociada con tu cuenta y te enviaremos un correo
                    electrónico con un link para restablecer tu contraseña
                  </p>
                  <div>
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <Input id="email" name="email" type="text" placeholder="Ingrese tu email" />
                    <FormError name="email" />
                  </div>
                  <div>
                    <FormError showUntouchedErrors name="generic" />
                  </div>
                  <FormButton type="submit">Enviar link de recuperación</FormButton>
                </>
              )}
            </FormRoot>
          )}
        </Formik>
      </FormContainer>
    </PasswordResetContainer>
  );
};

const getResetPasswordServerError = (result) => {
  let errors = {};

  switch (result.error.code) {
    case "auth/user-not-found":
      errors.email =
        "No se ha encontrado ninguna cuenta de usuario con esa dirección de correo electrónico. " +
        "Por favor, revisa que la dirección de correo electrónico ingresada sea correcta o crea una cuenta nueva si aún no tienes una";

      break;
    case "auth/too-many-requests":
      errors.generic =
        "Se ha excedido el límite de intentos de recuperación de contraseña. " +
        "Por favor, espera unos minutos antes de volver a intentarlo.";

      break;
    default:
      errors.generic = result.error.message;
  }
  return errors;
};
