import { addDoc, collection, deleteDoc, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { database, storage } from "../firebase";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUniqueFilename } from "../helpers/file";

export const getServices = createAsyncThunk("service/get", async (_, { getState }) => {
  const state = getState();
  const user = state.user.data;
  const uid = user.uid;

  const servicesRef = collection(database, "services");
  const servicesQuery = query(servicesRef, where("createdBy", "==", uid));

  const result = await getDocs(servicesQuery);
  return result.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
});

export const createService = createAsyncThunk("service/create", async (data, { getState }) => {
  const state = getState();
  const user = state.user.data;
  const uid = user.uid;

  const collectionRef = collection(database, "services");

  const serviceData = {
    name: data.name,
    price: data.price,
    currency: data.currency,
    status: data.status,
    description: data.description,
    durationMinutes: data.durationMinutes,
    photos: data.photos,
    type: data.type,
    createdBy: uid,
  };

  const result = await addDoc(collectionRef, serviceData);

  return { ...serviceData, id: result.id };
});

export const uploadServicePhotos = createAsyncThunk(
  "service/upload-photos",
  async ({ filesOrURLs, id }, { getState }) => {
    const state = getState();
    const user = state.user.data;
    const services = state.services.data;
    let currentService = null;

    // find updated service
    if (services && id) {
      currentService = services.find((service) => service.id === id);
    }

    const uid = user.uid;

    const uploadPhotos = async (file) => {
      return new Promise(async (resolve, reject) => {
        try {
          const fileName = getUniqueFilename(file);
          const fileRef = ref(storage, `users/${uid}/services/${fileName}`);
          const result = await uploadBytes(fileRef, file);
          const downloadLink = await getDownloadURL(result.ref);
          resolve(downloadLink);
        } catch (error) {
          reject(error);
        }
      });
    };

    const removePhoto = async (file) => {
      return new Promise(async (resolve, reject) => {
        try {
          const fileRef = ref(storage, file);
          await deleteObject(fileRef);

          resolve();
        } catch (error) {
          reject(error);
        }
      });
    };

    const fileURLs = [];
    const filesToUpload = [];

    // remove files if needed
    const missingElements = !currentService
      ? []
      : currentService.photos.filter((element) => !filesOrURLs.includes(element));
    if (missingElements.length > 0) {
      const filesToRemove = missingElements.map(removePhoto);
      await Promise.all(filesToRemove);
    }

    // chequea si hay archivos para subir filtrando por tipo File
    filesOrURLs.forEach((fileOrURL) => {
      if (fileOrURL instanceof window.File) {
        filesToUpload.push(fileOrURL);
      }
      if (typeof fileOrURL === "string") {
        fileURLs.push(fileOrURL);
      }
    });
    // sube los nuevos archivos, reemplazando los anteriores
    if (filesToUpload.length > 0) {
      const uploadTasks = filesToUpload.map(uploadPhotos);
      const newFileURLs = await Promise.all(uploadTasks);
      return [...fileURLs, ...newFileURLs];
    }

    return fileURLs;
  }
);

export const deleteService = createAsyncThunk("service/delete", async (id, { getState }) => {
  const state = getState();
  const services = state.services.data;

  try {
    const service = services.find((serv) => serv.id === id);
    if (!service) {
      return;
    }
    // try to delete images
    // or continue
    const deletePhotos = async (url) => {
      return new Promise(async (resolve, reject) => {
        try {
          const fileRef = ref(storage, url);
          const result = await deleteObject(ref(storage, url));
          resolve(result);
        } catch (error) {
          reject(error);
        }
      });
    };
    const deleteTasks = service.photos.map(deletePhotos);
    await Promise.all(deleteTasks);
  } catch (error) {
    console.error("Error deleting images:", error);
  }

  // delete service
  const documentRef = doc(database, "services", id);
  await deleteDoc(documentRef);

  return id;
});

export const updateService = createAsyncThunk("service/update", async (args) => {
  const { id, ...data } = args;

  const documentRef = doc(database, "services", id);

  await updateDoc(documentRef, data);

  return args;
});
