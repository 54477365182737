const fonts = Object.freeze({
  NOTO_SANS: "'Noto Sans', sans-serif",
});

export const theme = Object.freeze({
  fonts,
  colors: {
    PRIMARY: "#DF2EFF",
    SECONDARY: "#3FB8AF",
    WHITE: "#FFFFFF",
    BLACK: "#000000",
    TEXT_DARK: "#3B3E45",
    TEXT_MEDIUM: "#9d9ea2",
    TEXT_LIGHT: "#F8F9FD",
    STROKE: "#DCDFE3",
    FOCUS_OUTLINE: "#3FB8AF99",
    LIGHT_BACKGROUND: "#F8F9FD",
    UNAVAILABLE: "#F0F1F5",
    OVERLAY: "#1826464d",
    DISABLED: "#3b3e454d",
    ERROR: "#FF4136",
    DELETE: "#FF4136",
    PRESSED: "#c829e5",
  },
});
