import {
  AvatarWithPlaceholder,
  LeftColumnWithPlaceholder,
  ProfileCard,
  ProfileDescription,
  ProfileError,
  ProfileFooterContainer,
  ProfileFooterContent,
  ProfileHeader,
  ProfileImage,
  ProfileImageContainer,
  ProfileImages,
  ProfileName,
  ProfileScreenContainer,
  ProfileText,
  RightColumnWithPlaceholder,
  SelectContainer,
  ServiceCard,
  ServiceHeader,
  ServiceLink,
  StyledGridTwoColumns,
} from "./styles";
import { FormActions, FormButton, FormLabel, FormParagraph, Icon } from "../../components";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { ServiceDescription, ServiceInfo, ServiceInfoField, ServiceTitle } from "../../components/services/styles";
import { getPublicProfile, getPublicServices } from "../../services/scheduling-service";
import { getServiceDuration, getServicePrice, getServiceTypeName } from "../../helpers/format";
import { selectScheduling, setProfileId, setSelectedService, setSelectedType } from "../../slices/scheduling-slice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";

import { Checkbox } from "../../components/checkbox";
import Placeholder from "react-placeholder";
import { Select } from "../../components/select";
import { routes } from "../../navigation/routes";
import { serviceTypes } from "../../helpers/consts";
import { theme } from "../../styles/theme";

export const PublicProfileScreen = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scheduling = useSelector(selectScheduling);

  let { id, profile, services, selectedType } = scheduling || {};

  // format fields
  const profileFields = useMemo(() => formatUserFields(profile), [profile]);
  const filteredServices = useMemo(() => fitlerServices(services, selectedType), [services, selectedType]);
  const anySelected = useMemo(() => services?.some((s) => s.selected), [services]);

  // errors
  const compatibleTypes = useMemo(() => {
    return services.every(
      (service) => !service.selected || (service.selected && service.type.some((t) => t === selectedType))
    );
  }, [services, selectedType]);

  useEffect(() => {
    setTimeout(() => {
      if (id !== params.id) {
        dispatch(setProfileId(params.id));
        dispatch(getPublicProfile(params.id));
        dispatch(getPublicServices(params.id));
      }
    });
  }, [id, params.id]);

  // pre select existing service type
  useEffect(() => {
    if (!selectedType) {
      const preSelectedType = getPreSelectedType(services);
      dispatch(setSelectedType(preSelectedType));
    }
  }, [selectedType, services]);

  // actions
  const onServiceCheckChange = (id, checked) => {
    dispatch(
      setSelectedService({
        service: id,
        selected: checked,
      })
    );
  };

  const onSelectType = (type) => {
    dispatch(setSelectedType(type));
  };

  const onContinue = () => {
    navigate(routes.publicScheduling(params.id));
  };

  return (
    <>
      <ProfileScreenContainer>
        <ProfileCard>
          <ProfileHeader>
            <AvatarWithPlaceholder
              size={128}
              ready={profile}
              src={profileFields.profileImage}
              alt={profileFields.fullName}
            />
            <LeftColumnWithPlaceholder ready={profile} style={{ minWidth: 155 }}>
              <ProfileName>{profileFields.fullName}</ProfileName>
              <ProfileText>
                <Icon name="fi-rr-user" size={13} color={theme.colors.TEXT_DARK} />
                {profileFields.categories}
              </ProfileText>
              <ProfileText>
                <Icon name="fi-rr-marker" size={13} color={theme.colors.TEXT_DARK} />
                {profileFields.fullAddress}
              </ProfileText>
            </LeftColumnWithPlaceholder>
            <RightColumnWithPlaceholder ready={profile}>
              <ProfileDescription>Descripción</ProfileDescription>
              <ProfileText>{profileFields.description}</ProfileText>
            </RightColumnWithPlaceholder>
          </ProfileHeader>
          <ProfileImages>
            {profile?.portfolio.map((photo, index) => (
              <ProfileImageContainer key={index}>
                <ProfileImage
                  hideDownload={true}
                  hideZoom={true}
                  large={photo}
                  small={photo}
                  alt={`${profileFields.fullName}#${index}`}
                />
              </ProfileImageContainer>
            ))}
            <ImageCollectionPlaceholder profile={profile} />
          </ProfileImages>
        </ProfileCard>
        <SelectContainer>
          <FormLabel>Tipo de servicio</FormLabel>
          <Select value={selectedType} items={serviceTypes} onValueChange={onSelectType} />
        </SelectContainer>
        {!compatibleTypes && (
          <ProfileError>
            <Icon name="fi-rr-info" size={14} />
            Seleccionaste uno o más servicios de otro tipo. Para continuar elige uno o más servicios del mismo tipo.
          </ProfileError>
        )}

        <StyledGridTwoColumns>
          {filteredServices?.map((service) => (
            <ServiceCard key={service.id}>
              <ServiceHeader>
                <ServiceLink to={routes.publicService(params.id, service.id)}>
                  <ServiceTitle>{service.name}</ServiceTitle>
                </ServiceLink>
                <Checkbox
                  style={{ alignSelf: "flex-start" }}
                  checked={service.selected}
                  onCheckedChange={(checked) => {
                    onServiceCheckChange(service.id, checked);
                  }}
                />
              </ServiceHeader>
              <ServiceInfo>
                <ServiceInfoField>
                  <Icon name="fi-rr-money" size={20} color={"#3B3E45"} />
                  <FormParagraph>{getServicePrice(service.price, "CLP")}</FormParagraph>
                </ServiceInfoField>
                <ServiceInfoField>
                  <Icon name="fi-rr-time-oclock" size={18} color={"#3B3E45"} />
                  <FormParagraph>{getServiceDuration(service.durationMinutes)}</FormParagraph>
                </ServiceInfoField>
                <ServiceInfoField>
                  <Icon name="fi-rr-building" size={18} color={"#3B3E45"} />
                  <FormParagraph>{getServiceTypeName([selectedType])}</FormParagraph>
                </ServiceInfoField>
              </ServiceInfo>
              <FormLabel>Descripción</FormLabel>
              <ServiceDescription>{service.description}</ServiceDescription>
            </ServiceCard>
          ))}
        </StyledGridTwoColumns>
      </ProfileScreenContainer>
      <ProfileFooterContainer>
        <ProfileFooterContent>
          <FormActions>
            <FormButton disabled={!anySelected || !compatibleTypes} onClick={onContinue}>
              Siguiente
            </FormButton>
          </FormActions>
        </ProfileFooterContent>
      </ProfileFooterContainer>
      <Outlet />
    </>
  );
};

//
// Helpers
//
const formatUserFields = (profile) => {
  if (!profile) {
    return {
      fullName: "",
      categories: "",
      fullAddress: "",
      profileImage: "",
      description: "",
    };
  }
  const fullAddress = [profile.address, profile.other, profile.commune].filter((part) => !!part).join(", ");
  const profileImage = profile.profileImage;
  const fullName = `${profile.firstName} ${profile.lastName}`;
  const categories = profile.categories.join(", ");
  const description = profile.description;

  return {
    fullName,
    categories,
    fullAddress,
    profileImage,
    description,
  };
};

const fitlerServices = (services, selectedType) => {
  if (!services) {
    return [];
  }
  return services.filter((service) => {
    return service.type.some((s) => s === selectedType);
  });
};

const ImageCollectionPlaceholder = ({ profile }) => {
  if (profile) {
    return null;
  }
  return [1, 2, 3, 4, 5, 6, 7].map((_, index) => (
    <ProfileImageContainer key={index}>
      <Placeholder type="rect" ready={profile} color={"#f2f3f7"} style={{ width: 174, height: 101 }} />
    </ProfileImageContainer>
  ));
};

const getPreSelectedType = (services) => {
  if (!services) {
    return "in-person";
  }
  var typeCounter = {};
  services.forEach(function (servicio) {
    servicio.type.forEach((type) => {
      if (typeCounter[type]) {
        typeCounter[type]++;
      } else {
        typeCounter[type] = 1;
      }
    });
  });
  var selected;
  var maxS = 0;

  for (var tipo in typeCounter) {
    if (typeCounter[tipo] > maxS) {
      maxS = typeCounter[tipo];
      selected = tipo;
    }
  }

  return selected;
};
