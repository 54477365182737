import { GridThreeColumns } from "../../components/column/styles";
import styled from "styled-components";

export const SchedulingScreenContainer = styled.div`
  background-color: ${(props) => props.theme.colors.LIGHT_BACKGROUND};
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 48px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const StyledGridThreeColumns = styled(GridThreeColumns)`
  width: 100%;
  max-width: 1108px;
`;

export const SchedulingFooterContainer = styled.div`
  width: "100%";
  box-shadow: 5px 0px 13px rgba(0, 0, 0, 0.03);
  display: flex;
  justify-content: center;
  padding: 0px 20px;
  background-color: ${(props) => props.theme.colors.LIGHT_BACKGROUND};
`;
export const SchedulingFooterContent = styled.div`
  flex: 1;
  max-width: 1108px;
`;

export const ServiceSelect = styled.div`
  padding: 20px;
  padding-bottom: 0px;
`;

export const ServiceItem = styled.div`
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 10px 14px;
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => (props.selected ? props.theme.colors.SECONDARY : props.theme.colors.TEXT_DARK)};
  border: 1px solid ${(props) => (props.selected ? props.theme.colors.SECONDARY : props.theme.colors.STROKE)};
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  &:hover {
    background-color: #f0f0f0;
  }
`;

export const ServiceItemName = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ServiceDetail = styled.div`
  display: flex;
  font-weight: 400;
  margin-top: 4px;
  .icon {
    margin-right: 4px;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
`;

export const SlotsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  padding: 16px;
`;

export const SlotItem = styled.div`
  display: inline-block;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.colors.STROKE};
  border-radius: 4px;
  padding: 10px 14px;
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => (props.selected ? props.theme.colors.SECONDARY : props.theme.colors.TEXT_DARK)};
  font-weight: ${(props) => (props.selected ? 700 : 400)};
  border: 1px solid ${(props) => (props.selected ? props.theme.colors.SECONDARY : props.theme.colors.STROKE)};
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  &:hover {
    background-color: #f0f0f0;
  }
`;

export const SchedulingError = styled.div`
  display: flex;
  margin-top: 6px;
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.ERROR};
  font-size: 13px;
  width: 100%;
  max-width: 1108px;
  padding: 16px 0;
  font-weight: 600;
  align-items: center;
  .icon {
    margin-right: 4px;
  }
`;
