import styled from "styled-components";

export const PageContainer = styled.main`
  flex: 1;
  background-color: ${(props) => props.theme.colors.LIGHT_BACKGROUND};
  padding: 50px;
  overflow-y: scroll;
  @media (max-width: 918px) {
    padding: 26px;
  }
`;
