import { TableRowMenuLink } from "../../components";
import styled from "styled-components";

export const ToggleSvg = (props) => (
  <svg width={24} height={15} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={1} y={1} width={22} height={13} rx={6.5} stroke="#3B3E45" strokeWidth={2} />
    <circle cx={7.5} cy={7.5} r={4.5} fill="#3B3E45" />
  </svg>
);

export const CustomTableRowMenuLink = styled(TableRowMenuLink)`
  &:hover rect {
    stroke: ${(props) => props.theme.colors.SECONDARY};
  }
  &:hover circle {
    fill: ${(props) => props.theme.colors.SECONDARY};
  }
`;
