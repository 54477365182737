import { CardBody, CardClose, CardTitle, Icon, Modal } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { ClientForm } from "./client-form";
import { ClientFormContainer } from "./styles";
import { clientDataScheme } from "../../helpers/schemas";
import { selectClient } from "../../slices/clients-slice";
import { updateClient } from "../../services/clients-service";

export const EditClientModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const client = useSelector(selectClient(params?.id));

  const onSubmit = async (data, formik) => {
    const result = await dispatch(
      updateClient({
        id: params?.id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        commune: data.commune,
        address: data.address,
        postalCode: data.postalCode,
        other: data.other,
      })
    );

    if (result.type === "clients/update/rejected") {
      formik.setErrors({
        generic: result.error.message,
      });
    }
    if (result.type === "clients/update/fulfilled") {
      navigate(-1);
    }
  };
  const onModalClose = () => {
    navigate(-1);
  };
  return (
    <Modal expand onClose={onModalClose}>
      <CardTitle>
        Editar Cliente
        <CardClose onClick={onModalClose} />
      </CardTitle>
      <CardBody $borderBottom $roundedBottom>
        <ClientFormContainer>
          <ClientForm
            initialValues={client}
            validationSchema={clientDataScheme}
            onSubmit={onSubmit}
            onCancel={onModalClose}
          />
        </ClientFormContainer>
      </CardBody>
    </Modal>
  );
};
