import { useNavigate, useParams } from "react-router-dom";

import { AlertModal } from "../../components";
import { deleteService } from "../../services/services-service";
import { useDispatch } from "react-redux";

export const DeleteServiceModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const onAccept = async () => {
    const result = await dispatch(deleteService(params.id));

    if (result.type === "service/delete/rejected") {
    }
    if (result.type === "service/delete/fulfilled") {
      navigate(-1);
    }
  };
  const onModalClose = () => {
    navigate(-1);
  };

  return (
    <AlertModal
      title="¿Estas seguro?"
      message="Se eliminará este servicio"
      onModalClose={onModalClose}
      onCancel={onModalClose}
      onAccept={onAccept}
    />
  );
};
