import "./firebase";

import { AccountLayout, PublicLayout, SetupLayout } from "./layouts";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  ClientsScreen,
  HistoryScreen,
  LandingScreen,
  LoginScreen,
  MessagesScreen,
  NotificationsScreen,
  PasswordResetScreen,
  PasswordUpdateScreen,
  ScheduleScreen,
  ServicesScreen,
  SetupAccountScreen,
  SetupProfileScreen,
  SetupServicesScreen,
  SignupScreen,
} from "./screens";
import { persistor, store } from "./store";

import { AccountTab } from "./screens/user/user-account-tab";
import { AddServiceModal } from "./modals/service/service-add-modal";
import { ApproveAppointmentModal } from "./modals/appointment/approve-appointment-modal";
import { ChangeServiceStatusModal } from "./modals/service/service-status-modal";
import { DeleteClientModal } from "./modals/clients/delete-client-modal";
import { DeleteServiceModal } from "./modals/service/service-delete-modal";
import { DeleteTimeoffModal } from "./modals/timeoff/delete-timeoff-modal";
import { EditClientModal } from "./modals/clients/edit-client-modal";
import { EditServiceModal } from "./modals/service/service-edit-modal";
import { GlobalStyles } from "./styles/global-styles";
import { LoaderDialog } from "./components/loader";
import { LoaderScreen } from "./screens/loader/loader-screen";
import { PersistGate } from "redux-persist/integration/react";
import { ProfileTab } from "./screens/user/user-profile-tab";
import { Provider } from "react-redux";
import { PublicClientDataScreen } from "./screens/public-client-data/public-client-data-screen";
import { PublicOrderSuccess } from "./screens/public-order-success/public-order-success-screen";
import { PublicProfileScreen } from "./screens/public-profile/public-profile-screen";
import { PublicSchedulingScreen } from "./screens/public-scheduling/public-scheduling-screen";
import { RejectAppointmentModal } from "./modals/appointment/reject-appointment-modal";
import { RepeatAppointment } from "./modals/appointment/repeat-appointment";
import { RoleBasedRedirect } from "./navigation/redirect";
import { ScheduleAppointment } from "./modals/appointment/schedule-appointment";
import { ScheduleTab } from "./screens/user/user-schedule-tab";
import { ShareProfileModal } from "./modals/share-profile/share-profile";
import { SubscriptionTab } from "./screens/user/user-subscription-tab";
import { ThemeProvider } from "styled-components";
import { TimeOffModal } from "./modals/timeoff/schedule-timeoff";
import { UpdateAppointmentModal } from "./modals/appointment/update-appointment-modal";
import { UserTabsScreen } from "./screens/user/user-screen";
import { ViewAppointmentModal } from "./modals/appointment/view-appointment-modal";
import { ViewServiceModal } from "./modals/service/service-view-modal";
import { routes } from "./navigation/routes";
import { theme } from "./styles/theme";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Provider store={store}>
        <PersistGate loading={<LoaderDialog />} persistor={persistor}>
          <BrowserRouter>
            <Routes>
              <Route element={<RoleBasedRedirect />}>
                <Route element={<PublicLayout />}>
                  <Route path={routes.root()} element={<LandingScreen />} />
                  <Route path={routes.login()} element={<LoginScreen />} />
                  <Route path={routes.signup()} element={<SignupScreen />} />
                  <Route path={routes.passwordReset()} element={<PasswordResetScreen />} />
                  <Route path={routes.passwordUpdate()} element={<PasswordUpdateScreen />} />
                  <Route path={routes.publicProfile()} element={<PublicProfileScreen />}>
                    <Route path={routes.publicService()} element={<ViewServiceModal publicSource />} />
                  </Route>
                  <Route path={routes.publicScheduling()} element={<PublicSchedulingScreen />} />
                  <Route path={routes.publicClientData()} element={<PublicClientDataScreen />} />
                  <Route path={routes.publicOrderSuccess()} element={<PublicOrderSuccess />} />
                </Route>
                <Route element={<SetupLayout />}>
                  <Route path={routes.setupAccount()} element={<SetupAccountScreen />} />
                  <Route path={routes.setupProfile()} element={<SetupProfileScreen />} />
                  <Route path={routes.setupServices()} element={<SetupServicesScreen />}>
                    <Route path={routes.setupAddService()} element={<AddServiceModal />} />
                    <Route path={routes.setupEditService()} element={<EditServiceModal />} />
                    <Route path={routes.setupViewService()} element={<ViewServiceModal />} />
                    <Route path={routes.setupDeleteService()} element={<DeleteServiceModal />} />
                    <Route path={routes.setupSetServiceStatus()} element={<ChangeServiceStatusModal />} />
                  </Route>
                </Route>
                <Route element={<AccountLayout />}>
                  <Route element={<UserTabsScreen />}>
                    <Route path={routes.userAccount()} element={<AccountTab />} />
                    <Route path={routes.userProfile()} element={<ProfileTab />} />
                    <Route path={routes.userSchedule()} element={<ScheduleTab />} />
                    <Route path={routes.userSubscription()} element={<SubscriptionTab />} />
                  </Route>
                  <Route path={routes.clients()} element={<ClientsScreen />}>
                    <Route path={routes.editClient()} element={<EditClientModal />} />
                    <Route path={routes.deleteClient()} element={<DeleteClientModal />} />

                    <Route path={routes.clientAppointment()} element={<ScheduleAppointment />} />
                  </Route>
                  <Route path={routes.history()} element={<HistoryScreen />}>
                    <Route path={routes.historyRepeat()} element={<RepeatAppointment />} />
                    <Route path={routes.historyApprove()} element={<ApproveAppointmentModal />} />
                    <Route path={routes.historyReject()} element={<RejectAppointmentModal />} />
                    <Route path={routes.historyUpdate()} element={<UpdateAppointmentModal />} />
                    <Route path={routes.historyView()} element={<ViewAppointmentModal />} />
                  </Route>
                  <Route path={routes.schedule()} element={<ScheduleScreen />}>
                    <Route path={routes.scheduleAppointment()} element={<ScheduleAppointment />} />
                    <Route path={routes.scheduleTimeoff()} element={<TimeOffModal />} />
                    <Route path={routes.deleteTimeoff()} element={<DeleteTimeoffModal />} />
                    <Route path={routes.scheduleViewAppointment()} element={<ViewAppointmentModal />} />
                  </Route>
                  <Route path={routes.messages()} element={<MessagesScreen />} />
                  <Route path={routes.services()} element={<ServicesScreen />}>
                    <Route path={routes.addService()} element={<AddServiceModal />} />
                    <Route path={routes.editService()} element={<EditServiceModal />} />
                    <Route path={routes.viewService()} element={<ViewServiceModal />} />
                    <Route path={routes.deleteService()} element={<DeleteServiceModal />} />
                    <Route path={routes.setServiceStatus()} element={<ChangeServiceStatusModal />} />
                  </Route>
                  <Route path={routes.shareProfile()} element={<ShareProfileModal />} />
                </Route>
              </Route>
            </Routes>
          </BrowserRouter>
          <LoaderScreen />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
