import { formatDistanceStrict, isWithinInterval, parse, subMinutes } from "date-fns";

import { es } from "date-fns/locale";

/**
 * Comprueba si la fecha está dentro de uno de los slots.
 * @returns {boolean} Devuelve true si la fecha está dentro de uno de los slots, de lo contrario devuelve false.
 */
export function isWithinRange(date, slots) {
  for (let i = 0; i < slots.length; i++) {
    if (isWithinSlot(date, slots[i])) {
      return true;
    }
  }
  return false;
}

/**
 * Comprueba si la fecha está dentro del intervalo especificado por el slot.
 * @returns {boolean} Devuelve true si la fecha está dentro del intervalo especificado por el slot, de lo contrario devuelve false.
 */
export function isWithinSlot(date, slot) {
  const startTime = parse(slot.start, "HH:mm", date);
  const endTime = subMinutes(parse(slot.end, "HH:mm", date), 1);

  const interval = { start: startTime, end: endTime };
  return isWithinInterval(date, interval);
}

/**
 * Helper para mostrar de forma reducida la distancia a una fecha especifica
 * @returns
 */
export function formatShortDistance(date) {
  const formatDistanceLocale = {
    lessThanXSeconds: "{{count}}s",
    xSeconds: "{{count}}s",
    halfAMinute: "30s",
    lessThanXMinutes: "{{count}}m",
    xMinutes: "{{count}}m",
    aboutXHours: "{{count}}h",
    xHours: "{{count}}h",
    xDays: "{{count}}d",
    aboutXWeeks: "{{count}}sem",
    xWeeks: "{{count}}sem",
    aboutXMonths: "{{count}}m",
    xMonths: "{{count}}m",
    aboutXYears: "{{count}}a",
    xYears: "{{count}}a",
    overXYears: "{{count}}a",
    almostXYears: "{{count}}a",
  };

  return formatDistanceStrict(new Date(date) * 1000, new Date(), {
    addSuffix: false,
    locale: {
      ...es,
      formatDistance: (token, count) => {
        return formatDistanceLocale[token].replace("{{count}}", count);
      },
    },
  });
}
