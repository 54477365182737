import styled, { css } from "styled-components";

import { Icon } from "../icon";

/* prettier-ignore */
const notificationsTransition =css`
  &.notifs-slide-enter {
    transform: translateX(-10%);
    opacity: 0;
  }
  &.notifs-slide-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  }
  &.notifs-slide-exit {
    transform: translateX(0);
    opacity: 1;
  }
  &.notifs-slide-exit-active {
    opacity: 0;
    transform: translateX(-10%);
    transition: transform 200ms ease-out, opacity 200ms ease-out;
  }
`;

export const NotificationsContainer = styled.nav`
  position: relative;
`;

export const NotificationsContent = styled.nav`
  ${notificationsTransition};
  position: absolute;
  height: 100vh;
  width: 328px;
  z-index: 10;
  border-right: 1px solid ${(props) => props.theme.colors.STROKE};
  background-color: ${(props) => props.theme.colors.WHITE};
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
  padding: 24px;
  box-sizing: border-box;
  overflow-y: auto;
  @media (max-width: 918px) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    max-width: 400px;
    z-index: 1001;
    border-radius: 0;
  }
`;

export const NotificationsTitle = styled.div`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.SECONDARY};
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
`;

export const NotificationsCloseIcon = styled(Icon).attrs((props) => ({
  name: "fi-rr-cross-small",
  color: props.theme.colors.TEXT_DARK,
}))`
  display: none;
  @media (max-width: 918px) {
    display: block;
  }
  cursor: pointer;
`;

export const NotificationsTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const NotificationsSection = styled.div`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-size: 16px;
  font-weight: 700;
  padding-top: 16px;
  margin-top: 16px;
  border-top: 1px solid ${(props) => props.theme.colors.STROKE};
`;

export const NotificationBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 15px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const NotificationMessage = styled.div`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  font-size: 14px;
  color: ${(props) => props.theme.colors.TEXT_DARK};
`;

export const NotificationDate = styled.div`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  font-size: 12px;
  color: ${(props) => props.theme.colors.TEXT_MEDIUM};
  flex-shrink: 0;
  margin-left: 6px;
`;
