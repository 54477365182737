import { Icon } from "../icon";
import styled from "styled-components";

export const MobileNavContainer = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const Logo = styled.img`
  height: auto;
  width: 126px;
  display: block;
`;

export const MobileMenuIcon = styled(Icon).attrs((props) => ({
  name: "fi-rr-menu-burger",
  color: props.theme.colors.PRIMARY,
}))`
  cursor: pointer;
`;
