import styled from "styled-components";

export const LoginContainer = styled.div`
  width: 100%;
  max-width: 1110px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
`;

export const FormContainer = styled.div`
  margin-top: 100px;
  margin-left: auto;
  width: 540px;
  height: 500px;
  padding: 14px 20px;

  @media (max-width: 768px) {
    margin-top: 20px;
    margin-right: auto;
  }
`;

export const LoginImage = styled.img`
  width: 100%;
  height: auto;
`;
export const ImageContainer = styled.div`
  flex: 1;
  max-width: 540px;
  margin-left: auto;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`;
