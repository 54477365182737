import { SortImage, StyledDataTable, TableRowMenuLink, TableRowMenuRoot } from "../../components";

import { AppointmentState } from "../../helpers/consts";
import { EmptyComponent } from "../../components/table/empty-table";
import es from "date-fns/locale/es"; // Importar el objeto de configuración para español
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { routes } from "../../navigation/routes";
import { useRowSelection } from "../../hooks/use-table";

export const HistoryGrid = (props) => {
  const [conditionalRowStyles, handleSelection] = useRowSelection();
  const columns = getHistoryColumns(handleSelection, props.type);

  return (
    <StyledDataTable
      sortIcon={<SortImage />}
      columns={columns}
      data={props.data}
      defaultSortFieldId={"date"}
      defaultSortAsc={false}
      onRowClicked={props.onRowClicked}
      persistTableHead={true}
      noBorderLastRow={props.noBorderLastRow}
      noDataComponent={<EmptyComponent entityName="registro" readonly />}
      conditionalRowStyles={conditionalRowStyles}
    />
  );
};

const TableRowMenu = (props) => {
  if (props.type === AppointmentState.PENDING) {
    return (
      <TableRowMenuRoot row={props.row} onSelectionChange={props.onSelectionChange}>
        <TableRowMenuLink to={routes.appendToPath(`approve/${props.row.id}`)} name="fi-rr-check" />
        <TableRowMenuLink to={routes.appendToPath(`reject/${props.row.id}`)} name="fi-rr-ban" />
        <TableRowMenuLink to={routes.appendToPath(`update/${props.row.id}`)} name="fi-rr-time-past" />
        <TableRowMenuLink to={routes.appendToPath(`view/${props.row.id}`)} name="fi-rr-eye" />
      </TableRowMenuRoot>
    );
  }
  if (props.type === AppointmentState.APPROVED) {
    return (
      <TableRowMenuRoot row={props.row} onSelectionChange={props.onSelectionChange}>
        <TableRowMenuLink to={routes.appendToPath(`reject/${props.row.id}`)} name="fi-rr-ban" />
        <TableRowMenuLink to={routes.appendToPath(`update/${props.row.id}`)} name="fi-rr-time-past" />
        <TableRowMenuLink to={routes.appendToPath(`view/${props.row.id}`)} name="fi-rr-eye" />
      </TableRowMenuRoot>
    );
  }
  if (props.type === AppointmentState.PAST) {
    return (
      <TableRowMenuRoot row={props.row} onSelectionChange={props.onSelectionChange}>
        <TableRowMenuLink to={routes.appendToPath(`repeat/${props.row.id}`)} name="fi-rr-refresh" />
        <TableRowMenuLink to={routes.appendToPath(`view/${props.row.id}`)} name="fi-rr-eye" />
      </TableRowMenuRoot>
    );
  }
  if (props.type === AppointmentState.REJECTED || props.type === AppointmentState.CANCELED) {
    return (
      <TableRowMenuRoot row={props.row} onSelectionChange={props.onSelectionChange}>
        <TableRowMenuLink to={routes.appendToPath(`view/${props.row.id}`)} name="fi-rr-eye" />
      </TableRowMenuRoot>
    );
  }
};

const getHistoryColumns = (onSelectionChange, type) => {
  return [
    {
      id: "name",
      name: "Nombre",
      selector: (row) => `${row.firstName} ${row.lastName}`,
      sortable: true,
    },
    {
      id: "date",
      name: "Fecha",
      selector: (row) => parseISO(row.startDate),
      format: (row) => format(parseISO(row.startDate), "dd MMMM yyyy", { locale: es }),
      sortable: true,
    },
    {
      id: "time",
      name: "Hora",
      selector: (row) => new parseISO(row.startDate),
      format: (row) => {
        const from = format(new parseISO(row.startDate), "HH:mm", { locale: es });
        const to = format(new parseISO(row.endDate), "HH:mm", { locale: es });
        return `${from} - ${to}`;
      },
      sortable: true,
    },
    {
      id: "services",
      name: "Servicios",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      id: "cell-menu",
      cell: (row) => <TableRowMenu row={row} onSelectionChange={onSelectionChange} type={type} />,
      allowOverflow: true,
      button: true,
      width: "48px",
    },
  ];
};
