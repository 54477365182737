import { CardBody, CardFooter, CardTitle, FormActions, FormButton, Icon } from "../../components";
import {
  DateContainer,
  SchedulingError,
  SchedulingFooterContainer,
  SchedulingFooterContent,
  SchedulingScreenContainer,
  ServiceDetail,
  ServiceItem,
  ServiceItemName,
  ServiceSelect,
  SlotItem,
  SlotsContainer,
  StyledGridThreeColumns,
} from "./styles";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { addMonths, differenceInMinutes, format, parse, parseISO } from "date-fns";
import {
  selectAvailability,
  selectScheduledServices,
  selectScheduling,
  setDate,
  setSelectedService,
  setTime,
} from "../../slices/scheduling-slice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";

import { DatePicker } from "../../components/date-time";
import { getPublicAvailability } from "../../services/scheduling-service";
import { getServiceDuration } from "../../helpers/format";
import { routes } from "../../navigation/routes";

export const PublicSchedulingScreen = () => {
  const params = useParams();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const scheduling = useSelector(selectScheduling);
  const scheduledServices = useSelector(selectScheduledServices());
  const availability = useSelector(selectAvailability());

  // reload availability
  useEffect(() => {
    setError("");
    dispatch(getPublicAvailability(params.id));
  }, []);

  const serviceSelectionAvailability = useMemo(
    () => getSlotsForServices(availability, scheduledServices, scheduling.dateString),
    [availability, scheduledServices, scheduling.dateString]
  );

  // scheduling window
  const dateRange = useMemo(() => {
    const today = new Date();
    const maxDate = addMonths(today, 3);
    return { today, maxDate };
  }, []);

  const excludedDays = useMemo(() => {
    return Object.keys(availability)
      .filter((day) => {
        return availability[day].length === 0;
      })
      .map((day) => parseISO(day));
  }, [availability]);

  // actions
  const onRemoveServiceClick = (id) => {
    dispatch(
      setSelectedService({
        service: id,
        selected: false,
      })
    );
  };

  const onSelectDateClick = (date) => {
    dispatch(
      setDate({
        dateString: format(date, "yyyy-MM-dd"),
        date: Number(date),
      })
    );
  };

  const onSelectTimeClick = (time) => {
    dispatch(setTime({ time }));
  };

  const onBackClick = () => {
    navigate(-1);
  };

  const onContinueClick = () => {
    if (!scheduling.date || !scheduling.time) {
      setError("Debes seleccionar fecha y hora para los servicios seleccionados.");
      return;
    } else {
      setError("");
    }
    navigate(routes.publicClientData(params.id), { replace: true });
  };

  return (
    <>
      <SchedulingScreenContainer>
        {error && (
          <SchedulingError>
            <Icon name="fi-rr-info" size={14} />
            {error}
          </SchedulingError>
        )}
        <StyledGridThreeColumns>
          <div style={{ minWidth: 300 }}>
            <CardTitle>
              <Icon name="fi-rr-hand-holding-heart" size={18} style={{ marginRight: 6 }} />
              Selecciona el servicio
            </CardTitle>
            <CardBody>
              <ServiceSelect>
                {scheduledServices.map((service) => (
                  <ServiceItem key={service.id} selected={false}>
                    <ServiceItemName>
                      {service.name}
                      <Icon name="fi-rr-trash" size={16} onClick={() => onRemoveServiceClick(service.id)} />
                    </ServiceItemName>

                    <ServiceDetail>
                      <Icon name="fi-rr-clock" size={14} />
                      {getServiceDuration(service.durationMinutes, false)}
                    </ServiceDetail>
                    {/*      {scheduling.date && (
                      <ServiceDetail>
                        <Icon name="fi-rr-calendar" size={14} />
                        {getServiceHumanDate(scheduling.date)}
                      </ServiceDetail>
                    )}
                    {scheduling.time && (
                      <ServiceDetail>
                        <Icon name="fi-rr-clock" size={14} />
                        {getServiceHumanDuration(scheduling.time, service.durationMinutes)}
                      </ServiceDetail>
                    )} */}
                  </ServiceItem>
                ))}
                <FormButton $type="outline" type="button" onClick={onBackClick}>
                  Agregar otro servicio
                </FormButton>
              </ServiceSelect>
            </CardBody>
            <CardFooter></CardFooter>
          </div>
          <div>
            <CardTitle>
              <Icon name="fi-rr-calendar" size={18} style={{ marginRight: 6 }} />
              Selecciona el día
            </CardTitle>
            <CardBody $borderBottom $roundedBottom>
              <DateContainer>
                <DatePicker
                  inline
                  borderless
                  value={scheduling.date}
                  excludeDates={excludedDays}
                  onValueChange={onSelectDateClick}
                  minDate={dateRange.today}
                  maxDate={dateRange.maxDate}
                />
              </DateContainer>
            </CardBody>
          </div>
          <div>
            <CardTitle>
              <Icon name="fi-rr-clock" size={18} style={{ marginRight: 6 }} />
              Selecciona la hora
            </CardTitle>
            <CardBody>
              <SlotsContainer>
                {serviceSelectionAvailability.map((slot) => (
                  <SlotItem key={slot} selected={scheduling.time === slot} onClick={() => onSelectTimeClick(slot)}>
                    {slot}
                  </SlotItem>
                ))}
              </SlotsContainer>
            </CardBody>
            <CardFooter></CardFooter>
          </div>
        </StyledGridThreeColumns>
      </SchedulingScreenContainer>
      <SchedulingFooterContainer>
        <SchedulingFooterContent>
          <FormActions>
            <FormButton $type="secondary" type="button" onClick={onBackClick}>
              Anterior
            </FormButton>
            <FormButton onClick={onContinueClick}>Siguiente</FormButton>
          </FormActions>
        </SchedulingFooterContent>
      </SchedulingFooterContainer>
      <Outlet />
    </>
  );
};

function getSlotsForServices(availability, services, dateString) {
  if (!services || !availability) {
    return [];
  }

  let totalDuration = services.reduce((total, service) => {
    return total + service.durationMinutes;
  }, 0);
  const dayAvailability = availability[dateString] || [];

  return dayAvailability.reduce((acc, slot, index) => {
    let minutes = 15;
    const curr = parse(slot, "HH:mm", new Date());
    for (let i = index + 1; i < dayAvailability.length; i++) {
      const next = parse(dayAvailability[i], "HH:mm", new Date());
      if (Math.abs(differenceInMinutes(curr, next)) === minutes) {
        minutes += 15;
      } else {
        break;
      }
    }
    if (minutes >= totalDuration) {
      acc.push(slot);
    }

    return acc;
  }, []);
}

/* const checkServicesOverlap = (services) => {
  return services.some((service, index) => {
    const otherServices = services.slice(0, index).concat(services.slice(index + 1));
    return otherServices.some((otherService) => {
      const serviceStart = parse(service.time, "HH:mm", service.date);
      const serviceEnd = addMinutes(serviceStart, service.durationMinutes);
      const otherServiceStart = parse(otherService.time, "HH:mm", otherService.date);
      const otherServiceEnd = addMinutes(otherServiceStart, otherService.durationMinutes);
      return areIntervalsOverlapping(
        {
          start: serviceStart,
          end: serviceEnd,
        },
        {
          start: otherServiceStart,
          end: otherServiceEnd,
        }
      );
    });
  });
};
 */
