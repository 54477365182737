import {
  BookContainer,
  ClientDataError,
  ClientDataLayout,
  ClientDataScreenContainer,
  PriceContainer,
  PriceRow,
  ServiceDetail,
  ServiceItem,
  ServiceSection,
} from "./styles";
import {
  CardBody,
  CardDivider,
  CardFooter,
  CardTitle,
  FormBadge,
  FormButton,
  FormError,
  FormLabel,
  FormRoot,
  FormTitle,
  Icon,
  Input,
} from "../../components";
import { Formik, setNestedObjectValues } from "formik";
import { getAppointmentTime, getServiceDuration, getServiceTypeName } from "../../helpers/format";
import { selectScheduledServices, selectScheduling } from "../../slices/scheduling-slice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";

import { Checkbox } from "../../components/checkbox";
import { RowSpan } from "../../components/column/styles";
import { SelectField } from "../../components/select";
import { clientDataScheme } from "../../helpers/schemas";
import communes from "../../assets/data/communes.json";
import es from "date-fns/locale/es";
import { format } from "date-fns";
import { publicCreateAppointment } from "../../services/scheduling-service";
import { routes } from "../../navigation/routes";
import { useNavigate } from "react-router-dom";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  commune: "",
  address: "",
  postalCode: "",
  other: "",
};

export const PublicClientDataScreen = () => {
  const formRef = useRef();
  const [error, setError] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const scheduledServices = useSelector(selectScheduledServices());
  const scheduling = useSelector(selectScheduling);

  // reload availability
  useEffect(() => {
    setError("");
    // dispatch(getPublicAvailability(params.id));
  }, []);

  // selected service
  const [currentServiceId, setCurreentServiceId] = useState(null);

  const price = useMemo(() => {
    return scheduledServices.reduce((acc, curr) => {
      return acc + curr.price;
    }, 0);
  }, [scheduledServices]);

  // select first service
  useEffect(() => {
    if (scheduledServices.length && !currentServiceId) {
      const [firstService] = scheduledServices;
      setCurreentServiceId(firstService.id);
    }
  }, [currentServiceId, scheduledServices]);

  const onContinueClick = async () => {
    if (!acceptTerms) {
      setError("Debes aceptar los términos y condiciones para continuar.");
      return;
    } else {
      setError("");
    }
    const errors = await formRef.current.validateForm();
    if (Object.keys(errors).length === 0) {
      const result = await dispatch(publicCreateAppointment(formRef.current.values));
      if (result.type === "scheduling/create-appointment/fulfilled") {
        navigate(routes.publicOrderSuccess(), { replace: true });
      } else {
        setError("Hubo un error al procesar la solicitud.");
      }
    } else {
      formRef.current.setTouched(setNestedObjectValues(errors, true));
    }
  };

  if (!scheduling.id) {
    console.log("No scheduling data, redirecting.");
    return "No se encontraron datos.";
  }

  return (
    <>
      <ClientDataScreenContainer>
        <ClientDataLayout>
          <div style={{ flex: 2, minWidth: 350 }}>
            <CardTitle>
              <Icon name="fi-rr-info" size={18} style={{ marginRight: 6 }} />
              Información personal
            </CardTitle>
            <CardBody style={{ padding: "0px 20px" }}>
              <Formik innerRef={formRef} initialValues={initialValues} validationSchema={clientDataScheme}>
                <FormRoot columns={2}>
                  <RowSpan block span={2}>
                    <FormTitle style={{ marginBottom: 0 }}>Contacto</FormTitle>
                  </RowSpan>
                  <div>
                    <FormLabel htmlFor="firstName">Nombre</FormLabel>
                    <Input id="firstName" name="firstName" type="text" placeholder="Ingrese tu nombre" />
                    <FormError name="firstName" />
                  </div>
                  <div>
                    <FormLabel htmlFor="lastName">Apellido</FormLabel>
                    <Input id="lastName" name="lastName" type="text" placeholder="Ingrese tu apellido" />
                    <FormError name="lastName" />
                  </div>
                  <div>
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <Input id="email" name="email" type="text" placeholder="Ingrese tu email" />
                    <FormError name="email" />
                  </div>
                  <div>
                    <FormLabel htmlFor="phone">Teléfono</FormLabel>
                    <Input id="phone" name="phone" type="text" placeholder="Ingrese tu teléfono" />
                    <FormError name="phone" />
                  </div>
                  <RowSpan block span={2}>
                    <FormTitle style={{ marginBottom: 0 }}>Dirección</FormTitle>
                  </RowSpan>
                  <div>
                    <FormLabel htmlFor="commune">Comúna</FormLabel>
                    <SelectField id="commune" name="commune" items={communes} placeholder="Comuna" />
                    <FormError name="commune" />
                  </div>
                  <div>
                    <FormLabel htmlFor="address">Calle y numeración</FormLabel>
                    <Input id="address" name="address" />
                    <FormError name="address" />
                  </div>
                  <div>
                    <FormLabel flex htmlFor="other">
                      Otro
                      <FormBadge>Opcional</FormBadge>
                    </FormLabel>
                    <Input id="other" name="other" />
                    <FormError name="other" />
                  </div>
                  <div>
                    <FormLabel htmlFor="postalCode">
                      Código Postal
                      <FormBadge>Opcional</FormBadge>
                    </FormLabel>
                    <Input id="postalCode" name="postalCode" />
                    <FormError name="postalCode" />
                  </div>
                </FormRoot>
              </Formik>
            </CardBody>
            <CardFooter></CardFooter>
          </div>
          <div style={{ flex: 1, minWidth: 350 }}>
            <CardTitle>Resumen de tus servicios</CardTitle>
            <CardBody $borderBottom $roundedBottom>
              <ServiceSection>
                {scheduledServices.map((service) => (
                  <ServiceItem
                    key={service.id}
                    selected={service.id === currentServiceId}
                    onClick={() => setCurreentServiceId(service.id)}
                  >
                    {service.name}
                    {/*     <ServiceDetail>
                      <Icon name="fi-rr-calendar" size={14} />
                      {getServiceDuration(service.durationMinutes, false)}
                    </ServiceDetail> */}
                    <ServiceDetail>
                      <Icon name="fi-rr-clock" size={14} />
                      {getServiceDuration(service.durationMinutes, false)}
                    </ServiceDetail>
                  </ServiceItem>
                ))}
                <CardDivider />
                <ServiceItem>
                  <ServiceDetail>
                    <Icon name="fi-rr-clock" size={14} />
                    {getAppointmentTime(scheduling)}
                  </ServiceDetail>
                  <ServiceDetail>
                    <Icon name="fi-rr-calendar" size={14} />
                    {format(scheduling.date, "EEEE dd MMMM yyyy", { locale: es })}
                  </ServiceDetail>
                  <ServiceDetail>
                    <Icon name="fi-rr-building" size={14} />
                    {getServiceTypeName([scheduling.selectedType])}
                  </ServiceDetail>
                </ServiceItem>
              </ServiceSection>
              <CardDivider />
              <PriceContainer>
                <PriceRow>
                  Total
                  <span>${price.toLocaleString("es-CL")}</span>
                </PriceRow>
                <Checkbox checked={acceptTerms} onCheckedChange={setAcceptTerms}>
                  He leído y acepto <b>términos y condiciones</b>
                </Checkbox>
              </PriceContainer>

              <CardDivider />
              <BookContainer>
                {error && <ClientDataError>{error}</ClientDataError>}
                <FormButton $type="primary" type="button" onClick={onContinueClick}>
                  Agendar
                </FormButton>
              </BookContainer>
            </CardBody>
          </div>
        </ClientDataLayout>
      </ClientDataScreenContainer>
    </>
  );
};
