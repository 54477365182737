import styled, { keyframes } from "styled-components";

import { Content } from "@radix-ui/react-tooltip";

const slideDownAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const TooltipContent = styled(Content)`
  border-radius: 4px;
  padding: 15px;
  max-width: 250px;
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-size: 12px;
  line-height: 1;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  user-select: none;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  &[data-state="delayed-open"][data-side="top"] {
    animation-name: ${slideDownAndFade};
  }
  z-index: 101;
`;

export const IconButton = styled.div`
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
