import styled from "styled-components";

export const FirstSectionContainer = styled.div`
  width: 100%;
  height: 100%;
  clip-path: inset(0);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Shapes = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

export const Title = styled.h1``;
