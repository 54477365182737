import * as Select from "@radix-ui/react-select";

import styled from "styled-components";

export const SelectTrigger = styled(Select.Trigger)`
  display: flex;
  align-items: center;
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  font-size: 14px;
  padding: 8px 12px;
  height: 36px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  cursor: pointer;
  line-height: 1;

  background: ${(props) => props.theme.colors.PRIMARY};
  color: ${(props) => props.theme.colors.WHITE};
  border: 1px solid ${(props) => props.theme.colors.PRIMARY};

  &:hover {
    background-color: ${(props) => props.theme.colors.PRESSED};
  }

  .icon {
    margin-right: 5px;
  }
`;

export const SelectContent = styled(Select.Content)`
  overflow: hidden;
  background-color: white;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.STROKE};
  box-shadow: 0px 4px 10px rgba(59, 62, 69, 0.2);
  z-index: 100;
`;

export const SelectItem = styled(Select.Item)`
  font-size: 13px;
  line-height: 1;
  color: ${(props) => props.theme.colors.TEXT_DARK};
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 35px 0 25px;
  position: relative;
  user-select: none;

  &[data-disabled] {
    color: ${(props) => props.theme.colors.STROKE};
    pointer-events: none;
  }
  &[data-highlighted] {
    outline: none;
    background-color: ${(props) => props.theme.colors.STROKE};
    color: ${(props) => props.theme.colors.TEXT_DARK};
  }
`;

export const SelectViewport = styled(Select.Viewport)`
  padding: 5px;
`;

export const SelectScrollDownButton = styled(Select.ScrollDownButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  color: ${(props) => props.theme.colors.TEXT_DARK};
  cursor: default;
`;

export const SelectScrollUpButton = styled(Select.ScrollUpButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  color: ${(props) => props.theme.colors.TEXT_DARK};
  cursor: default;
`;
