import { CustomTableRowMenuLink, ToggleSvg } from "./styles";
import { SortImage, StyledDataTable, TableRowMenuLink, TableRowMenuRoot } from "../../components";
import { getServiceDuration, getServicePrice, getServiceStatusName, getServiceTypeName } from "../../helpers/format";

import { EmptyComponent } from "../../components/table/empty-table";
import { routes } from "../../navigation/routes";
import { useRowSelection } from "../../hooks/use-table";

export const ServicesGrid = (props) => {
  const [conditionalRowStyles, handleSelection] = useRowSelection();
  const columns = getServiceColumns(handleSelection);

  return (
    <StyledDataTable
      sortIcon={<SortImage />}
      columns={columns}
      data={props.data}
      defaultSortFieldId={"name"}
      defaultSortAsc={true}
      persistTableHead={true}
      onRowClicked={props.onRowClicked}
      noBorderLastRow={props.noBorderLastRow}
      noDataComponent={<EmptyComponent to={props.emptyRoute} entityName="servicio" />}
      conditionalRowStyles={conditionalRowStyles}
    />
  );
};

const TableRowMenu = (props) => (
  <TableRowMenuRoot row={props.row} onSelectionChange={props.onSelectionChange}>
    <TableRowMenuLink to={routes.appendToPath(`view/${props.row.id}`)} name="fi-rr-eye" />
    <TableRowMenuLink to={routes.appendToPath(`edit/${props.row.id}`)} name="fi-rr-edit" />
    <CustomTableRowMenuLink to={routes.appendToPath(`set-status/${props.row.id}`)} component={ToggleSvg} />
    <TableRowMenuLink to={routes.appendToPath(`delete/${props.row.id}`)} name="fi-rr-trash" />
  </TableRowMenuRoot>
);

const getServiceColumns = (onSelectionChange) => {
  return [
    {
      id: "name",
      name: "Nombre",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      id: "price",
      name: "Precio",
      selector: (row) => row.price,
      format: (row) => getServicePrice(row.price, row.currency),
      sortable: true,
    },
    {
      id: "duration",
      name: "Duración",
      selector: (row) => row.durationMinutes,
      format: (row) => getServiceDuration(row.durationMinutes),
      sortable: true,
    },
    {
      id: "type",
      name: "Tipo",
      selector: (row) => row.type,
      format: (row) => getServiceTypeName(row.type),
      sortable: true,
    },
    {
      id: "state",
      name: "Estado",
      selector: (row) => row.status,
      format: (row) => getServiceStatusName(row.status),
      sortable: true,
    },
    {
      id: "cell-menu",
      cell: (row) => <TableRowMenu row={row} onSelectionChange={onSelectionChange} />,
      allowOverflow: true,
      button: true,
      width: "48px",
    },
  ];
};
