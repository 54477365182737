import { ModalOverlay, ModalWrapper } from "./styles";

import React from "react";

export const Modal = ({ children, onClose, expand, fullheight, overflowY }) => {
  const handleClose = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <ModalOverlay onClick={handleClose}>
      <ModalWrapper $fullheight={fullheight} $expand={expand} overflowY={overflowY}>
        {children}
      </ModalWrapper>
    </ModalOverlay>
  );
};

export default Modal;
