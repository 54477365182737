import { CardBody, CardClose, CardTitle, Modal } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { AppointmentForm } from "./appointment-form";
import { AppointmentFormContainer } from "./styles";
import { createAppointment } from "../../services/appointments-service";
import { getServices } from "../../services/services-service";
import { selectClient } from "../../slices/clients-slice";
import { selectServices } from "../../slices/services-slice";
import { useEffect } from "react";

export const ScheduleAppointment = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const services = useSelector(selectServices);
  const prefillClient = useSelector(selectClient(params.id));

  useEffect(() => {
    if (services.length === 0) {
      dispatch(getServices());
    }
  }, [services]);

  const onSubmit = async (data, formik) => {
    const result = await dispatch(createAppointment(data));

    if (result.type === "appointments/create/rejected") {
      formik.setErrors({
        generic: result.error.message,
      });
    }
    if (result.type === "appointments/create/fulfilled") {
      navigate(-1);
    }
  };
  const onModalClose = () => {
    navigate(-1);
  };
  return (
    <Modal expand onClose={onModalClose}>
      <CardTitle>
        Agregar cita
        <CardClose onClick={onModalClose} />
      </CardTitle>
      <CardBody $borderBottom $roundedBottom>
        <AppointmentFormContainer>
          <AppointmentForm
            onSubmit={onSubmit}
            services={services}
            onCancel={onModalClose}
            initialValues={prefillClient}
          />
        </AppointmentFormContainer>
      </CardBody>
    </Modal>
  );
};
