import { Fallback, Image, Root } from "@radix-ui/react-avatar";

import styled from "styled-components";

export const AvatarRoot = styled(Root)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  user-select: none;
  width: ${(props) => (props.$size ? `${props.$size}px` : `120px`)};
  height: ${(props) => (props.$size ? `${props.$size}px` : `120px`)};
  border-radius: 100%;
  background-color: #f2f3f7;
  flex-shrink: 0;
`;
export const AvatarImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
`;

export const AvatarFallback = styled(Fallback)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f3f7;
`;

export const UpdateContainer = styled.section`
  text-align: center;
  cursor: pointer;
`;

export const UpdateHint = styled.div`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
`;

export const UpdateTitle = styled.div`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.SECONDARY};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  margin-top: 20px;
  margin-bottom: 6px;
`;
