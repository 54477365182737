import { Group, ItemText, Portal, Root, Value } from "@radix-ui/react-select";
import React, { useMemo, useState } from "react";
import {
  SelectContent,
  SelectIcon,
  SelectItem,
  SelectScrollDownButton,
  SelectScrollUpButton,
  SelectTrigger,
  SelectViewport,
} from "./styles";

import { Icon } from "../icon";
import { useRect } from "@radix-ui/react-use-rect";

/**
 * Navegación que utiliza un select para mostrar opciones de rutas
 */
export const SelectLink = (props) => {
  const [triggerRef, setTriggerRef] = useState();
  const triggerRect = useRect(triggerRef);

  const selected = useMemo(() => {
    return props.items.find((item) => item.to === props.value);
  }, [props.items, props.value]);

  const items = useMemo(() => {
    const filtered = props.items.filter((item) => item.to !== props.value);
    return filtered.map((item) => {
      return (
        <SelectItem key={item.to} value={item.to}>
          <SelectIcon size={18} name={item.icon} />
          <ItemText>{item.label}</ItemText>
        </SelectItem>
      );
    });
  }, [props.items, props.value]);

  return (
    <Root value={props.value} onValueChange={props.onValueChange}>
      <SelectTrigger ref={setTriggerRef} style={props.triggerStyle} aria-label={props.name}>
        <div style={{ display: "flex" }}>
          <SelectIcon size={18} name={selected.icon} />
          <Value>{selected.label}</Value>
        </div>
        <Icon name="fi-rr-angle-small-down" />
      </SelectTrigger>
      <Portal>
        <SelectContent position="popper" side="bottom" sideOffset={-1} $width={triggerRect?.width}>
          <SelectScrollUpButton>
            <Icon name="fi-rr-angle-small-up" />
          </SelectScrollUpButton>
          <SelectViewport>
            <Group>{items}</Group>
          </SelectViewport>
          <SelectScrollDownButton>
            <Icon name="fi-rr-angle-small-down" />
          </SelectScrollDownButton>
        </SelectContent>
      </Portal>
    </Root>
  );
};
