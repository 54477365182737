import { addMinutes, format, parse } from "date-fns";

import es from "date-fns/locale/es";

export const getServiceStatusName = (status) => {
  switch (status) {
    case "active":
      return "Activo";
    case "inactive":
      return "Inactivo";
    default:
      return "";
  }
};

export const getServiceTypeName = (type) => {
  const typeNames = type.map((t) => {
    switch (t) {
      case "in-home":
        return "A domicilio";
      case "in-person":
        return "Presencial";
      case "remote":
        return "Remoto";
      default:
        return "";
    }
  });
  return typeNames.join(", ");
};

export const getServiceDuration = (minutes, short = true) => {
  let hours = Math.floor(minutes / 60);
  let remainingMinutes = minutes % 60;
  let durationString = ``;

  if (hours > 0) {
    durationString += `${hours} ${short ? "h" : hours === 1 ? "hora" : "horas"}`;
  }

  if (remainingMinutes > 0) {
    durationString += ` ${remainingMinutes} ${short ? "min" : "minutos"}`;
  }

  return durationString;
};

export const getServicePrice = (price, currency) => {
  if (!price) {
    return "";
  }
  // por ahora todo se maneja en CLPs
  if (currency === "CLP") {
    return `$${price.toLocaleString("es-CL")}`;
  }
};

export const getServiceHumanDate = (date) => {
  return format(date, "EEE d MMM", { locale: es });
};

export const getServiceHumanDuration = (slot, duration) => {
  const startTime = parse(slot, "HH:mm", new Date());
  const endTime = addMinutes(startTime, duration);
  return `${slot} - ${format(endTime, "HH:mm", { locale: es })}`;
};

export function getAppointmentTime(scheduling) {
  const startDate = parse(scheduling.time, "HH:mm", scheduling.date);
  const endDate = addMinutes(startDate, scheduling.durationMinutes);
  return `${scheduling.time} - ${format(endDate, "HH:mm")}`;
}
