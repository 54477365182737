import * as Select from "@radix-ui/react-select";

import { Content, List, Root, Trigger } from "@radix-ui/react-tabs";

import { Icon } from "../icon";
import styled from "styled-components";

export const TabsRoot = styled(Root)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TabsList = styled(List)`
  flex-shrink: 0;
  display: flex;
  overflow-x: scroll;
`;

export const TabsTrigger = styled(Trigger)`
  font-family: inherit;
  background-color: white;
  padding: 0 20px;
  height: 45px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  line-height: 1;
  user-select: none;
  border: none;
  cursor: pointer;
  color: ${(props) => props.theme.colors.TEXT_DARK};
  border-top: 1px solid ${(props) => props.theme.colors.STROKE};
  border-bottom: 1px solid ${(props) => props.theme.colors.STROKE};
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  &:first-child {
    border-top-left-radius: 12px;
    border-left: 1px solid ${(props) => props.theme.colors.STROKE};
  }

  &:last-child {
    border-top-right-radius: 12px;
    border-right: 1px solid ${(props) => props.theme.colors.STROKE};
  }
  &:hover {
    color: ${(props) => props.theme.colors.PRIMARY};
    border-bottom: 2px solid ${(props) => props.theme.colors.PRIMARY};
  }
  &[data-state="active"] {
    font-weight: 600;
    color: ${(props) => props.theme.colors.PRIMARY};
    border-bottom: 2px solid ${(props) => props.theme.colors.PRIMARY};
  }

  &:focus {
    position: relative;
  }

  & > .icon {
    margin-right: 11px;
  }
`;

export const TabsContent = styled(Content)`
  flex-grow: 1;
  background-color: white;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  outline: none;
  border: 1px solid ${(props) => props.theme.colors.STROKE};
  border-top: none;
`;
