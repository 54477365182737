import { Portal, Root } from "@radix-ui/react-dialog";

import { DialogOverlay } from "./styles";

export const Dialog = (props) => (
  <Root open={props.open}>
    <Portal>
      <DialogOverlay>{props.children}</DialogOverlay>
    </Portal>
  </Root>
);
