import { deleteClient, getClients, updateClient } from "../services/clients-service";

import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  data: [],
  error: false,
  errorMessage: "",
};

const clientsSlice = createSlice({
  name: "clients",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getClients.fulfilled, (state, action) => {
      state.error = false;
      state.errorMessage = "";
      state.data = action.payload;
    });
    builder.addCase(getClients.rejected, (state, action) => {
      state.error = true;
      state.errorMessage = action.error;
    });
    builder.addCase(updateClient.fulfilled, (state, action) => {
      state.error = false;
      state.errorMessage = "";
      const { id, ...data } = action.payload;
      const index = state.data.findIndex((client) => client.id === id);
      if (index !== -1) {
        state.data[index] = { ...state.data[index], ...data };
      }
    });
    builder.addCase(updateClient.rejected, (state, action) => {
      state.error = true;
      state.errorMessage = action.error;
    });
    builder.addCase(deleteClient.fulfilled, (state, action) => {
      state.error = false;
      state.errorMessage = "";
      state.data = state.data.filter((client) => client.id !== action.payload);
    });
    builder.addCase(deleteClient.rejected, (state, action) => {
      state.error = true;
      state.errorMessage = action.error;
    });
  },
});

// selectors
export const selectClients = (state) => state.clients.data;
export const selectClientsError = (state) => ({
  error: state.user.error,
  errorMessage: state.user.errorMessage,
});

export const selectClient = (clientId) => (state) => {
  if (clientId) {
    return state.clients.data.find((client) => client.id === clientId);
  }
  return null;
};

export default clientsSlice.reducer;
