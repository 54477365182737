import styled from "styled-components";

export const PasswordResetContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const FormContainer = styled.div`
  margin-top: 100px;
  width: 100%;
  max-width: 540px;
  height: 500px;
  padding: 14px 20px;

  @media (max-width: 768px) {
    margin-top: 20px;
    margin-right: auto;
  }
`;
