import { GridThreeColumns } from "../../components/column/styles";
import styled from "styled-components";

export const OrderSuccessScreenContainer = styled.div`
  background-color: ${(props) => props.theme.colors.LIGHT_BACKGROUND};
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 48px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const OrderSuccessCard = styled.div`
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 12px;
  border-top: 1px solid ${(props) => props.theme.colors.STROKE};
  background: ${(props) => props.theme.colors.WHITE};
  box-shadow: 0px 4px 17px 0px #0000001a;
  box-sizing: border-box;
  padding: 24px;
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-size: 14px;
`;

export const SuccessTitle = styled.h2`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.PRIMARY};
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
`;

export const Groopa = styled.span`
  color: ${(props) => props.theme.colors.PRIMARY};
  font-weight: 700;
`;

export const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const OrderEntry = styled.div`
  display: flex;
  font-weight: 400;
  align-items: center;
  .icon {
    margin-right: 4px;
  }
`;

export const OrderSummary = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Total = styled.span`
  font-size: 16px;
  font-weight: 700;
`;

export const ServiceRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 5px;
`;
