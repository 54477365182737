import { AddressContainer, CategoryField, ProfileFormContainer } from "./styles";
import { FormActions, FormBadge, FormButton, FormError, FormLabel, FormRoot, FormTitle, Input } from "../../components";

import { FileUploaderField } from "../../components/file-uploader";
import { Formik } from "formik";
import { InputArray } from "../../components/input-array";
import { SelectField } from "../../components/select";
import communes from "../../assets/data/communes.json";
import { completeProfileScheme } from "../../helpers/schemas";

export const ProfileForm = (props) => {
  const initialValues = {
    portfolio: [],
    categories: [""],
    commune: "",
    address: "",
    postalCode: "",
    description: "",
    other: "",
    ...props.data,
  };

  return (
    <ProfileFormContainer>
      <Formik initialValues={initialValues} validationSchema={completeProfileScheme} onSubmit={props.onSubmit}>
        <FormRoot columns={1}>
          <FormTitle style={{ marginBottom: 0 }}>Sobre mí</FormTitle>
          <CategoryField>
            <FormLabel>Categoría</FormLabel>
            <InputArray name="categories" />
            <FormError name="categories" />
          </CategoryField>
          <div>
            <FormLabel htmlFor="description">Descripción</FormLabel>
            <Input id="description" name="description" component="textarea" />
            <FormError name="description" />
          </div>
          <div>
            <FormLabel htmlFor="portfolio">Galería - Sobre mi trabajo</FormLabel>
            <FileUploaderField name="portfolio" />
            <FormError name="portfolio" />
          </div>
          <div>
            <FormTitle style={{ marginBottom: 10 }}>Dirección</FormTitle>
            <AddressContainer>
              <div>
                <FormLabel htmlFor="commune">Comúna</FormLabel>
                <SelectField id="commune" name="commune" items={communes} placeholder="Comuna" />
                <FormError name="commune" />
              </div>
              <div>
                <FormLabel htmlFor="address">Calle y numeración</FormLabel>
                <Input id="address" name="address" />
                <FormError name="address" />
              </div>
              <div>
                <FormLabel flex htmlFor="other">
                  Otro
                  <FormBadge>Opcional</FormBadge>
                </FormLabel>
                <Input id="other" name="other" />
                <FormError name="other" />
              </div>
              <div>
                <FormLabel htmlFor="postalCode">
                  Código Postal
                  <FormBadge>Opcional</FormBadge>
                </FormLabel>
                <Input id="postalCode" name="postalCode" />
                <FormError name="postalCode" />
              </div>
            </AddressContainer>
          </div>
          <FormError showUntouchedErrors name="generic" />
          <FormActions>
            {props.onBack && (
              <FormButton $type="secondary" type="button" onClick={props.onBack}>
                Anterior
              </FormButton>
            )}
            <FormButton type="submit">{props.submitText}</FormButton>
          </FormActions>
        </FormRoot>
      </Formik>
    </ProfileFormContainer>
  );
};
