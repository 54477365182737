import { differenceInMinutes, setHours, setMinutes, startOfToday } from "date-fns";

import { DurationContainer } from "./styles";
import { Field } from "formik";
import { TimePicker } from "../date-time";
import { useMemo } from "react";

export const Duration = (props) => {
  const dateValue = useMemo(() => {
    let hours = 1;
    let minutes = 0;
    if (!isNaN(props.value)) {
      hours = Math.floor(props.value / 60);
      minutes = props.value % 60;
    }

    return setMinutes(setHours(new Date(), hours), minutes);
  }, [props.value]);

  const onValueChange = (value) => {
    const durationMinutes = differenceInMinutes(value, startOfToday());
    if (durationMinutes === 0) {
      props.onError("Debe definir una duración válida.");
      return;
    }
    props.onValueChange(durationMinutes);
  };

  return (
    <DurationContainer id={props.id} name={props.name}>
      <TimePicker value={dateValue} style={{ width: "100%" }} selected={props.value} onValueChange={onValueChange} />
    </DurationContainer>
  );
};

export const DurationField = (props) => {
  const onValueChange = (formik) => (value) => {
    formik.form.setFieldValue(formik.field.name, value);
  };
  const onError = (formik) => (value) => {
    formik.form.setErrors({
      [formik.field.name]: value,
    });
  };
  return (
    <Field
      name={props.name}
      component={(formik) => (
        <Duration
          id={props.id}
          value={formik.field.value}
          onValueChange={onValueChange(formik)}
          onError={onError(formik)}
        />
      )}
    />
  );
};
