import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  history: {},
  loaders: {},
  callers: {},
  startActions: {},
  stopActions: {},
};

const loaderSlice = createSlice({
  name: "loader",
  initialState: INITIAL_STATE,
  reducers: {
    register: (state, action) => {
      state.loaders[action.payload.id] = false;
      state.history[action.payload.id] = action.payload;
      state.callers[action.payload.id] = "";

      action.payload.startActions.forEach((startAction) => {
        state.startActions[startAction] = action.payload.id;
      });

      action.payload.stopActions.forEach((stopAction) => {
        state.stopActions[stopAction] = action.payload.id;
      });
    },
    unregister: (state, action) => {
      state.history[action.payload].startActions.forEach((startAction) => {
        delete state.startActions[startAction];
      });

      state.history[action.payload].stopActions.forEach((stopAction) => {
        delete state.stopActions[stopAction];
      });
      delete state.history[action.payload];
      delete state.loaders[action.payload];
      delete state.callers[action.payload];
    },
    start: (state, action) => {
      state.loaders[action.payload.id] = true;
      state.callers[action.payload.id] = action.payload.caller;
    },
    stop: (state, action) => {
      state.loaders[action.payload.id] = false;
      state.callers[action.payload.id] = "";
    },
  },
});

// selectors
export const selectLoader = (state) => state.loader;

export const { register, unregister, start, stop } = loaderSlice.actions;

export default loaderSlice.reducer;
