import { CardBody, CardTitle } from "../../components";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { AddServiceButton } from "./styles";
import { Icon } from "../../components/icon";
import { ServicesGrid } from "../../grids/services-grid/services-grid";
import { getServices } from "../../services/services-service";
import { routes } from "../../navigation/routes";
import { selectServices } from "../../slices/services-slice";
import { useEffect } from "react";

export const ServicesScreen = () => {
  const dispatch = useDispatch();
  const services = useSelector(selectServices);
  const navigate = useNavigate();

  // refresco servicios en esta página
  useEffect(() => {
    dispatch(getServices());
  }, []);

  const onAddServiceClick = async () => {
    navigate(routes.addService());
  };

  const onRowClicked = ({ id }) => {
    navigate(routes.viewService(id));
  };

  return (
    <div>
      <AddServiceButton onClick={onAddServiceClick}>
        <Icon name="fi-rr-plus" size={12} /> Agregar Servicio
      </AddServiceButton>
      <CardTitle>
        <Icon name="fi-rr-address-book" className="card-icon" size={20} />
        Mis Servicios
      </CardTitle>
      <CardBody $borderBottom $roundedBottom>
        <ServicesGrid data={services} emptyRoute={routes.addService()} onRowClicked={onRowClicked} noBorderLastRow />
      </CardBody>
      <Outlet />
    </div>
  );
};
