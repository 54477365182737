export const routes = {
  root: () => `/`,
  login: () => `/login`,
  signup: () => `/signup`,
  userAccount: () => `/user/account`,
  userProfile: () => `/user/profile`,
  userSchedule: () => `/user/schedule`,
  userSubscription: () => `/user/subscription`,
  clients: () => `/clients`,
  editClient: (id = ":id") => `/clients/edit/${id}`,
  deleteClient: (id = ":id") => `/clients/delete/${id}`,
  history: (type = ":type") => `/history/${type}`,
  historyApprove: (type = ":type", id = ":id") => `/history/${type}/approve/${id}`,
  historyRepeat: (type = ":type", id = ":id") => `/history/${type}/repeat/${id}`,
  historyReject: (type = ":type", id = ":id") => `/history/${type}/reject/${id}`,
  historyView: (type = ":type", id = ":id") => `/history/${type}/view/${id}`,
  historyUpdate: (type = ":type", id = ":id") => `/history/${type}/update/${id}`,
  schedule: () => `/schedule`,
  scheduleAppointment: () => `/schedule/appointment`,
  scheduleViewAppointment: (id = ":id") => `/schedule/appointment/${id}`,
  clientAppointment: (id = ":id") => `/clients/appointment/${id}`,
  scheduleTimeoff: () => `/schedule/block`,
  deleteTimeoff: (id = ":id") => `/schedule/block/delete/${id}`,
  messages: () => `/messages`,
  services: () => `/services`,
  addService: () => `/services/add`,
  editService: (id = ":id") => `/services/edit/${id}`,
  viewService: (id = ":serviceId") => `/services/view/${id}`,
  deleteService: (id = ":id") => `/services/delete/${id}`,
  setServiceStatus: (id = ":id") => `/services/set-status/${id}`,
  notifs: () => `/notifications`,
  setupAccount: () => `/setup/account`,
  setupProfile: () => `/setup/profile`,
  setupServices: () => `/setup/services`,
  setupAddService: () => `/setup/services/add`,
  setupEditService: (id = ":id") => `/setup/services/edit/${id}`,
  setupViewService: (id = ":serviceId") => `/setup/services/view/${id}`,
  setupDeleteService: (id = ":id") => `/setup/services/delete/${id}`,
  setupSetServiceStatus: (id = ":id") => `/setup/services/set-status/${id}`,
  passwordReset: () => `/password-reset`,
  passwordUpdate: () => `/password-update`,
  shareProfile: (id = ":id") => `/share-profile/${id}`,
  publicProfile: (id = ":id") => `/u/${id}`,
  publicScheduling: (id = ":id") => `/u/${id}/scheduling`,
  publicService: (id = ":id", service = ":serviceId") => `/u/${id}/s/${service}`,
  publicClientData: (id = ":id") => `/u/${id}/client-data`,
  publicOrderSuccess: () => `/order-success`,
  appendToPath,
};

function appendToPath(part) {
  let pathname = window.location.pathname;
  if (!pathname.endsWith("/")) {
    pathname += "/";
  }
  return pathname + part;
}

export function getPublicURL(part) {
  let basePath =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PRODUCTION_API
      : process.env.REACT_APP_DEVELOPMENT_API;
  return `${basePath}${part}`;
}
