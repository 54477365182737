import styled from "styled-components";

export const ProfileFormContainer = styled.div`
  flex: 1;
  padding-left: 22px;
  padding-right: 22px;
`;

export const CategoryField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const AddressContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-start;
  flex-wrap: wrap;
  & > div {
    flex-grow: 1;
  }
`;
