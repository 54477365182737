import { CardBody, CardClose, CardTitle, FormLink, FormTitle, Icon, Modal } from "../../components";
import { CopyButton, Icons, ShareProfileContainer } from "./styles";
import { FacebookIcon, FacebookShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { getPublicURL, routes } from "../../navigation/routes";
import { useNavigate, useParams } from "react-router-dom";

import { Tooltip } from "../../components/tooltip/tooltip";
import { useMemo } from "react";

export const ShareProfileModal = () => {
  const navigate = useNavigate();
  const params = useParams();

  const publicProfileURL = useMemo(() => {
    return getPublicURL(routes.publicProfile(params.id));
  }, [params.id]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(publicProfileURL);
    navigate(-1);
  };

  const onModalClose = () => {
    navigate(-1);
  };
  return (
    <Modal expand fullheight onClose={onModalClose}>
      <CardTitle>
        Comparte tu perfil
        <CardClose onClick={onModalClose} />
      </CardTitle>
      <CardBody $borderBottom $roundedBottom>
        <ShareProfileContainer>
          <FormTitle style={{ margin: 0 }}>Comparte en tus redes sociales</FormTitle>
          <Icons>
            <Tooltip
              children="Copiar al portapapeles"
              trigger={
                <CopyButton onClick={copyToClipboard}>
                  <Icon size={18} name="fi-rr-copy" color="white" />
                </CopyButton>
              }
            />
            <Tooltip
              children="Enviar por whatsapp"
              trigger={
                <WhatsappShareButton onClick={onModalClose} url={publicProfileURL}>
                  <WhatsappIcon size={40} round />
                </WhatsappShareButton>
              }
            />
            <Tooltip
              children="Compartir en facebook"
              trigger={
                <FacebookShareButton onClick={onModalClose} url={publicProfileURL}>
                  <FacebookIcon size={40} round />
                </FacebookShareButton>
              }
            />
          </Icons>
          <FormLink $type={"secondary"} to={publicProfileURL} target="_blank" rel="noopener noreferrer">
            Ver mi perfil
          </FormLink>
        </ShareProfileContainer>
      </CardBody>
    </Modal>
  );
};
