/**
 * Middleware para registrar globalmente si la app esta esperando algún recurso.
 * Basado en el package redux-loader.
 */

import { start, stop } from "../slices/loader-slice";

const DEFAULT_REDUCER = "loader";

const loaderMiddleware =
  (key = DEFAULT_REDUCER) =>
  (store) =>
  (next) =>
  (action) => {
    const state = store.getState()[key];

    if (state) {
      next(action);

      const startActions = state.startActions || {};
      const startActionTypes = Object.keys(startActions);

      const stopActions = state.stopActions || {};
      const stopActionTypes = Object.keys(stopActions);

      if (startActionTypes.includes(action.type)) {
        const id = startActions[action.type];

        return next({
          type: start.type,
          payload: { id, caller: action.type },
        });
      }

      if (stopActionTypes.includes(action.type)) {
        const id = stopActions[action.type];

        return next({
          type: stop.type,
          payload: { id, caller: action.type },
        });
      }
      return;
    }

    return next(action);
  };

export default loaderMiddleware;
