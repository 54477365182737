import {
  File,
  FileInfo,
  FileLink,
  FilesContainer,
  IconContainer,
  UpdateContainer,
  UpdateHint,
  UpdateTitle,
} from "./styles";
import { formatSize, getFilename } from "../../helpers/file";

import Dropzone from "react-dropzone";
import { Field } from "formik";
import { Icon } from "../icon";

const FileConfig = {
  maxSize: 2097152,
  multiple: true,
  accept: {
    "image/jpeg": [".jpeg", ".jpg"],
    "image/png": [".png"],
    "image/svg+xml": [".svg"],
  },
};

export const FileUploader = (props) => {
  return (
    <div style={{ width: "100%" }}>
      <Dropzone
        {...FileConfig}
        onDropAccepted={(e) => props.onAcceptedFilesChange([...props.acceptedFiles, ...e])}
        onDropRejected={props.onDropRejected}
      >
        {(zoneProps) => (
          <>
            <Files acceptedFiles={props.acceptedFiles} onUpdateFiles={props.onAcceptedFilesChange} />
            <UploadFrame
              acceptedFiles={props.acceptedFiles}
              inputProps={zoneProps.getInputProps()}
              rootProps={zoneProps.getRootProps()}
            />
          </>
        )}
      </Dropzone>
    </div>
  );
};

export const FileUploaderField = (props) => {
  const onDropRejected = (formik) => (rejectedFiles) => {
    formik.form.setErrors({
      [formik.field.name]: getFileUploaderErrorMessage(rejectedFiles),
    });
  };

  const onAcceptedFilesChange = (formik) => (value) => {
    formik.form.setFieldValue(formik.field.name, value);
  };
  return (
    <Field
      name={props.name}
      component={(formik) => (
        <FileUploader
          id={props.id}
          acceptedFiles={formik.field.value}
          onAcceptedFilesChange={onAcceptedFilesChange(formik)}
          onDropRejected={onDropRejected(formik)}
        />
      )}
    />
  );
};

const getFileUploaderErrorMessage = (rejectedFiles) => {
  const errors = [];

  rejectedFiles.forEach((rejection) => {
    rejection.errors.forEach((error) => {
      if (error.code === "file-too-large") {
        errors.push(`El archivo ${rejection.file.name} fue omitido porque supera el tamaño máximo permitido.`);
      } else {
        errors.push(`El archivo ${rejection.file.name} fue omitido. ${error.code}`);
      }
    });
  });
  return errors.join(" ");
};

const Files = (props) => {
  if (!props.acceptedFiles.length) {
    return null;
  }
  const removeFile = (file) => {
    const files = props.acceptedFiles.filter((acceptedFile) => {
      const fileName = typeof file === "string" ? file : file.name;
      const acceptedFilename = typeof acceptedFile === "string" ? acceptedFile : acceptedFile.name;
      return fileName !== acceptedFilename;
    });
    props.onUpdateFiles(files);
  };

  return (
    <FilesContainer>
      {props.acceptedFiles.map((file, index) => {
        const isFile = typeof file !== "string";
        const fileName = getFilename(file);
        return (
          <File key={index}>
            <IconContainer style={{ margin: 0 }}>
              <Icon name="fi-rr-file" color="white" size={11} />
            </IconContainer>
            {isFile ? (
              <>
                <FileInfo>{fileName}</FileInfo>
                <FileInfo>{formatSize(file.size)}</FileInfo>
              </>
            ) : (
              <>
                <FileLink href={file} target="_blank">
                  {fileName}
                </FileLink>
              </>
            )}
            <Icon onClick={() => removeFile(file)} name="fi-rr-trash" size={20} style={{ marginLeft: "auto" }} />
          </File>
        );
      })}
    </FilesContainer>
  );
};

const UploadFrame = (props) => {
  const style = { marginTop: props.acceptedFiles.length ? "20px" : "" };
  return (
    <UpdateContainer style={style}>
      <div {...props.rootProps}>
        <input {...props.inputProps} />
        <IconContainer>
          <Icon name="fi-rr-folder" color="white" size={11} />
        </IconContainer>
        <UpdateTitle>
          <b>Haz click</b> para subir tus archivos, o arrastralos aquí
        </UpdateTitle>
        <UpdateHint>SVG, PNG, JPG (max. 2mb)</UpdateHint>
      </div>
    </UpdateContainer>
  );
};
