import { Avatar } from "../../components/avatar/avatar-view";
import { GridTwoColumns } from "../../components/column/styles";
import { Link } from "react-router-dom";
import ModalImage from "react-modal-image";
import styled from "styled-components";
import { withPlaceholder } from "../../hocs/with-placeholder";

//
// Profile
//
export const ProfileScreenContainer = styled.div`
  background-color: ${(props) => props.theme.colors.LIGHT_BACKGROUND};
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
`;

export const ProfileCard = styled.div`
  width: 100%;
  flex-shrink: 0;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.colors.STROKE};
  background-color: ${(props) => props.theme.colors.WHITE};
  border-radius: 12px;
  margin-top: 42px;
  padding: 20px;
  max-width: 1108px;
`;

export const ProfileName = styled.h2`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.BLACK};
  word-break: break-all;
  font-weight: 700;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ProfileDescription = styled.h3`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.TEXT_DARK};
  word-break: break-all;
  font-weight: 700;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ProfileText = styled.div`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-weight: 400;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 10px;
  display: flex;
  line-height: 16px;
  align-items: center;
  gap: 7px;
`;

export const ProfileHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: 386px) {
    justify-content: center;
  }
`;

const ProfileColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProfileImageContainer = styled.div`
  width: 174px;
  height: 101px;
  overflow: hidden;
  display: inline-block;
  background: #f2f3f7;
`;

export const ProfileImage = styled(ModalImage)`
  width: 174px;
  height: 101px;
  object-fit: cover;
`;

export const ProfileImages = styled.div`
  overflow-x: scroll;
  white-space: nowrap;
  margin-top: 20px;
  & > div:not(:last-child) {
    margin-right: 16px;
  }
`;

export const ProfileFooterContainer = styled.div`
  width: "100%";
  box-shadow: 5px 0px 13px rgba(0, 0, 0, 0.03);
  display: flex;
  justify-content: center;
  padding: 0px 20px;
  background-color: ${(props) => props.theme.colors.LIGHT_BACKGROUND};
`;
export const ProfileFooterContent = styled.div`
  flex: 1;
  max-width: 1108px;
`;

export const SelectContainer = styled.div`
  max-width: 1108px;
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
  display: flex;
  text-align: right;
  flex-direction: column;
  align-items: flex-end;
  box-sizing: border-box;
  & > * {
    width: 160px;
  }
`;

//
// Services
//

export const ServiceLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
`;

export const ServiceHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledGridTwoColumns = styled(GridTwoColumns)`
  width: 100%;
  max-width: 1108px;
`;

export const ServiceCard = styled.div`
  border: 1px solid ${(props) => props.theme.colors.STROKE};
  background-color: ${(props) => props.theme.colors.WHITE};
  border-radius: 12px;
  padding: 20px;
`;

//
// Placeholders
//

export const AvatarWithPlaceholder = withPlaceholder(Avatar, {
  type: "round",
  color: "#f2f3f7",
  className: "avatar-with-placeholder",
  style: { width: 128, height: 128, flexShrink: 0 },
  componentStyles: { alignSelf: "center" },
});

export const LeftColumnWithPlaceholder = withPlaceholder(ProfileColumn, {
  color: "#f2f3f7",
  style: { marginTop: 20, flex: 1, minWidth: 200 },
  componentStyles: { flex: 1, minWidth: 200 },
});

export const RightColumnWithPlaceholder = withPlaceholder(ProfileColumn, {
  color: "#f2f3f7",
  style: { marginTop: 20, flex: 2, minWidth: 200 },
  componentStyles: { flex: 2, minWidth: 200 },
});

export const ProfileError = styled.div`
  display: flex;
  margin-top: 6px;
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.ERROR};
  font-size: 13px;
  width: 100%;
  max-width: 1108px;
  padding: 16px 0;
  font-weight: 600;
  align-items: center;
  .icon {
    margin-right: 4px;
  }
`;
