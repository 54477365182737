import { addMinutes, formatISO, parse } from "date-fns";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { functions } from "../firebase";
import { httpsCallable } from "firebase/functions";

export const getPublicProfile = createAsyncThunk("public-profile/get", async (userId) => {
  const getPublicProfileFunction = httpsCallable(functions, "getPublicProfile");
  const result = await getPublicProfileFunction({ userId });
  return result.data.data;
});

export const getPublicServices = createAsyncThunk("public-services/get", async (userId) => {
  const getPublicServicesFunction = httpsCallable(functions, "getPublicServices");
  const result = await getPublicServicesFunction({ userId });

  return result.data.data;
});

export const getPublicAvailability = createAsyncThunk("public-availability/get", async (userId) => {
  const getPublicServicesFunction = httpsCallable(functions, "getPublicAvailability");
  const result = await getPublicServicesFunction({ userId });
  return result.data.data;
});

export const publicCreateAppointment = createAsyncThunk("scheduling/create-appointment", async (data, { getState }) => {
  const state = getState();
  const scheduling = state.scheduling.data;

  const selectedServices = scheduling.services.filter((service) => service.selected);
  const services = selectedServices.map((service) => service.id);
  const price = selectedServices.reduce((total, service) => total + service.price, 0);
  const title = selectedServices.map((service) => service.name).join(" + ");

  // calculate start and end dates
  const startDate = parse(scheduling.time, "HH:mm", scheduling.date);
  const endDate = addMinutes(startDate, scheduling.durationMinutes);

  const createAppointmentFunction = httpsCallable(functions, "createAppointment");
  const result = await createAppointmentFunction({
    title: title,
    price: price,
    durationMinutes: scheduling.durationMinutes,
    startDate: formatISO(startDate),
    endDate: formatISO(endDate),
    services: services,
    serviceType: scheduling.selectedType,
    createdBy: scheduling.id,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phone: data.phone,
    commune: data.commune,
    address: data.address,
    other: data.other,
    postalCode: data.postalCode,
  });

  return result.data;
});
