import { collection, deleteDoc, doc, getDocs, query, updateDoc, where } from "firebase/firestore";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { database } from "../firebase";

export const getClients = createAsyncThunk("clients/get", async (_, { getState }) => {
  const state = getState();
  const user = state.user.data;
  const uid = user.uid;

  const clientsRef = collection(database, "clients");
  const clientsQuery = query(clientsRef, where("createdBy", "==", uid));

  const result = await getDocs(clientsQuery);
  return result.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
});

export const deleteClient = createAsyncThunk("clients/delete", async (id) => {
  const documentRef = doc(database, "clients", id);
  await deleteDoc(documentRef);

  return id;
});

export const updateClient = createAsyncThunk("clients/update", async (args) => {
  const { id, ...data } = args;
  const documentRef = doc(database, "clients", id);
  await updateDoc(documentRef, data);

  return args;
});
