import { Group, ItemText, Portal, Root, Value } from "@radix-ui/react-select";
import React, { useMemo } from "react";
import {
  SelectContent,
  SelectItem,
  SelectItemIndicator,
  SelectScrollDownButton,
  SelectScrollUpButton,
  SelectTrigger,
  SelectViewport,
} from "./styles";

import { Field } from "formik";
import { Icon } from "../icon";

export const Select = (props) => {
  const items = useMemo(() => {
    return props.items.map((item, index) => {
      const label = typeof item === "string" ? item : item[props.labelField];
      const value = typeof item === "string" ? item : item[props.dataField];
      return (
        <SelectItem key={index} value={value} disabled={item?.disabled}>
          <ItemText>{label}</ItemText>
          <SelectItemIndicator>
            <Icon size={18} name="fi-rr-check" />
          </SelectItemIndicator>
        </SelectItem>
      );
    });
  }, [props.items, props.dataField, props.labelField]);

  const clearOption = props.clearable && props.value && (
    <SelectItem key={"clear"} value={null}>
      <ItemText>{props.clearText}</ItemText>
    </SelectItem>
  );

  return (
    <Root value={props.value || undefined} onValueChange={props.onValueChange}>
      <SelectTrigger style={props.triggerStyle} aria-label={props.name}>
        <Value placeholder={props.placeholder} />
        <Icon name="fi-rr-angle-small-down" />
      </SelectTrigger>
      <Portal>
        <SelectContent>
          <SelectScrollUpButton>
            <Icon name="fi-rr-angle-small-up" />
          </SelectScrollUpButton>
          <SelectViewport>
            <Group>
              {items}
              {clearOption}
            </Group>
          </SelectViewport>
          <SelectScrollDownButton>
            <Icon name="fi-rr-angle-small-down" />
          </SelectScrollDownButton>
        </SelectContent>
      </Portal>
    </Root>
  );
};

Select.defaultProps = {
  dataField: "value",
  labelField: "label",
};

export const SelectField = (props) => {
  const onValueChange = (formik) => (value) => {
    props.onValueChange && props.onValueChange(value);
    formik.form.setFieldValue(formik.field.name, value);
  };
  return (
    <Field
      name={props.name}
      component={(formik) => (
        <Select
          id={props.id}
          value={formik.field.value}
          onValueChange={onValueChange(formik)}
          items={props.items}
          placeholder={props.placeholder}
          dataField={props.dataField}
          labelField={props.labelField}
          triggerStyle={props.triggerStyle}
        />
      )}
    />
  );
};
