import { createTimeoff, deleteTimeoff, getTimeoff } from "../services/timeoff-service";

import { createSlice } from "@reduxjs/toolkit";
import { logout } from "../services/user-service";

const INITIAL_STATE = {
  data: [],
  error: false,
  errorMessage: "",
};

const timeOffSlice = createSlice({
  name: "timeoff",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createTimeoff.fulfilled, (state, action) => {
      state.error = false;
      state.errorMessage = "";
      state.data.push(action.payload);
    });
    builder.addCase(createTimeoff.rejected, (state, action) => {
      state.error = true;
      state.errorMessage = action.error;
    });
    builder.addCase(deleteTimeoff.fulfilled, (state, action) => {
      state.error = false;
      state.errorMessage = "";
      state.data = state.data.filter((d) => d.id !== action.payload);
    });
    builder.addCase(deleteTimeoff.rejected, (state, action) => {
      state.error = true;
      state.errorMessage = action.error;
    });
    builder.addCase(getTimeoff.fulfilled, (state, action) => {
      state.error = false;
      state.errorMessage = "";
      state.data = action.payload;
    });
    builder.addCase(getTimeoff.rejected, (state, action) => {
      state.error = true;
      state.errorMessage = action.error;
      state.data = [];
    });
    builder.addCase(logout.fulfilled, () => {
      return INITIAL_STATE;
    });
  },
});

// selectors
export const selectTimeOff = (state) => state.timeoff.data;

export default timeOffSlice.reducer;
