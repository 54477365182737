import { CardBody, Icon } from "../../components";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { TabsList, TabsRoot, TabsTrigger } from "../../components/tabs/styles";

import { SelectLink } from "../../components/select-link";
import { routes } from "../../navigation/routes";
import { useMediaQuery } from "../../hooks/use-media-query";

const items = [
  {
    icon: "fi-rr-settings",
    label: "Cuenta",
    to: routes.userAccount(),
  },
  {
    icon: "fi-rr-user",
    label: "Perfil",
    to: routes.userProfile(),
  },
  {
    icon: "fi-rr-clock",
    label: "Horario",
    to: routes.userSchedule(),
  },
  {
    icon: "fi-rr-credit-card",
    label: "Subscripción",
    to: routes.userSubscription(),
  },
];

export const UserTabsScreen = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const mobile = useMediaQuery("(max-width: 918px)");

  return (
    <>
      {mobile ? (
        <SelectLink value={pathname} items={items} onValueChange={navigate} />
      ) : (
        <TabsRoot value={pathname} onValueChange={navigate}>
          <TabsList aria-label="Administra tu cuenta">
            {items.map((item) => (
              <TabsTrigger key={item.to} value={item.to}>
                <Icon name={item.icon} size={18} />
                {item.label}
              </TabsTrigger>
            ))}
          </TabsList>
        </TabsRoot>
      )}
      <CardBody $borderBottom $roundedBottom>
        <Outlet />
      </CardBody>
    </>
  );
};
