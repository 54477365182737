import { Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMemo, useState } from "react";

import { MobileNav } from "../components/mobile-nav";
import { Page } from "../components/page";
import { Sidebar } from "../components/sidebar";
import defaultItems from "../components/sidebar/items";
import { logout } from "../services/user-service";
import { selectUser } from "../slices/user-slice";
import { useMediaQuery } from "../hooks/use-media-query";

export const SetupLayout = () => {
  const mobile = useMediaQuery("(max-width: 918px)");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const user = useSelector(selectUser) || {};
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  // actions
  const onCloseSidebar = () => {
    setSidebarOpen(false);
  };

  const onOpenSidebar = () => {
    setSidebarOpen(true);
  };

  const items = useMemo(() => {
    // deshabilito items que no forman parte del onboarding flow
    return {
      clients: { ...defaultItems.clients, enabled: false },
      history: { ...defaultItems.history, enabled: false },
      /*  messages: { ...defaultItems.messages, enabled: false }, */
      notifications: { ...defaultItems.notifications, enabled: false },
      schedule: { ...defaultItems.schedule, enabled: false },
      services: { ...defaultItems.services, enabled: false },
      share: { ...defaultItems.share, enabled: false },
      user: { ...defaultItems.user, text: user.firstName, to: pathname },
      logout: { ...defaultItems.logout, onClick: () => dispatch(logout()) },
    };
  }, [dispatch, pathname]);

  return (
    <div style={{ display: "flex", height: "100%", overflowY: "scroll" }}>
      <Sidebar open={sidebarOpen || !mobile} onCloseSidebar={onCloseSidebar} items={items} />
      <Page>
        {mobile && <MobileNav onOpenSidebar={onOpenSidebar} />}
        <Outlet />
      </Page>
    </div>
  );
};
