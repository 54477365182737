import styled from "styled-components";

export const ShareProfileContainer = styled.div`
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Icons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 11px;
  margin-top: 28px;
  margin-bottom: 28px;
`;

export const CopyButton = styled.div`
  background-color: #909bab;
  border: initial;
  color: initial;
  cursor: pointer;
  padding: initial;
  text-align: initial;
  text-decoration: none;
  display: block;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
