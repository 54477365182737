import { AvatarFallback, AvatarImage, AvatarRoot, UpdateContainer, UpdateHint, UpdateTitle } from "./styles";

import Dropzone from "react-dropzone";
import { Icon } from "../icon";
import React from "react";

const FileConfig = {
  maxSize: 2097152,
  maxFiles: 1,
  accept: {
    "image/jpeg": [".jpeg", ".jpg"],
    "image/png": [".png"],
  },
};

export const UpdateAvatar = (props) => {
  const onDrop = (acceptedFiles) => {
    console.log("acceptedFiles", acceptedFiles);
    const [file] = acceptedFiles;
    props.onUpdateAvatar(file);
  };

  return (
    <Dropzone {...FileConfig} onDrop={onDrop}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <UpdateContainer>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <AvatarRoot>
              <AvatarImage src={props.src} alt="Imagen de perfil" />
              <AvatarFallback>
                <Icon name="fi-rr-user-add" />
              </AvatarFallback>
            </AvatarRoot>
            <UpdateTitle>{isDragActive ? "Suelta el archivo aquí..." : "Cargar imagen"}</UpdateTitle>
            <UpdateHint>Permitido JPG, PNG. Max 2MB</UpdateHint>
          </div>
        </UpdateContainer>
      )}
    </Dropzone>
  );
};
