import { CardBody, CardClose, CardTitle, Modal } from "../../components";
import { createService, uploadServicePhotos } from "../../services/services-service";

import { ServiceForm } from "./service-form";
import { ServiceFormContainer } from "./styles";
import { serviceScheme } from "../../helpers/schemas";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const initialValues = {
  name: "",
  price: "",
  currency: "CLP",
  status: "active",
  description: "",
  durationMinutes: "",
  photos: [],
  type: "",
};

export const AddServiceModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = async (data, formik) => {
    // sube las imagenes primero
    const photosResult = await dispatch(uploadServicePhotos({ filesOrURLs: data.photos, id: null }));

    if (photosResult.type === "service/upload-photos/rejected") {
      formik.setErrors({
        generic: "Error subiendo imágenes del servicio",
      });
      return;
    }
    // crea el servicio, incluyendo imagenes
    const result = await dispatch(
      createService({
        name: data.name,
        price: Number(data.price),
        currency: data.currency,
        status: data.status,
        description: data.description,
        durationMinutes: data.durationMinutes,
        photos: photosResult.payload,
        type: data.type,
      })
    );

    if (result.type === "service/create/rejected") {
      formik.setErrors({
        generic: result.error.message,
      });
    }
    if (result.type === "service/create/fulfilled") {
      navigate(-1);
    }
  };
  const onModalClose = () => {
    navigate(-1);
  };
  return (
    <Modal expand onClose={onModalClose}>
      <CardTitle>
        Nuevo servicio
        <CardClose onClick={onModalClose} />
      </CardTitle>
      <CardBody $borderBottom $roundedBottom>
        <ServiceFormContainer>
          <ServiceForm
            initialValues={initialValues}
            validationSchema={serviceScheme}
            onCancel={onModalClose}
            onSubmit={onSubmit}
          />
        </ServiceFormContainer>
      </CardBody>
    </Modal>
  );
};
