import { IconBadge, IconContainer, StyledIcon } from "./styles";

import PropTypes from "prop-types";

export const Icon = (props) => {
  const size = props.size || 24;
  return (
    <IconContainer onClick={props.onClick} className={`icon ${props.className}`} style={props.style} $size={size}>
      <StyledIcon className={props.name} color={props.color} $size={size} />
      {props.badge && <IconBadge color={props.badgeColor} />}
    </IconContainer>
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  badge: PropTypes.bool,
  badgeColor: PropTypes.string,
};
