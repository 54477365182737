import { FormButton, FormError, FormLabel, FormRoot, Input } from "../../components";

import { DurationField } from "../../components/duration";
import { FileUploaderField } from "../../components/file-uploader";
import { Formik } from "formik";
import { MultiSelectField } from "../../components/multi-select";
import { RowSpan } from "../../components/column/styles";
import { serviceScheme } from "../../helpers/schemas";
import { serviceTypes } from "../../helpers/consts";

export const ServiceForm = (props) => {
  return (
    <Formik initialValues={props.initialValues} validationSchema={serviceScheme} onSubmit={props.onSubmit}>
      <FormRoot columns={2}>
        <RowSpan block span={2}>
          <FormLabel htmlFor="name">Nombre del servicio</FormLabel>
          <Input id="name" name="name" type="text" placeholder="Ingresa el nombre del servicio" />
          <FormError name="name" />
        </RowSpan>
        <div>
          <FormLabel htmlFor="type">Tipo de servicio</FormLabel>
          <MultiSelectField id="type" name="type" items={serviceTypes} placeholder="Selecciona el tipo de servicio" />
          <FormError name="type" />
        </div>
        <div>
          <FormLabel htmlFor="price">Precio</FormLabel>
          <Input id="price" name="price" type="text" placeholder="Ingresa el precio" />
          <FormError name="price" />
        </div>
        <RowSpan block span={2}>
          <FormLabel htmlFor="durationMinutes">Duración</FormLabel>
          <DurationField id="durationMinutes" name="durationMinutes" />
          <FormError name="durationMinutes" />
        </RowSpan>
        <RowSpan block span={2}>
          <FormLabel htmlFor="description">Descripción</FormLabel>
          <Input id="description" name="description" component="textarea" />
          <FormError name="description" />
        </RowSpan>
        <RowSpan span={2}>
          <FileUploaderField name="photos" />
          <FormError name="photos" />
        </RowSpan>
        <RowSpan span={2} style={{ marginTop: 10 }}>
          <FormError showUntouchedErrors name="generic" />
        </RowSpan>
        <FormButton $type="secondary" type="button" onClick={props.onCancel}>
          Cancelar
        </FormButton>
        <FormButton type="submit">Guardar</FormButton>
      </FormRoot>
    </Formik>
  );
};
