import Placeholder from "react-placeholder";

/**
 * HOC para agregar un placeholder a un componente.
 */
export const withPlaceholder = (Component, placeholderProps) => {
  const WithPlaceholder = (props) => {
    return (
      <Placeholder ready={props.ready} {...placeholderProps}>
        <Component
          {...props}
          style={{
            ...placeholderProps.componentStyles,
            ...props.style,
          }}
        />
      </Placeholder>
    );
  };

  return WithPlaceholder;
};
