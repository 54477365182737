import { Navigate, Outlet, useLocation } from "react-router-dom";
import { addDays, formatISO, isBefore, parseISO } from "date-fns";
import { getUserSubscription, isActiveOrTrial } from "../services/subscription-service";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";

import { matchPath } from "react-router-dom";
import { routes } from "./routes";
import { selectSubscription } from "../slices/subscription-slice";
import { selectUser } from "../slices/user-slice";

const professionalRoutes = [
  routes.clients(),
  routes.editClient(),
  routes.deleteClient(),
  routes.history(),
  routes.historyApprove(),
  routes.historyReject(),
  routes.historyUpdate(),
  routes.historyView(),
  routes.historyRepeat(),
  routes.messages(),
  routes.schedule(),
  routes.services(),
  routes.addService(),
  routes.editService(),
  routes.setServiceStatus(),
  routes.viewService(),
  routes.deleteService(),
  routes.userAccount(),
  routes.userProfile(),
  routes.userSchedule(),
  routes.userSubscription(),
  routes.scheduleAppointment(),
  routes.scheduleViewAppointment(),
  routes.deleteTimeoff(),
  routes.clientAppointment(),
  routes.scheduleTimeoff(),
  routes.shareProfile(),
  routes.publicProfile(),
  routes.publicService(),
  routes.publicScheduling(),
  routes.publicClientData(),
  routes.publicOrderSuccess(),
];

const setupRoutes = [
  //
  routes.setupAccount(),
  routes.setupProfile(),
  routes.setupServices(),
  routes.setupAddService(),
  routes.setupViewService(),
  routes.setupEditService(),
  routes.setupDeleteService(),
  routes.setupSetServiceStatus(),
];

const publicRoutes = [
  //,
  routes.root(),
  routes.login(),
  routes.signup(),
  routes.passwordReset(),
  routes.passwordUpdate(),
  routes.publicProfile(),
  routes.publicService(),
  routes.publicScheduling(),
  routes.publicClientData(),
  routes.publicOrderSuccess(),
];

/**
 * Componente que maneja la redirección a la pantalla correspondiente según el rol del usuario
 */
export const RoleBasedRedirect = () => {
  const user = useSelector(selectUser);
  const subscription = useSelector(selectSubscription);
  const location = useLocation();
  const dispatch = useDispatch();

  // cada 1 día desde el login chequea la suscripción
  useEffect(() => {
    if (user?.uid) {
      const lastLoginTimeString = localStorage.getItem("lastLoginTime");
      if (lastLoginTimeString) {
        const lastLoginTime = parseISO(localStorage.getItem("lastLoginTime"));
        const oneDayAgo = addDays(new Date(), -1);
        if (isBefore(lastLoginTime, oneDayAgo)) {
          dispatch(getUserSubscription(user.uid));
          localStorage.setItem("lastLoginTime", formatISO(new Date()));
        }
      }
    }
  }, [user?.uid]);

  const pathname = location.pathname.replace(/\/$/, "") || "/";

  const redirect = useMemo(() => {
    let redirect = false;

    // usuario autenticado; cuenta configurada
    if (user && user.setupComplete) {
      // pero no activo: directo a subscription
      if (!isActiveOrTrial(subscription) && pathname !== routes.userSubscription()) {
        redirect = routes.userSubscription();
      }
      // activo, chequeo ruta
      else if (!professionalRoutes.some((p) => matchPath(p, pathname))) {
        redirect = routes.history("pending");
      }
    }

    // usuario autenticado; sin-onboarding
    if (user && !user.setupComplete && !setupRoutes.some((p) => matchPath(p, pathname))) {
      redirect = routes.setupAccount();
    }

    // usuario anonimo
    if (!user && !publicRoutes.some((p) => matchPath(p, pathname))) {
      redirect = routes.login();
    }

    return redirect;
  }, [subscription, user, pathname]);

  if (redirect && process.env.NODE_ENV !== "production") {
    console.log(`unauthorized: redirecting from ${pathname} to ${redirect}`);
  }

  return redirect ? <Navigate replace to={redirect} /> : <Outlet />;
};
