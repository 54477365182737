import { AccountFormContainer, UpdateAvatarContainer } from "./styles";
import { FormActions, FormButton, FormError, FormLabel, FormRoot, FormTitle, Input } from "../../components";

import { Formik } from "formik";
import { RowSpan } from "../../components/column/styles";
import { SelectField } from "../../components/select";
import { UpdateAvatar } from "../../components/avatar/avatar-update";
import { completeAccountScheme } from "../../helpers/schemas";
import { genders } from "../../helpers/consts";

export const AccountForm = (props) => {
  const initialValues = {
    gender: "",
    ...props.data,
  };
  return (
    <AccountFormContainer>
      <Formik initialValues={initialValues} validationSchema={completeAccountScheme} onSubmit={props.onSubmit}>
        <FormRoot columns={3}>
          <RowSpan span={2}>
            <FormTitle style={{ marginBottom: 0 }}>Perfil del usuario</FormTitle>
          </RowSpan>
          <UpdateAvatarContainer>
            <UpdateAvatar src={props.data.profileImage} onUpdateAvatar={props.onUpdateAvatar} />
          </UpdateAvatarContainer>
          <div>
            <FormLabel htmlFor="firstName">Nombre</FormLabel>
            <Input id="firstName" name="firstName" type="text" placeholder="Ingrese tu nombre" />
            <FormError name="firstName" />
          </div>
          <div>
            <FormLabel htmlFor="lastName">Apellido</FormLabel>
            <Input id="lastName" name="lastName" type="text" placeholder="Ingrese tu apellido" />
            <FormError name="lastName" />
          </div>
          <div>
            <FormLabel htmlFor="phone">Teléfono</FormLabel>
            <Input id="phone" name="phone" type="text" placeholder="Ingrese tu teléfono" />
            <FormError name="phone" />
          </div>
          <div>
            <FormLabel htmlFor="gender">Género</FormLabel>
            <SelectField id="gender" name="gender" items={genders} placeholder="Género" />
            <FormError name="gender" />
          </div>
          <RowSpan span={2}>
            <FormTitle style={{ margin: 0 }}>Datos de acceso</FormTitle>
          </RowSpan>
          <div>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input id="email" name="email" type="text" disabled />
          </div>
          {/*   <div>
            <FormLabel htmlFor="password">Contraseña</FormLabel>
            <Input id="password" name="password" type="password" disabled value="fake-password" />
          </div> */}
          <RowSpan span={3}>
            <FormError showUntouchedErrors name="generic" />
          </RowSpan>
          <RowSpan span={3}>
            <FormActions>
              <FormButton type="submit">{props.submitText}</FormButton>
            </FormActions>
          </RowSpan>
        </FormRoot>
      </Formik>
    </AccountFormContainer>
  );
};
