import {
  CardBody,
  CardDivider,
  CardTitle,
  StepContainer,
  StepDescription,
  StepNumber,
  StepTitle,
} from "../../components";
import { updatePortfolio, updateUser } from "../../services/user-service";
import { useDispatch, useSelector } from "react-redux";

import { Icon } from "../../components/icon";
import { ProfileForm } from "../../forms/profile-form/profile-form";
import { routes } from "../../navigation/routes";
import { selectUser } from "../../slices/user-slice";
import { useNavigate } from "react-router-dom";

export const SetupProfileScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const onSubmit = async (data, formik) => {
    // sube las imagenes para poder incluirlas en el perfil del usuario
    const portfolioResult = await dispatch(updatePortfolio(data.portfolio));

    if (portfolioResult.type === "user/portfolio-update/rejected") {
      formik.setErrors({
        generic: "Error actualizando la galería de imagenes",
      });
      return;
    }
    // actualiza perfil del usuario
    const result = await dispatch(
      updateUser({
        portfolio: portfolioResult.payload,
        categories: data.categories,
        commune: data.commune,
        address: data.address,
        postalCode: data.postalCode,
        description: data.description,
        other: data.other,
      })
    );

    if (result.type === "user/update/rejected") {
      formik.setErrors({
        generic: result.error.message,
      });
    }
    if (result.type === "user/update/fulfilled") {
      navigate(routes.setupServices());
    }
  };

  const onBack = () => {
    navigate(routes.setupAccount());
  };

  return (
    <div>
      <CardTitle>
        <Icon name="fi-rr-user" className="card-icon" size={20} />
        Crea tu perfil
      </CardTitle>
      <CardBody $borderBottom $roundedBottom>
        <StepContainer>
          <StepTitle>
            <StepNumber>2</StepNumber>Cuéntanos más de ti
          </StepTitle>
          <StepDescription>
            Cuentale a tus clientes un poco más de ti, para que adquieran tus servicios.
          </StepDescription>
        </StepContainer>
        <CardDivider />
        <ProfileForm data={user} onSubmit={onSubmit} onBack={onBack} submitText={"Siguiente"} />
      </CardBody>
    </div>
  );
};
