import styled from "styled-components";

export const AlertMessage = styled.p`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  color: ${(props) => props.theme.colors.TEXT_DARK};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
`;

export const AlertContainer = styled.div`
  padding: 20px;
  display: flex;
  gap: 10px;
  align-items: center;
`;
