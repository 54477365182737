import styled from "styled-components";

export const AddButton = styled.button`
  font-family: ${(props) => props.theme.fonts.NOTO_SANS};
  font-weight: 500;
  line-height: 19px;
  font-size: 14px;
  margin-top: 5px;
  border: none;
  cursor: pointer;
  text-align: start;
  color: ${(props) => props.theme.colors.PRIMARY};
  background-color: ${(props) => props.theme.colors.WHITE};
`;

export const RemoveButton = styled.button`
  margin-left: 15px;
  border: none;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.WHITE};
  color: ${(props) => props.theme.colors.STROKE};
  &:hover {
    color: ${(props) => props.theme.colors.DELETE};
  }
`;
